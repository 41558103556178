import React from "react";
import SideNavigation from "./commonComponents/SideNavigation";
import TopNavBar from "./commonComponents/TopNavBar";
import PaymentOrderDetails from "./commonComponents/PaymentOrderDetails";

export default function PaymentView() {
  return (
    <>
      <div>
        <TopNavBar />
      </div>
      <div>
        <SideNavigation />
        <PaymentOrderDetails />
      </div>
    </>
  );
}
