import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { KeyboardArrowLeftOutlined } from "@mui/icons-material";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import ReaderCards from "./ReaderCards";

export default function ReaderTable({
  readers,
  selectedReader,
  idprop,
  locprovid,
}) {
  const {
    toggleViewLR,
    getReaderMetaData,
    addNewReader,
  } = useIntegrationHook();

  let EditableConfig_1 = JPJS(getReaderMetaData);
  let EditableConfig_2 = JPJS(getReaderMetaData);
  EditableConfig_1.ID_Level.push(idprop);
  EditableConfig_1.updating_key = "reader_label";
  EditableConfig_2.updating_key = "pairing_code";
  return (
    <>
      <div className="Reader-Card-Form">
        <div className="divide-columns-rt">
          <div className="rt-col2">
            <IconButton
              onClick={() => {
                toggleViewLR(idprop);
              }}
            >
              <KeyboardArrowLeftOutlined />
            </IconButton>
          </div>
          <div className="rt-col1">
            <div className="container-reader-card">
              {readers === undefined || readers.length === 0 ? (
                <div className="no-reader-message">No Reader Found!</div>
              ) : null}
              {readers?.map((reader, index) => {
                return (
                  <ReaderCards
                    reader={reader}
                    locprop={idprop}
                    rdridprop={reader.Rdr_id}
                    locprovid={locprovid}
                    key={reader.Rdr_id}
                  />
                );
              })}
              {locprovid ? (
                <div className="add-reader-card-button">
                  <Tooltip title="Add New Reader" arrow placement="right-start">
                    <IconButton
                      className="add-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        addNewReader(idprop);
                      }}
                      data-testid="add-reader-test-button"
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
