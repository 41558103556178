import React from "react";
import dataerror from "../../../Assets/dataerrors.svg";
import processerror from "../../../Assets/processerror.svg";
import animationnotfound from "../../../Assets/animation-notfound.svg";
export function LoadingAnimation() {
  return (
    <div className="animation-1">
      <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>

  );
}


export function DataErrors() {
  return (
    <div className="animation-3">
      <img
        src={dataerror}
        alt="Missing information"
        width={200}
        height={170}
      />
    </div>

  );
}

export function NoShow() {
  return (

    <div className="animation-2">
      <img
        src={animationnotfound}
        alt="Application is down"
        width={200}
        height={170}
      />

    </div>

  );
}

export function ProcessError() {
  return (
    <div>
      <img
        src={processerror}
        alt="Processing Error"
        width={200}
        height={170}
      />
    </div>
  );
}



