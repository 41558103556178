import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginHook } from "../adminHooks/loginHook";
export const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken, configLoaded } = useLoginHook();

  const checkUserToken = () => {
    if (
      (accessToken === "" && location.pathname !== "/admin/forgot_password") ||
      configLoaded === false
    ) {
      return navigate("/admin/login");
    }
  };
  useEffect(() => {
    checkUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, location]);

  return (
    <React.Fragment>
      {(accessToken !== "" || location.pathname === "/admin/forgot_password") &&
      configLoaded === true
        ? props.children
        : null}
    </React.Fragment>
  );
};
