import { useDispatch, useSelector } from "react-redux";
import {
  createOrderApi,
  loadConfig,
  discoverReaders,
  setCardValidation,
  confirmCardPayment,
  processPaymentApi,
  connectReaderHandler,
  setDisplayReader,
  collectPaymentMethod,
  processPosPayment,
  checkStatus,
  cancelPayment,
  clearReaderDisplay,
  getStatusPayload,
  disconnectReader,
  checkReaderConnection,
  getStatusDiscoverReaders,
  confirmPosPaymentBackend,
  confirmBraintreePayment,
  //checkStatus,
} from "../external/Api";
import {
  SetConfigurationPending,
  SetCreateOrderPending,
  ValidateAndSetAmount,
  LocationAvailabilityPending,
  ShowPOSView,
  ShowOnlineView,
  SetTokenPending,
  SetToken,
  ShowBraintreeOnlineView,
} from "../../slice/PaymentSlice";
import {
  startStage,
  viewModeNameList,
} from "../../stateAndConstants/paymentStateAndConstants/constants";
import { useEffect } from "react";
import { redirectBack, setToken } from "../external/browserAndCallingApp";

const useAppHook = () => {
  const dispatch = useDispatch();

  const viewStructure = useSelector(
    (state) => state.main.UIInteractions.viewStructure
  );
  const StopStateForTesting = useSelector(
    (state) => state.main.UIInteractions.StopStateForTesting
  );
  const createOrderPayload = useSelector(
    (state) => state.main.PaymentInteractionDetails.createOrderPayload
  );
  const refId = useSelector(
    (state) => state.main.IntegratingClientDetails.tenantDetails.referenceId
  );
  const amountDetails = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails
  );
  const locationChangeHandler = useSelector(
    (state) => state.main.UIInteractions.locationChangeHandler
  );
  const currentLocation = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentLocation
  );
  const currentReader = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentReader
  );
  const authToken = useSelector(
    (state) => state.main.PaymentInteractionDetails.authToken
  );
  const orderPayloadToken = useSelector(
    (state) => state.main.PaymentInteractionDetails.orderPayloadToken
  );
  const paymentId = useSelector(
    (state) => state.main.PaymentInteractionDetails.paymentId
  );
  const cardClientSecret = useSelector(
    (state) => state.main.PaymentIntegrations.cardClientSecret
  );
  const posClientSecret = useSelector(
    (state) => state.main.PaymentIntegrations.posClientSecret
  );

  const cardProcessingPayload = useSelector(
    (state) => state.main.PaymentInteractionDetails.cardProcessingPayload
  );
  const currentPaymentMode = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentPaymentMode
  );
  const currentPaymentMethod = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentPaymentMethod
  );
  const paymentIntent = useSelector(
    (state) => state.main.PaymentIntegrations.paymentIntent
  );
  const paymentStatus = useSelector(
    (state) => state.main.PaymentIntegrations.paymentStatus
  );
  const statusPayload = useSelector(
    (state) => state.main.PaymentIntegrations.statusPayload
  );
  const browserCallbackUrl = useSelector(
    (state) =>
      state.main.IntegratingClientDetails.tenantDetails.browserCallbackUrl
  );

  const paymentMethodNonce = useSelector(
    (state) => state.main.braintreeConfigurations.paymentMethodNonce
  );

  useEffect(() => {
    if (!viewStructure.IsViewVariableSet) {
      switch (viewStructure.ViewName) {
        case viewModeNameList.ConfigPending:
          if (StopStateForTesting !== "ConfigPending") {
            dispatch(SetConfigurationPending(viewStructure));
            dispatch(loadConfig());
          }
          break;
        case "ConfigComplete":
          if (StopStateForTesting !== "ConfigComplete") {
            dispatch(SetTokenPending());
          }
          break;
        case "SetTokenPending":
          if (StopStateForTesting !== "ConfigComplete") {
            const tokens = setToken();
            dispatch(SetToken(tokens));
          }
          break;
        case "SetTokenComplete":
          if (StopStateForTesting !== "ConfigComplete") {
            dispatch(SetCreateOrderPending());
          }
          break;
        case "CreateOrderPending":
          if (StopStateForTesting !== "CreateOrderPending") {
            // NOTE - Currently accepting the auth_token as Bearer token and order payload as request body
            dispatch(
              createOrderApi({
                authToken: authToken,
                orderPayloadToken: orderPayloadToken,
              })
            );
          }
          break;
        case "CreateOrderComplete":
          dispatch(ValidateAndSetAmount(createOrderPayload));
          break;
        case "LocationAvailabilityCheckPending":
          dispatch(LocationAvailabilityPending());
          break;
        case "LocationAvailabilityCheckComplete":
          dispatch(ShowPOSView());
          break;
        case "ShowOnlineView":
          dispatch(ShowOnlineView());
          break;
        case "ShowPOSView":
          dispatch(ShowPOSView());
          break;
        case "ShowBraintreeOnlineView":
          dispatch(ShowBraintreeOnlineView());
          break;
        case "SetCardValidation":
          dispatch(setCardValidation(cardProcessingPayload));
          break;
        case "SetCardProcessOrderPending":
          const cardReq = {
            paymentId: paymentId,
            postMessage: cardProcessingPayload.postData,
            authToken: authToken, // NOTE - Currently accepting the auth_token as bearer token and order_payload in the post body
          };
          dispatch(processPaymentApi(cardReq));
          break;
        case "SetCardProcessOrderComplete":
          dispatch(
            confirmCardPayment({
              cardProcessingPayload: cardProcessingPayload,
              clientSecret: cardClientSecret,
            })
          );
          break;
        case "CheckReaderConnectionStatus":
          dispatch(checkReaderConnection());
          break;
        case "DisconnectReader":
          dispatch(disconnectReader());
          break;
        case "ConnectReaderDisplay":
          dispatch(connectReaderHandler(currentReader));
          break;
        case "SetReaderDisplay":
          const requestBody = {
            reader: currentReader.id,
            type: "cart",
            amount: parseInt(amountDetails.amount),
            tax: parseFloat(amountDetails.serviceFee),
            total: amountDetails.total,
            currency: amountDetails.currency,
            referenceId: refId,
          };
          dispatch(setDisplayReader(requestBody));
          break;
        case "ClearReaderDisplay":
          dispatch(clearReaderDisplay());
          break;
        case "PosProcessPaymentApiPending":
          const posReq = {
            paymentId: paymentId,
            authToken: authToken, // NOTE - Currently accepting the auth_token as bearer token and order_payload in the post body
            postMessage: {
              card_present: {
                reader_id: currentReader.id,
                location_id: currentLocation.provider_location_id,
              },
              payment_mode: currentPaymentMode,
              payment_method: currentPaymentMethod,
              order_jwt: orderPayloadToken,
            },
          };
          dispatch(processPaymentApi(posReq));
          break;
        case "PosProcessPaymentApiComplete":
          dispatch(collectPaymentMethod(posClientSecret));
          break;
        case "PosPaymentPending":
          dispatch(processPosPayment(paymentIntent));
          break;
        case "CheckStatusPending":
          const checkReq = {
            paymentId: paymentId,
            orderPayloadToken: orderPayloadToken, // NOTE - Currently accepting the auth_token as bearer token and order_payload as query param
            authToken: authToken,
            paymentStatus: paymentStatus,
          };
          dispatch(checkStatus(checkReq));
          break;
        case "RedirectBack":
          const queryParams = {
            url: browserCallbackUrl,
            payload: statusPayload,
          };
          redirectBack(queryParams);
          break;
        case "GetStatusPayload":
          const getPayloadReq = {
            paymentId: paymentId,
            orderPayloadToken: orderPayloadToken, // NOTE - Currently accepting the Order Payload as bearer token
            authToken: authToken,
          };
          dispatch(getStatusPayload(getPayloadReq));
          break;
        case "SetPaymentCancel":
          const cancelQuery = {
            paymentId: paymentId,
            authToken: authToken,
            orderPayloadToken: orderPayloadToken, // NOTE - Currently accepting the auth_token as bearer token and order_payload in the patch body
          };
          dispatch(cancelPayment(cancelQuery));
          break;
        case "ReCheckStatusPending":
          const recheckReq = {
            paymentId: paymentId,
            authToken: authToken,
            orderPayloadToken: orderPayloadToken, // NOTE - Currently accepting the auth_token as bearer token and order_payload as query param
          };
          dispatch(checkStatus(recheckReq));
          break;
        case "SetDiscoverReaders":
          dispatch(getStatusDiscoverReaders(currentLocation));
          break;
        case "PosBackendPaymentPending":
          dispatch(
            confirmPosPaymentBackend({
              authToken: authToken,
              paymentId: paymentId,
              postMessage: {
                payment_method: currentPaymentMethod,
                payment_mode: currentPaymentMode,
                order_jwt: orderPayloadToken,
                card_present: {
                  location_id: currentLocation.provider_location_id,
                  reader_id: currentReader.id,
                },
              },
            })
          );
          break;
        case "BraintreeConfirmPaymentPending":
          dispatch(
            confirmBraintreePayment({
              authToken: authToken,
              paymentId: paymentId,
              request: {
                payment_mode: currentPaymentMode,
                payment_method: currentPaymentMethod,
                card: {
                  payment_method_token: paymentMethodNonce,
                },
                order_jwt: orderPayloadToken,
              },
            })
          );
          break;
        default:
          break;
      }
    }

    if (locationChangeHandler.isLocationChangeNeeded) {
      switch (locationChangeHandler.startStage) {
        case startStage.LoadLocationsReadersPending:
          dispatch(discoverReaders(currentLocation));
          break;
        // case startStage.CheckStripeTerminalConnection:
        //     const stripeConn = createTerminal();
        //     stripeConn.then((result) => {
        //       dispatch(CheckStripeTerminalConnection(result));
        //     });
        //     break;
        case startStage.CheckReaderStatusAndUpdate:
          dispatch(connectReaderHandler(currentReader));
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewStructure, locationChangeHandler]);

  return { viewStructure };
};

export default useAppHook;
