import React from "react";
import StripePaymentElement from "./StripePaymentElement";
import useCardMethodComponentHook from "../../hooksAndUiInteractions/CardMethodComponentHook";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box } from "@mui/material";
import { useEffect } from "react";
import {
  CancelPayment,
  ProcessCardPaymentAction,
} from "../actionButtons/allActions";

export default function CardMethodComponent() {
  const { options, publicKey, stripePromise, setStripePromise, onlineErrors } = useCardMethodComponentHook();

  useEffect(() => {
    if (publicKey && !stripePromise) {
      setStripePromise(loadStripe(publicKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, stripePromise]);
  return (
    <div >
      <>
        <div className="stripe-payment-card-main" data-testid="card-div-test">
          <Elements stripe={stripePromise} options={options}>
            <StripePaymentElement />
          </Elements>
        </div>
        <div className="error-div-main">
        {onlineErrors && <div>{onlineErrors}</div>}
        </div>
        <Box className="main-button-section" data-testid="card-buttons-test">
          <ProcessCardPaymentAction />
          <div className="cancel-div">
              <CancelPayment />
            </div>
        </Box>
      </>

    </div>
  );
}
