export const messagetexts = {
  forget_password_updated:
    "Your password has been updated successfully. Please log in using your new password",
  forget_password_failed: "Password updation failed, Please try again",
  login_failed: "Login failed, Please try again",
  otp_generated: "Code generated, check your mail",
  otp_generation_failed: "Code generation failed, Please try again",
  invalid_email_forget_password: "Entered invalid email, Please try again",
  config_not_loaded: "Configuration Not Loaded, Please try again later",
  integration_saved: "Integration Saved Successfully",
  integration_updated: "Integration Updated Successfully",
  tenant_updated: "Tenant Updated Successfully",
  integration_save_failed: "Failed to save the integration, Please try again",
  tenant_saved: "Tenant Saved Successfully",
  tenant_save_failed: "Failed to save the tenant, Please try again",
  location_saved: "Location Saved Successfully",
  location_updated: "Location Updated Successfully",
  location_update_failed: "Failed to Update Location, Please Try Again",
  location_save_failed: "Failed to save the location, Please try again",
  reader_saved: "Reader Saved successfully",
  reader_save_failed: "Failed to save the reader, Please try again",
  tenant_update_failed: "Failed to Update Tenant, please try again",
  integration_deleted: "Integration deleted successfully",
  integration_update_failed: "Failed to Update Integration, please try again",
  integration_delete_failed:
    "Could not delete Integration, Please try again later",
  tenant_deleted: "Tenant deleted successfully",
  tenant_delete_failed: "Could not delete Tenant, Please try again later",
  location_deleted: "Location deleted successfully",
  location_delete_failed: "Could not delete Location, Please try again later",
  associated_entities:
    "Please remove the associated entities before deleting the record",
  reader_deleted: "Reader deleted successfully",
  reader_delete_failed: "Could not delete Reader, Please try again later",
  api_not_available: "Can not Fetch data currently, Please try again later",
  tenant_not_found: "No Tenants Found, Save Integration to proceed",
  unsaved_changes:
    "Please save your changes before proceeding to prevent data loss",
  refresh_status_errored: "Could not refresh status, please try again",
  refresh_status_complete: "Refreshed status successfully",
  discard_not_available: "Could not discard your changes, please try again",
};

export const errorsAndWarnings = [
  {
    scenario: "location_updated",
    code: "",
    main_message_text: messagetexts.location_updated,
  },
  {
    scenario: "location_update_failed",
    code: "",
    main_message_text: messagetexts.location_update_failed,
  },
  {
    scenario: "tenant_not_found",
    code: "",
    main_message_text: messagetexts.tenant_not_found,
  },
  {
    scenario: "tenant_not_found",
    code: "",
    main_message_text: messagetexts.tenant_not_found,
  },
  {
    scenario: "forget_password_updated",
    code: "",
    main_message_text: messagetexts.forget_password_updated,
  },
  {
    scenario: "forget_password_failed",
    code: "",
    main_message_text: messagetexts.forget_password_failed,
  },
  {
    scenario: "login_failed",
    code: "",
    main_message_text: messagetexts.login_failed,
  },
  {
    scenario: "otp_generated",
    code: "",
    main_message_text: messagetexts.otp_generated,
  },
  {
    scenario: "otp_generation_failed",
    code: "",
    main_message_text: messagetexts.otp_generation_failed,
  },
  {
    scenario: "invalid_email_forget_password",
    code: "",
    main_message_text: messagetexts.invalid_email_forget_password,
  },
  {
    scenario: "config_not_loaded",
    code: "",
    main_message_text: messagetexts.config_not_loaded,
  },
  {
    scenario: "integration_updated",
    code: "",
    main_message_text: messagetexts.integration_updated,
  },
  {
    scenario: "integration_saved",
    code: "",
    main_message_text: messagetexts.integration_saved,
  },
  {
    scenario: "integration_saved",
    code: "",
    main_message_text: messagetexts.integration_saved,
  },
  {
    scenario: "tenant_update_failed",
    code: "",
    main_message_text: messagetexts.tenant_update_failed,
  },
  {
    scenario: "integration_update_failed",
    code: "",
    main_message_text: messagetexts.integration_update_failed,
  },
  {
    scenario: "integration_save_failed",
    code: "",
    main_message_text: messagetexts.integration_save_failed,
  },
  {
    scenario: "tenant_saved",
    code: "",
    main_message_text: messagetexts.tenant_saved,
  },
  {
    scenario: "tenant_updated",
    code: "",
    main_message_text: messagetexts.tenant_updated,
  },
  {
    scenario: "tenant_save_failed",
    code: "",
    main_message_text: messagetexts.tenant_save_failed,
  },
  {
    scenario: "location_saved",
    code: "",
    main_message_text: messagetexts.location_saved,
  },
  {
    scenario: "location_save_failed",
    code: "",
    main_message_text: messagetexts.location_save_failed,
  },
  {
    scenario: "reader_saved",
    code: "",
    main_message_text: messagetexts.reader_saved,
  },
  {
    scenario: "reader_save_failed",
    code: "",
    main_message_text: messagetexts.reader_save_failed,
  },
  {
    scenario: "integration_deleted",
    code: "",
    main_message_text: messagetexts.integration_deleted,
  },
  {
    scenario: "integration_delete_failed",
    code: "",
    main_message_text: messagetexts.integration_delete_failed,
  },
  {
    scenario: "tenant_deleted",
    code: "",
    main_message_text: messagetexts.tenant_deleted,
  },
  {
    scenario: "tenant_delete_failed",
    code: "",
    main_message_text: messagetexts.tenant_delete_failed,
  },
  {
    scenario: "location_deleted",
    code: "",
    main_message_text: messagetexts.location_deleted,
  },
  {
    scenario: "location_delete_failed",
    code: "400",
    main_message_text: messagetexts.location_delete_failed,
  },
  {
    scenario: "reader_deleted",
    code: "",
    main_message_text: messagetexts.reader_deleted,
  },
  {
    scenario: "reader_delete_failed",
    code: "",
    main_message_text: messagetexts.reader_delete_failed,
  },
  {
    scenario: "api_not_available",
    code: "",
    main_message_text: messagetexts.api_not_available,
  },
  {
    scenario: "unsaved_changes",
    code: "",
    main_message_text: messagetexts.unsaved_changes,
  },
  {
    scenario: "associated_entities",
    code: "",
    main_message_text: messagetexts.associated_entities,
  },
  {
    scenario: "refresh_status_errored",
    code: "",
    main_message_text: messagetexts.refresh_status_errored,
  },
  {
    scenario: "refresh_status_complete",
    code: "",
    main_message_text: messagetexts.refresh_status_complete,
  },
  {
    scenario: "discard_not_available",
    code: "",
    main_message_text: messagetexts.discard_not_available,
  },
];

export const alertObject = (scenario) => {
  return errorsAndWarnings.filter((error) => error.scenario === scenario)[0];
};

export const allBraintreePaymentModes = {
  online: [
    {
      display: "Card",
      code: "card",
    },
  ],
};
export const allPaymentModes = {
  online: [
    {
      display: "ACSS Debit",
      code: "acss_debit",
    },
    {
      display: "Affirm",
      code: "affirm",
    },
    {
      display: "Afterpay/Clearpay",
      code: "afterpay_clearpay",
    },
    {
      display: "Alipay",
      code: "alipay",
    },
    {
      display: "AU BECS Debit",
      code: "au_becs_debit",
    },
    {
      display: "BACS Debit",
      code: "bacs_debit",
    },
    {
      display: "Bancontact",
      code: "bancontact",
    },
    {
      display: "BLIK",
      code: "blik",
    },
    {
      display: "Boleto",
      code: "boleto",
    },
    {
      display: "Card",
      code: "card",
    },
    {
      display: "Cash App",
      code: "cashapp",
    },
    {
      display: "EPS",
      code: "eps",
    },
    {
      display: "FPX",
      code: "fpx",
    },
    {
      display: "Giropay",
      code: "giropay",
    },
    {
      display: "GrabPay",
      code: "grabpay",
    },
    {
      display: "iDEAL",
      code: "ideal",
    },
    {
      display: "Klarna",
      code: "klarna",
    },
    {
      display: "Konbini",
      code: "konbini",
    },
    {
      display: "Link",
      code: "link",
    },
    {
      display: "OXXO",
      code: "oxxo",
    },
    {
      display: "P24",
      code: "p24",
    },
    {
      display: "PayNow",
      code: "paynow",
    },
    {
      display: "PIX",
      code: "pix",
    },
    {
      display: "PromptPay",
      code: "promptpay",
    },
    {
      display: "SEPA Debit",
      code: "sepa_debit",
    },
    {
      display: "SOFORT",
      code: "sofort",
    },
    {
      display: "US Bank Account",
      code: "us_bank_account",
    },
    {
      display: "WeChat Pay",
      code: "wechat_pay",
    },
    {
      display: "Zip",
      code: "zip",
    },
  ],
  in_person: [
    {
      display: "POS",
      code: "card_present",
    },
  ],
};

export const nonSupportedModes = {
  online: [
    {
      display: "ACSS Debit",
      code: "acss_debit",
    },
    {
      display: "Affirm",
      code: "affirm",
    },
    {
      display: "Afterpay/Clearpay",
      code: "afterpay_clearpay",
    },
    {
      display: "Alipay",
      code: "alipay",
    },
    {
      display: "AU BECS Debit",
      code: "au_becs_debit",
    },
    {
      display: "BACS Debit",
      code: "bacs_debit",
    },
    {
      display: "Bancontact",
      code: "bancontact",
    },
    {
      display: "BLIK",
      code: "blik",
    },
    {
      display: "Boleto",
      code: "boleto",
    },
    {
      display: "Cash App",
      code: "cashapp",
    },
    {
      display: "EPS",
      code: "eps",
    },
    {
      display: "FPX",
      code: "fpx",
    },
    {
      display: "Giropay",
      code: "giropay",
    },
    {
      display: "GrabPay",
      code: "grabpay",
    },
    {
      display: "iDEAL",
      code: "ideal",
    },
    {
      display: "Klarna",
      code: "klarna",
    },
    {
      display: "Konbini",
      code: "konbini",
    },
    {
      display: "Link",
      code: "link",
    },
    {
      display: "OXXO",
      code: "oxxo",
    },
    {
      display: "P24",
      code: "p24",
    },
    {
      display: "PayNow",
      code: "paynow",
    },
    {
      display: "PIX",
      code: "pix",
    },
    {
      display: "PromptPay",
      code: "promptpay",
    },
    {
      display: "SEPA Debit",
      code: "sepa_debit",
    },
    {
      display: "SOFORT",
      code: "sofort",
    },
    {
      display: "US Bank Account",
      code: "us_bank_account",
    },
    {
      display: "WeChat Pay",
      code: "wechat_pay",
    },
    {
      display: "Zip",
      code: "zip",
    },
  ],
  in_person: [],
};

export const getSupportedPaymentModes = (
  allPaymentModes,
  nonSupportedModes
) => {
  let supportedModes = {};

  Object.keys(allPaymentModes).forEach((key) => {
    supportedModes[key] = allPaymentModes[key].filter(
      (mode) =>
        !nonSupportedModes[key].some(
          (nonSupportedMode) => nonSupportedMode.code === mode.code
        )
    );
  });

  return supportedModes;
};

export const TemplateIntegration = {
  Int_id: "INT-0000",
  isEditable: false,
  integration_name: "",
  integration_code: "",
  enabled_payment_methods: {
    online: [],
    in_person: [],
  },
  enabled_payment_gateways: [],
  enabled_payment_method_gateways: {
    in_person: {
      card_present: "",
    },
    online: {
      card: "",
    },
  },
  stripe_supported_payment_methods: {
    online: [
      {
        display: null,
        code: null,
      },
    ],
    in_person: [
      {
        display: null,
        code: null,
      },
    ],
  },
  braintree_supported_payment_methods: {
    online: [
      {
        display: null,
        code: null,
      },
    ],
  },
  braintree_enabled_payment_methods: {
    online: [],
  },
  service_charges: "",
  service_charges_percentage: null,
  service_charges_error: null,
  selected_tenant_id: null,
  tenants: [],
  record_status: "A",
  changed_ind: false,
  disable_edit_list: [],
  code_error: null,
};

export const TemplateTenant = {
  Tnt_id: "TNT-0000",
  isEditable: true,
  display_name: null,
  tenant_code: null,
  service_charges: "",
  service_charges_percentage: "",
  service_charges_error: null,
  enabled_payment_methods: {
    online: [],
    in_person: [],
  },
  enabled_payment_gateways: [],
  enabled_payment_method_gateways: {
    in_person: {
      card_present: "",
    },
    online: {
      card: "",
    },
  },
  stripe_supported_payment_methods: {
    online: [
      {
        display: null,
        code: null,
      },
    ],
    in_person: [
      {
        display: null,
        code: null,
      },
    ],
  },
  braintree_supported_payment_methods: {
    online: [
      {
        display: null,
        code: null,
      },
    ],
  },
  braintree_enabled_payment_methods: {
    online: [],
  },
  payment_gateway_key_paths: {
    stripe: {
      isPublicKeyChanged: false,
      isPrivateKeyChanged: false,
      public_key: null,
      private_key: null,
    },
    braintree: {
      isPublicKeyChanged: false,
      isPrivateKeyChanged: false,
      isMerchantIdChanged: false,
      private_key: null,
      public_key: null,
      merchant_id: null,
    },
  },
  api_callback_subscriptions: [],
  auth_keys: [],
  new_auth_keys: [],
  order_payload_keys: [],
  new_order_payload_keys: [],
  public_status_payload_keys: null,
  selectedLocation_id: null,
  locations: [],
  record_status: "A",
  changed_ind: false,
  stripe_error: null,
  public_stripe_error: null,
  private_stripe_error: null,
  public_braintree_error: null,
  private_braintree_error: null,
  merchant_id_error: null,
  order_error: null,
  status_error: null,
  auth_error: null,
  code_error: null,
  disable_edit_list: ["public_status_payload_keys"],
};
export const TemplateAuth = {
  Auth_id: "AUTH-0000",
  id: null,
  key_value: "",
  thumbprint: "",
};
export const TemplateOrder = {
  Order_id: "ORDER-0000",
  id: null,
  key_value: "",
  thumbprint: "",
};
export const TemplateApi = {
  Api_id: "API-0000",
  callback_url: "",
  subscription_status: "",
  api_error: "",
};
export const TemplateLocation = {
  Loc_id: "LOC-0000",
  isExpanded: false,
  toggleToEdit: true,
  isEditable: false,
  isLocationAdded: false,
  isLocationEdited: false,
  display_name: "",
  provider_location_id: "",
  address: {
    line1: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  },
  selectedReader: null,
  readers: [],
  nameError: "",
  lineError: "",
  cityError: "",
  stateError: "",
  countryError: "",
  postalError: "",
  record_status: "A",
  changed_ind: false,
  process_payment_at_backend: null,
  disable_edit_list: [],
};
export const TemplateReader = {
  Rdr_id: "RDR-0000",
  isEditable: true,
  pairing_code: "",
  reader_label: "",
  reader_id: "",
  record_status: "A",
  labelerror: "",
  process_payment_at_backend: null,
  disable_edit_list: [],
};
export const AdminTypes = {
  GLOBAL: "GLOBAL",
  TENANT: "TENAT",
};

export const JPJS = (v) => {
  return JSON.parse(JSON.stringify(v));
};

export const controlMetadataTemplate = {
  Updating_Section: "",
  ID_Level: [],
  updating_key: "",
  updating_value: "",
};

export const selInt = (stAdminIm) => {
  if (!stAdminIm || !stAdminIm.listOfIntegrations) {
    return null;
  }

  let selIntegration = stAdminIm.listOfIntegrations?.filter(
    (integration) => integration.Int_id === stAdminIm?.selectedIntegration_id
  )[0];
  return selIntegration;
};

export const selTnt = (stAdminIm) => {
  let selectedInt = selInt(stAdminIm);
  let selectedTnt = selectedInt?.tenants.filter(
    (tnt) => tnt.Tnt_id === selectedInt?.selectedTenant_id
  )[0];
  return selectedTnt;
};

export const selCurrentLoc = (stAdminIm, Loc_id) => {
  let selectedLoc = selTnt(stAdminIm)?.locations.filter(
    (loc) => loc.Loc_id === Loc_id
  )[0];
  return selectedLoc;
};

export const selCurrentReader = (stAdminIm, Loc_id, Rdr_id) => {
  let selectedReader = selCurrentLoc(stAdminIm, Loc_id)?.readers.filter(
    (rdr) => rdr.Rdr_id === Rdr_id
  )[0];
  return selectedReader;
};

export const generateNewID = (state, prefix) => {
  state.integrationManagement.nextNewId++;
  return prefix + "-" + state.integrationManagement.nextNewId;
};

export const getCurrentEditingStatus = (integration) => {
  integration.tenants.forEach((tnt) => {
    tnt.locations.forEach((loc) => {
      loc.changed_ind =
        loc.record_status !== "U" || loc.readers.some((r) => r.changed_ind);
    });
    tnt.changed_ind =
      tnt.record_status !== "U" || tnt.locations.some((loc) => loc.changed_ind);
  });
  integration.changed_ind =
    integration.record_status !== "U" ||
    integration.tenants.some((x) => x.changed_ind);
};
