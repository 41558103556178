import { useDispatch, useSelector } from "react-redux";
import { closeAlertBox, closeSaveAlertBox } from "../../slice/AdminSlice";

export const useAlertHook = () => {
  const dispatch = useDispatch();
  const currentAlert = useSelector(
    (state) => state.admin.alertAndErrors.currentAlert
  );
  const isModalOpen = useSelector((state) => state.admin.isModalOpen);
  const isSaveModalOpen = useSelector((state) => state.admin.isSaveModalOpen);

  const toggleModal = (value) => {
    dispatch(closeAlertBox(value));
  };
  const closeSaveModal = (value) => {
    dispatch(closeSaveAlertBox(value));
  };

  return {
    currentAlert,
    isModalOpen,
    isSaveModalOpen,
    toggleModal,
    closeSaveModal,
  };
};
