import { useSelector } from "react-redux";
import { TransitionViewModes } from "../../stateAndConstants/paymentStateAndConstants/constants";

const useTransitionViewHook = () => {
  const viewStructure = useSelector(
    (state) => state.main.UIInteractions.viewStructure
  );

  const transitionMode = TransitionViewModes.filter(
    (tm) => tm.mode === viewStructure.MainViewName
  )[0];

  const applicationErrors = useSelector(
    (state) => state.main.UIInteractions.applicationErrors
  );

  return {
    viewStructure,
    transitionMode,
    applicationErrors,
  };
};

export default useTransitionViewHook;
