import React from "react";

export default function TopNavBar() {
  return (
    <div className="top-navbar-payment">
      <img
        src="/Images/quicketsolutionslogo.svg"
        alt="Quicket Logo"
        width={130}
        height={60}
        className="logo"
      />
      <div className="navbar-text"> Payment Service </div>
    </div>
  );
}
