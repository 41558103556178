import { EditOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import EditableConfig from "./CustomControls/EditableConfig";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import React, { useState } from "react";
import TenantTable from "./TenantTable";
import animation from "../../Assets/animation-notfound.svg";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";

import ClearIcon from "@mui/icons-material/Clear";

export default function IntegrationOnboarding() {
  const {
    selectedIntegration,
    isEditable,
    integrationUpdate,
    allowEditIntegration,
    saveCreatedIntegration,
    getIntegrationMetaData,
    togglePaymentMethod,
    setDiscardIntegration,
  } = useIntegrationHook();

  const EditableConfig_1 = JPJS(getIntegrationMetaData);
  EditableConfig_1.updating_key = "integration_name";
  const EditableConfig_2 = JPJS(getIntegrationMetaData);
  EditableConfig_2.updating_key = "integration_code";

  const EditableConfig_3 = JPJS(getIntegrationMetaData);
  EditableConfig_3.updating_key = "service_charges_percentage";

  const getDefaultChecked = (mode, type, gateway) => {
    if (
      selectedIntegration &&
      selectedIntegration.enabled_payment_gateways.includes(gateway) &&
      selectedIntegration.enabled_payment_methods &&
      selectedIntegration.enabled_payment_methods[type].length > 0 &&
      selectedIntegration.enabled_payment_method_gateways[type][mode.code] ===
        gateway
    ) {
      return selectedIntegration.enabled_payment_methods[type].some(
        (enabledMode) => enabledMode.code === mode.code
      );
    }
    if (
      selectedIntegration &&
      selectedIntegration.enabled_payment_gateways.includes(gateway) &&
      selectedIntegration.braintree_enabled_payment_methods &&
      selectedIntegration.braintree_enabled_payment_methods[type] &&
      selectedIntegration.enabled_payment_method_gateways[type][mode.code] ===
        gateway
    ) {
      return selectedIntegration.braintree_enabled_payment_methods[type].some(
        (enabledMode) => enabledMode.code === mode.code
      );
    }
    return false;
  };

  const [isChecked, setIsChecked] = useState({
    isStripeChecked: false,
    isBraintreeChecked: false,
  });

  console.log(selectedIntegration, "selectedIntegration");
  return (
    <>
      {selectedIntegration ? (
        <div className="Integration-card">
          <div className="Integration-header">
            <div className="header-content">
              <div className="main">
                <Typography className="MainText">Integration &nbsp;</Typography>
                <Typography className="SubMainText">Code &nbsp;</Typography>
                <Typography className="SubMainText">
                  Service Charges(%) &nbsp;
                </Typography>
              </div>
              <div className="main">
                <Typography className="MainText">: &nbsp; &nbsp;</Typography>
                <Typography className="SubMainText">: &nbsp; &nbsp;</Typography>
                <Typography className="SubMainText">: &nbsp; &nbsp;</Typography>
              </div>
              <div className="main">
                <div>
                  <EditableConfig
                    type={selectedIntegration}
                    value={selectedIntegration.integration_name}
                    classNameprop="MainText"
                    dataTestIdProp="integration-name-input"
                    updateValue={integrationUpdate}
                    controlMetaData={EditableConfig_1}
                    newcssforinput="inputbggrey"
                    disable_edit_list={selectedIntegration.disable_edit_list}
                  />
                </div>
                <div>
                  <EditableConfig
                    thisObjectName="integration_code"
                    type={selectedIntegration}
                    value={selectedIntegration.integration_code}
                    classNameprop="SubMainText"
                    dataTestIdProp="integration-code-input"
                    updateValue={integrationUpdate}
                    controlMetaData={EditableConfig_2}
                    newcssforinput="inputbggrey"
                    disable_edit_list={selectedIntegration.disable_edit_list}
                  />
                  {selectedIntegration.code_error !== null ? (
                    <div className="error-message">
                      {selectedIntegration.code_error}
                    </div>
                  ) : null}
                </div>
                <div>
                  <EditableConfig
                    thisObjectName="service_charges_percentage"
                    type={selectedIntegration}
                    value={selectedIntegration.service_charges_percentage}
                    classNameprop="SubMainText"
                    dataTestIdProp="service-charges-input"
                    updateValue={integrationUpdate}
                    controlMetaData={EditableConfig_3}
                    newcssforinput="inputbggrey"
                    disable_edit_list={selectedIntegration.disable_edit_list}
                  />
                  {selectedIntegration.service_charges_error !== null ? (
                    <div className="error-message">
                      {selectedIntegration.service_charges_error}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="actions">
              {isEditable ? (
                <>
                  <Button
                    className="save-integration-button"
                    variant="contained"
                    disabled={
                      selectedIntegration.service_charges_error !== null
                    }
                    startIcon={<DownloadDoneIcon />}
                    data-testid="save-integration-test-button"
                    onClick={() => {
                      saveCreatedIntegration(selectedIntegration);
                    }}
                  >
                    Save
                  </Button>
                  {selectedIntegration.record_status !== "A" ? (
                    <Button
                      className="discard-integration-button"
                      variant="contained"
                      startIcon={<ClearIcon />}
                      data-testid="discard-integration-test-button"
                      onClick={() => setDiscardIntegration(selectedIntegration)}
                    >
                      Discard
                    </Button>
                  ) : null}
                </>
              ) : (
                <Button
                  className="edit-integration-button"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  data-testid="edit-integration-test-button"
                  onClick={() => {
                    allowEditIntegration();
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
          {/* <div className="line-divider" /> */}
          <div className="payment-selector">
            <span className="heading-ps">Payment Mode </span>
            <div className="payment-container">
              <div className="stripe-config">
                <div className="header">Stripe Payment Modes</div>
                <div className="container-options">
                  {isEditable ? (
                    <>
                      {selectedIntegration.stripe_supported_payment_methods.online.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "online",
                                "stripe"
                              )}
                              value={mode.code}
                              disabled={isChecked.isStripeChecked}
                              onChange={(e) => {
                                setIsChecked({
                                  isStripeChecked: !e.target.checked,
                                  isBraintreeChecked: e.target.checked,
                                });
                                togglePaymentMethod({
                                  type: "online",
                                  value: mode,
                                  gateway: "stripe",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              Online - {mode.display}
                            </label>
                          </div>
                        )
                      )}

                      {selectedIntegration.stripe_supported_payment_methods.in_person.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "in_person",
                                "stripe"
                              )}
                              value={mode.code}
                              onChange={(e) => {
                                togglePaymentMethod({
                                  type: "in_person",
                                  value: mode,
                                  gateway: "stripe",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              In Person - {mode.display}
                            </label>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {(selectedIntegration?.enabled_payment_methods?.online ===
                        undefined ||
                        selectedIntegration?.enabled_payment_methods?.online
                          .length === 0) &&
                      (selectedIntegration?.enabled_payment_methods
                        ?.in_person === undefined ||
                        selectedIntegration?.enabled_payment_methods?.in_person
                          .length === 0) ? (
                        <p className="no-payment-message">
                          No payment modes enabled
                        </p>
                      ) : null}

                      {selectedIntegration.enabled_payment_methods.online.map(
                        (mode) =>
                          selectedIntegration.enabled_payment_method_gateways
                            .online[mode.code] === "stripe" ? (
                            <div className="option-1" key={mode.code}>
                              <label className="checbox-label-new">
                                Online - {mode.display}
                              </label>
                            </div>
                          ) : null
                      )}

                      {selectedIntegration.enabled_payment_methods.in_person.map(
                        (mode) =>
                          selectedIntegration.enabled_payment_method_gateways
                            .in_person[mode.code] === "stripe" ? (
                            <div className="option-1" key={mode.code}>
                              <label className="checbox-label-new">
                                In Person - {mode.display}
                              </label>
                            </div>
                          ) : null
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="braintree-config">
                <div className="header">Braintree Payment Modes</div>
                <div className="container-options">
                  {isEditable ? (
                    <>
                      {selectedIntegration.braintree_supported_payment_methods.online.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "online",
                                "braintree"
                              )}
                              value={mode.code}
                              disabled={isChecked.isBraintreeChecked}
                              onChange={(e) => {
                                setIsChecked({
                                  isStripeChecked: e.target.checked,
                                  isBraintreeChecked: !e.target.checked,
                                });
                                togglePaymentMethod({
                                  type: "online",
                                  value: mode,
                                  gateway: "braintree",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              Online - {mode.display}
                            </label>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {!selectedIntegration?.braintree_enabled_payment_methods
                        ?.online.length > 0 ? (
                        <p className="no-payment-message">
                          No payment method enabled
                        </p>
                      ) : (
                        <>
                          {selectedIntegration.braintree_enabled_payment_methods.online.map(
                            (mode) =>
                              selectedIntegration
                                .enabled_payment_method_gateways.online[
                                mode.code
                              ] === "braintree" ? (
                                <div className="option-1" key={mode.code}>
                                  <label className="checbox-label-new">
                                    Online- {mode.display}
                                  </label>
                                </div>
                              ) : null
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="line-divider" />
          <TenantTable />
        </div>
      ) : (
        <div className="no-selected-integration">
          <img src={animation} width={200} height={80} alt="notfound" />
          No Selected Integration
        </div>
      )}
    </>
  );
}
