import React, { useEffect } from "react";
import { Button, Typography, Modal, IconButton } from "@mui/material";
import { useAlertHook } from "../adminHooks/errorsAlertsHook";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function AlertModal() {
  const {
    currentAlert,
    isModalOpen,
    isSaveModalOpen,
    toggleModal,
    closeSaveModal,
  } = useAlertHook();

  useEffect(() => {
    if (isSaveModalOpen) {
      const timeoutId = setTimeout(() => {
        closeSaveModal(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveModalOpen]);
  return (
    <div>
      {isModalOpen && (
        <Modal outline="none" open={isModalOpen}>
          <div className="alertModal-admin">
            <div className="alertText">
              <Typography id="modal-modal-description">
                {currentAlert?.main_message_text}
              </Typography>
            </div>
            <div className="alertModal-button">
              <Button
                variant="outlined"
                className="ok-button"
                data-testid="modal-ok-button"
                onClick={() => toggleModal(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isSaveModalOpen && (
        <div className="saved-modal">
          <div className="saved-message">
            <p> {currentAlert?.main_message_text}</p>
          </div>
          <div>
            <IconButton onClick={() => closeSaveModal(false)}>
              <CloseOutlinedIcon className="cancel-icon" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}
