import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import EditableConfig from "./CustomControls/EditableConfig";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import copysvg from "../../Assets/Copy_svg.svg";
import copysuccess from "../../Assets/copied_green.svg";

export default function OrderForm({ orderkey, index }) {
  const {
    selectedTenant,
    isEditableTenant,
    orderUpdate,
    getOrderMetaData,
    closeorderkey,
  } = useIntegrationHook();
  let EditableConfig_4 = JPJS(getOrderMetaData);
  EditableConfig_4.ID_Level.push(orderkey.Order_id);
  EditableConfig_4.updating_key = "orderkey";
  const [copyState, setcopyState] = useState(false);
  let value = "";

  if (orderkey.key_value === null || "") {
    value = "null";
  } else {
    value = orderkey.key_value;
  }
  useEffect(() => {
    if (copyState) {
      const timer = setTimeout(() => {
        setcopyState(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copyState]);
  return (
    <div className="key-form-container">
      <div className="editableconfig">
        {isEditableTenant ? (
          <EditableConfig
            thisObjectName="order"
            type={selectedTenant}
            value={orderkey.key_value}
            classNameprop="admin-key-value"
            key={index}
            dataTestIdProp={`tenant-admin-auth-key-input-${index}`}
            updateValue={orderUpdate}
            controlMetaData={EditableConfig_4}
            newcssforinput="inputbggrey-margin"
          />
        ) : null}
      </div>
      <div className="admin-key-newaction">
        <IconButton
          onClick={() => {
            closeorderkey(orderkey.Order_id);
          }}
        >
          <img
            alt="Delete Button"
            src="/Images/icon _trash_.svg"
            width="15px"
            height="15px"
          />
        </IconButton>
        <CopyToClipboard text={value} onCopy={() => setcopyState(true)}>
          <IconButton>
            <img
              src={copyState ? copysuccess : copysvg}
              width="15px"
              height="15px"
            />
          </IconButton>
        </CopyToClipboard>
      </div>
    </div>
  );
}
