import { useDispatch, useSelector } from "react-redux";
import {
  UpdateUIValue,
  setSelectedIntegration,
  setSelectedTenant,
  addIntegration,
  addTenant,
  addLocation,
  addReader,
  enableEditing,
  enableEditingTenant,
  enableEditingLocation,
  toggleViewLocationReader,
  closeLocationAccordion,
  addauthkeyreducer, closeReaderCard,
  closeLocationCard,
  closeIntegrationCard,
  closeTenantCard,
  addorderpayloadreducer,
  addapicallreducer,
  filterIntegration,
  filterTenant,
  togglePaymentReducer,
  deleteauthkeyreducer,
  closeauthkeyreducer,
  deleteorderkeyreducer,
  closeorderkeyreducer,
  closeapiformreducer,
  filterLocation,
  setTenantPage,
  setLocationPage,
  toggleTenantPaymentReducer
} from "../../slice/AdminSlice";
import {
  JPJS,
  controlMetadataTemplate,
  selInt,
  selTnt, selCurrentLoc
} from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import {
  addIntegrationApi,
  addTenantApi,
  addLocationApi,
  addReaderApi,
  getAllIntegrationApi,
  getAllTenantsApi,
  updateLocationApi,
  getAllReadersApi,
  deleteReaderApi,
  deleteLocationApi,
  deleteIntegrationApi,
  deleteTenantApi,
  updateIntegrationApi,
  updateTenantApi,
  getSubscriptionApi,
  discardIntegration,
  discardTenant,
  discardLocation,
} from "../external/Api";

export const useIntegrationHook = () => {
  const dispatch = useDispatch();

  const getaccesstoken = useSelector(
    (state) => state.admin.accessTokens.accessToken
  );
  const listOfIntegrations = useSelector(
    (state) => state.admin.integrationManagement?.listOfIntegrations
  );
  const selectedIntegration = useSelector((state) => {
    return selInt(state.admin.integrationManagement);
  });
  const selectedTenant = useSelector((state) => {
    return selTnt(state.admin.integrationManagement);
  });
  const isEditableTenant = useSelector(
    (state) => selTnt(state.admin.integrationManagement)?.isEditable
  );
  const isEditable = useSelector(
    (state) => selInt(state.admin.integrationManagement)?.isEditable
  );
  const sidebarOpen = useSelector((state) => state.admin.isSidebarOpen);
  const searchIntegrationVariable = useSelector(
    (state) =>
      state.admin.integrationManagement?.searchVariables?.integrationInput
  );
  const searchTenantVariable = useSelector(
    (state) => state.admin.integrationManagement?.searchVariables?.tenantInput
  );
  const searchReaderVariable = useSelector(
    (state) => state.admin.integrationManagement?.searchVariables?.readerInput
  );
  const searchLocationVariable = useSelector(
    (state) => state.admin.integrationManagement?.searchVariables?.locationInput
  );
  const filteredIntegrations = useSelector(
    (state) => state.admin.integrationManagement?.filteredIntegration
  );
  // const serviceChargesInPercentage = useSelector(
  //   //for display purpose of service charges
  //   //while we are sending it post
  //   (state) =>  selInt(state.admin.integrationManagement).service_charges
  // );
  const selectedIntegration_id = useSelector(
    (state) => state.admin.integrationManagement.selectedIntegration_id
  );
  const disable_edit_list = useSelector(
    (state) => selInt(state.admin.integrationManagement)?.disable_edit_list
  );

  const getIntegrationMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "INTEGRATION";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    return c;
  });
  const getTenantMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "TENANT";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    return c;
  });
  const getApiMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "API_CALLBACK";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    c.ID_Level.push("00");
    c.ID_Level.push("00");
    // c.ID_Level.push(selApi(state.admin.integrationManagement)?.Api_id);
    return c;
  });
  const getAuthMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "AUTHKEY";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    c.ID_Level.push("00");
    c.ID_Level.push("00");
    return c;
  });
  const getOrderMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "ORDERPAYLOAD";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    c.ID_Level.push("00");
    c.ID_Level.push("00");
    return c;
  });

  const getReaderMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "READER";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    return c;
  });

  const getLocationMetaData = useSelector((state) => {
    let c = JPJS(controlMetadataTemplate);
    c.Updating_Section = "LOCATION";
    c.ID_Level.push(selInt(state.admin.integrationManagement)?.Int_id);
    c.ID_Level.push(selTnt(state.admin.integrationManagement)?.Tnt_id);
    return c;
  });
  const filterIntegrations = (val) => {
    dispatch(filterIntegration(val));
  };
  const filterTenants = (val) => {
    dispatch(filterTenant(val));
  };
  const filterLocations = (val) => {
    dispatch(filterLocation(val));
  };
  const allowEditingTenant = () => {
    dispatch(enableEditingTenant());
  };
  const allowEditIntegration = () => {
    dispatch(enableEditing());
  };
  const addNewIntegration = () => {
    dispatch(addIntegration());
  };
  const addNewTenant = () => {
    dispatch(addTenant());
    // Calculating the new pages
    const newPage = Math.ceil(
      (selectedIntegration.tenants.length + 1) / pagination.tenantsPerPage
    );
    // Update the page state
    dispatch(setTenantPage(newPage));
  };
  const addNewLocation = () => {
    dispatch(addLocation());
    const newPage = Math.ceil(
      (selectedTenant.locations.length + 1) / pagination.locationsPerPage
    );
    // Update the page state
    dispatch(setLocationPage(newPage));
  };
  const addNewReader = (val) => {
    dispatch(addReader(val));
  };
  const integrationUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const tenantUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const authUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const orderUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const apiUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const locationUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const readerUpdate = (val) => {
    dispatch(UpdateUIValue(val));
  };
  const setNewSelectedIntegration = (val) => {
    dispatch(setSelectedIntegration(val));
  };
  const setNewSelectedTenant = (val) => {
    dispatch(setSelectedTenant(val));
  };
  const setSelectedLocation = (val) => {
    dispatch(enableEditingLocation(val));
  };
  const saveCreatedIntegration = (selectedIntegration) => {
    if (selectedIntegration.record_status === "A") {
      dispatch(
        addIntegrationApi({
          selectedIntegration: selectedIntegration,
          getaccesstoken: getaccesstoken,
        })
      );
    }
    if (
      selectedIntegration.record_status === "U" ||
      selectedIntegration.record_status === "E"
    ) {
      dispatch(
        updateIntegrationApi({
          selectedIntegration: selectedIntegration,
          getaccesstoken: getaccesstoken,
        })
      );
    }
  };
  const saveCreatedTenant = (selectedIntegration, selectedTenant) => {
    if (selectedTenant.record_status === "A") {
      dispatch(
        addTenantApi({
          selectedIntegration: selectedIntegration,
          selectedTenant: selectedTenant,
          getaccesstoken: getaccesstoken,
        })
      );
    }
    if (
      selectedTenant.record_status === "U" ||
      selectedTenant.record_status === "E"
    ) {
      dispatch(
        updateTenantApi({
          selectedIntegration: selectedIntegration,
          selectedTenant: selectedTenant,
          getaccesstoken: getaccesstoken,
        })
      );
    }
  };
  const saveCreatedReader = (val) => {
    dispatch(
      addReaderApi({
        integration: selectedIntegration.integration_code,
        tenant: selectedTenant.tenant_code,
        loc: val.locprov,
        locid: val.locid,
        reader: val.reader,
        processflag: val.process_payment_at_backend,
        getaccesstoken: getaccesstoken,
      })
    );
  };
  const deleteCreatedReader = (val) => {
    dispatch(
      deleteReaderApi({
        integration: selectedIntegration.integration_code,
        tenant: selectedTenant.tenant_code,
        loc: val.locprov,
        reader: val.reader,
        getaccesstoken: getaccesstoken,
      })
    );
  };
  const getAllIntegrationAtLoad = () => {
    dispatch(
      getAllIntegrationApi({
        getaccesstoken: getaccesstoken,
      })
    );
  };

  const addAuthKeyInUI = () => {
    dispatch(addauthkeyreducer());
  };
  const addOrderPayloadInUI = () => {
    dispatch(addorderpayloadreducer());
  };
  const addApiCallbackInUI = () => {
    dispatch(addapicallreducer());
  };

  const getAllTenants = () => {
    dispatch(
      getAllTenantsApi({
        getaccesstoken: getaccesstoken,
        selectedIntegration: selectedIntegration,
      })
    );
  };

  const getAllReaders = (val) => {
    dispatch(
      getAllReadersApi({
        integration: selectedIntegration.integration_code,
        tenant: selectedTenant.tenant_code,
        loc: val,
        getaccesstoken: getaccesstoken,
      })
    );
  };

  const toggleViewLR = (val) => {
    dispatch(toggleViewLocationReader(val));
  };
  const closeLocationForm = (val) => {
    dispatch(closeLocationAccordion(val));
  };

  const closeReader = (val) => {
    dispatch(closeReaderCard(val));
  };
  const deleteauthkey = (val) => {
    dispatch(deleteauthkeyreducer(val));
  };
  const closeauthkey = (val) => {
    dispatch(closeauthkeyreducer(val));
  };

  const closeapiform = (val) => {
    dispatch(closeapiformreducer(val));
  };

  const deleteorderkey = (val) => {
    dispatch(deleteorderkeyreducer(val));
  };
  const closeorderkey = (val) => {
    dispatch(closeorderkeyreducer(val));
  };

  const saveLocationsEnable = useSelector((state) => {
    return selCurrentLoc(
      state.admin.integrationManagement,
      selTnt(state.admin.integrationManagement)?.selectedLocation_id
    );
  });

  const addUpdateLocation = (val) => {
    console.log("value coming from frontend", val);
    if (val.record_status === "A") {
      dispatch(
        addLocationApi({
          getaccesstoken: getaccesstoken,
          selectedIntegration: selectedIntegration,
          selectedTenant: selectedTenant,
          selectedLocation: val,
        })
      );
    }
    if (val.record_status === "U" || val.record_status === "E") {
      dispatch(
        updateLocationApi({
          getaccesstoken: getaccesstoken,
          selectedIntegration: selectedIntegration,
          selectedTenant: selectedTenant,
          selectedLocation: val,
        })
      );
    }
  };

  const togglePaymentMethod = (val) => {
    dispatch(togglePaymentReducer(val));
  };

  const toggleTenantPaymentMethod = (val) => {
    dispatch(toggleTenantPaymentReducer(val))
  }

  const deleteSelectedLocation = (val) => {
    if (
      val.location.record_status === "U" ||
      val.location.record_status === "E"
    ) {
      dispatch(
        deleteLocationApi({
          getaccesstoken: getaccesstoken,
          location_id: val.location.provider_location_id,
          tenant_code: val.selectedTenant.tenant_code,
          integration_code: val.selectedIntegration.integration_code,
        })
      );
    } else {
      dispatch(closeLocationCard(val.location.Loc_id));
    }
    const newPage = Math.ceil(
      (selectedTenant.locations.length + 1) / pagination.locationsPerPage
    );
    // Update the page state
    dispatch(setLocationPage(newPage));
  };

  const deleteIntegration = (int) => {
    if (int.record_status === "U" || int.record_status === "E") {
      dispatch(
        deleteIntegrationApi({
          getaccesstoken: getaccesstoken,
          integration: int,
        })
      );
    } else {
      dispatch(closeIntegrationCard(int));
    }
  };

  const deleteTenant = (val) => {
    if (val.tenant.record_status === "U" || val.tenant.record_status === "E") {
      dispatch(
        deleteTenantApi({
          getaccesstoken: getaccesstoken,
          integration: val.intcode,
          tenant: val.tenant,
        })
      );
    } else {
      dispatch(
        closeTenantCard({
          integration: val.intcode,
          tenant: val.tenant,
        })
      );
    }
    const newPage = Math.ceil(
      (selectedIntegration.tenants.length + 1) / pagination.tenantsPerPage
    );
    // Update the page state
    dispatch(setTenantPage(newPage));
  };

  const getSubscriptionStatus = (val) => {
    dispatch(
      getSubscriptionApi({
        integration_code: selectedIntegration.integration_code,
        tenant: val,
        getaccesstoken: getaccesstoken,
      })
    );
  };

  const pagination = useSelector((state) => state.admin.pagination);

  const tenantsDisplayList = selectedIntegration?.tenants.slice(
    (pagination.tenantsPage - 1) * pagination.tenantsPerPage,
    pagination.tenantsPage * pagination.tenantsPerPage
  );

  const locationsDisplayList = selectedTenant?.locations.slice(
    (pagination.locationsPage - 1) * pagination.locationsPerPage,
    pagination.locationsPage * pagination.locationsPerPage
  );

  const setPage = (value) => {
    dispatch(setTenantPage(value));
  };

  const setLocationPageNo = (value) => {
    dispatch(setLocationPage(value));
  };

  const setDiscardIntegration = (val) => {
    dispatch(
      discardIntegration({
        getaccesstoken: getaccesstoken,
        integration: val,
      })
    );
  };

  const setDiscardTenant = (val) => {
    dispatch(
      discardTenant({
        getaccesstoken: getaccesstoken,
        tenant: val,
        integration: selectedIntegration,
      })
    );
  };

  const setDiscardLocation = (val) => {
    dispatch(
      discardLocation({
        getaccesstoken: getaccesstoken,
        integration: selectedIntegration,
        tenant: selectedTenant,
        location: val,
      })
    );
  };

  return {
    setDiscardIntegration,
    setDiscardTenant,
    deleteTenant,
    setDiscardLocation,
    isEditable,
    searchTenantVariable,
    sidebarOpen,
    searchIntegrationVariable,
    searchReaderVariable,
    listOfIntegrations,
    selectedIntegration,
    selectedTenant,
    isEditableTenant,
    getIntegrationMetaData,
    getTenantMetaData,
    getaccesstoken,
    selectedIntegration_id,
    saveLocationsEnable,
    integrationUpdate,
    addNewIntegration,
    addNewTenant,
    addNewLocation,
    addNewReader,
    setNewSelectedIntegration,
    allowEditIntegration,
    setNewSelectedTenant,
    tenantUpdate,
    allowEditingTenant,
    setSelectedLocation,
    saveCreatedIntegration,
    getAllIntegrationAtLoad,
    toggleViewLR,
    closeLocationForm,
    getAllTenants,
    addAuthKeyInUI,
    getReaderMetaData,
    getLocationMetaData,
    disable_edit_list,
    locationUpdate,
    readerUpdate,
    saveCreatedReader,
    getAllReaders,
    deleteCreatedReader,
    deleteSelectedLocation,
    closeReader,
    addUpdateLocation,
    deleteIntegration,
    getAuthMetaData,
    authUpdate,
    addOrderPayloadInUI,
    orderUpdate,
    getOrderMetaData,
    saveCreatedTenant,
    getApiMetaData,
    addApiCallbackInUI,
    apiUpdate,
    filterIntegrations,
    filterTenants,
    togglePaymentMethod,
    deleteauthkey,
    closeauthkey,
    deleteorderkey,
    closeorderkey,
    closeapiform,
    getSubscriptionStatus,
    filterLocations,
    pagination,
    tenantsDisplayList,
    setPage,
    locationsDisplayList,
    setLocationPageNo,
    toggleTenantPaymentMethod
  };
};
