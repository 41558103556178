import { useSelector } from "react-redux";
import { MenuStructure } from "../../stateAndConstants/paymentStateAndConstants/constants";
import { useEffect } from "react";

const usePaymentOrderDetailsHook = () => {
  const referenceId = useSelector(
    (state) => state.main.IntegratingClientDetails.tenantDetails.referenceId
  );
  const amount = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.amount
  );
  const serviceFee = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.serviceFee
  );
  const total = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.total
  );
  const currency = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.currency
  );
  const currencySymbol = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.currencySymbol
  );

  const viewStructure = useSelector(
    (state) => state.main.UIInteractions.viewStructure
  );

  const showPaymentStatus = useSelector(
    (state) => state.main.PaymentInteractionDetails.showPaymentStatus
  );

  const paymentId = useSelector(
    (state) => state.main.PaymentInteractionDetails.paymentId
  );

  const paymentIdParts = paymentId.split("-");
  const displayRefId = paymentIdParts.slice(-1)[0].slice(-7);

  useEffect(() => {}, [showPaymentStatus]);

  let currentStatus = {};
  if (showPaymentStatus) {
    currentStatus = MenuStructure.filter(
      (tm) => tm.MenuView === viewStructure.MainViewName
    )[0];
  }

  // Reason for this is that we are filling the menustructure already
  // with the required payment components for the payment gateway
  // Hence instead of using the constant array, its better to use 
  // the already payment-gateway filtered state array
  const currentMenu = useSelector(
    (state) => state.main.UIInteractions.menuStructure
  ).filter((tm) => tm.MenuTag === viewStructure.MainViewName)[0];

  const description = useSelector(
    (state) => state.main.IntegratingClientDetails.description
  );

  return {
    referenceId,
    displayRefId,
    amount,
    serviceFee,
    total,
    currency,
    currencySymbol,
    currentMenu,
    currentStatus,
    showPaymentStatus,
    description,
  };
};

export default usePaymentOrderDetailsHook;
