import { useSelector } from "react-redux";

const usePosMethodViewHook = () => {
  const isPOSpossible = useSelector(
    (state) => state.main.UIInteractions.isPOSpossible
  );
  const stripeErrors = useSelector(
    (state) => state.main.UIInteractions.stripeErrors
  );
  const showPosModal = useSelector(
    (state) => state.main.UIInteractions.showPosModal
  );

  const processPaymentAtBackend = useSelector(
    (state) => state.main.PaymentInteractionDetails.processPaymentAtBackend
  );

  return {
    isPOSpossible,
    stripeErrors,
    showPosModal,
    processPaymentAtBackend
  };
};

export default usePosMethodViewHook;
