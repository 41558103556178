import {
  HelperMethods,
  MenuStructure,
  PaymentStatus,
  ViewModes,
  currencySymbols,
} from "./constants";
import moment from "moment/moment";

export const SetConfigurationPending = (state, action) => {
  state.UIInteractions.viewStructure.IsViewVariableSet = true;
};

export const SetConfigurationErrored = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ConfigErrored"
  )[0];
};

export const SetConfigurationComplete = (state, action) => {
  state.config = { ...action.payload };
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ConfigComplete"
  )[0];
};

export const SetBackEndHealthPending = (state, action) => {};
export const SetBackEndHealthErrored = (state, action) => {};
export const SetBackEndHealthComplete = (state, action) => {};

export const SetCreateOrderPending = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CreateOrderPending"
  )[0];
};

export const SetTokenPending = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetTokenPending"
  )[0];
};

export const SetTokenComplete = (state, action) => {
  state.PaymentInteractionDetails.authToken = action.payload?.authToken;
  state.PaymentInteractionDetails.orderPayloadToken =
    action.payload.orderPayloadToken;
  if (
    window.logRocketObj &&
    action.payload.authToken &&
    action.payload.authToken.split(".").length === 3
  ) {
    let authTokenValue = JSON.parse(
      atob(action.payload.authToken.split(".")[1])
    );
    window.logRocketObj.identify(
      (authTokenValue?.identity || "") +
        " - " +
        (authTokenValue?.user_claims?.user_id || "")
    );
  }
  // else {
  //   let orderValue = JSON.parse(
  //     atob(action.payload.orderPayloadToken?.split(".")[1])
  //   );
  //   window.logRocketObj.identify(orderValue.reference_id);
  // }
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CreateOrderPending"
  )[0];
};

export const SetTokenErrored = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetTokenErrored"
  )[0];
  // state.UIInteractions.viewStructure.IsViewVariableSet = true;
};

export const SetCreateOrderErrored = (state, action) => {
  state.PaymentInteractionDetails.createOrderSucceeded = false;
  state.UIInteractions.applicationErrors = action.payload.error
    ? action.payload.error
    : "";
  if (action.payload?.data) {
    state.IntegratingClientDetails.tenantDetails.browserCallbackUrl =
      action.payload.data?.tenant_details.browser_callback_url;
    state.PaymentInteractionDetails.paymentId = action.payload.data?.payment_id;
  }
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CreateOrderErrored"
  )[0];
};

export const SetCreateOrderRejected = (state, action) => {
  state.UIInteractions.applicationErrors =
    action.payload?.err?.response?.data?.detail || "";
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CreateOrderRejected"
  )[0];
};

export const SetCreateOrderComplete = (state, action) => {
  state.PaymentInteractionDetails.createOrderPayload = action.payload;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CreateOrderComplete"
  )[0];
};

export const ValidateAndSetAmount = (state, action) => {
  if (
    action.payload.data.amount_details.total === null ||
    action.payload.data.amount_details.total === 0
  ) {
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "CreateOrderInvalidAmount"
    )[0];
    state.UIInteractions.viewStructure.IsViewVariableSet = true;
  } else if (action.payload.status === "failure") {
    state.UIInteractions.applicationErrors = action.payload.error;
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "CreateOrderInvalidRequest"
    )[0];
    state.UIInteractions.viewStructure.IsViewVariableSet = true;
  } else {
    state.PaymentInteractionDetails.createOrderSucceeded = true;
    state.IntegratingClientDetails.amountDetails.amount =
      HelperMethods.formatFee(action.payload.data.amount_details.amount);
    state.IntegratingClientDetails.amountDetails.serviceFee =
      HelperMethods.formatFee(action.payload.data.amount_details.service_fee);
    state.IntegratingClientDetails.amountDetails.total =
      HelperMethods.formatFee(action.payload.data.amount_details.total);
    state.IntegratingClientDetails.amountDetails.currency = "usd";
    state.IntegratingClientDetails.amountDetails.currencySymbol =
      currencySymbols[
        state.IntegratingClientDetails.amountDetails.currency.toLowerCase()
      ];
    state.IntegratingClientDetails.tenantDetails.referenceId =
      action.payload.data.tenant_details.reference_id;
    state.IntegratingClientDetails.tenantDetails.browserCallbackUrl =
      action.payload.data.tenant_details.browser_callback_url;
    state.IntegratingClientDetails.description =
      action.payload.data.description || "";
    state.PaymentInteractionDetails.paymentId = action.payload.data.payment_id;

    state.PaymentInteractionDetails.paymentMethodGateways =
      action.payload.data?.payment_method_gateways;
    state.PaymentInteractionDetails.paymentModes = {};

    // Checking if POS is possible and rendering the is POS possible column
    if (
      state.PaymentInteractionDetails.paymentMethodGateways.in_person &&
      state.PaymentInteractionDetails.paymentMethodGateways.in_person
        .card_present &&
      Object.keys(
        state.PaymentInteractionDetails.paymentMethodGateways.in_person
      ).includes("card_present")
    ) {
      state.PaymentInteractionDetails.availablePaymentGateways.push(
        state.PaymentInteractionDetails.paymentMethodGateways.in_person
          .card_present
      );
      state.PaymentInteractionDetails.paymentModes["in_person"] = [
        "card_present",
      ];
      state.UIInteractions.isPOSpossible = true;
    }

    // Checking if Online Card is possible and rendering the desired Gateway
    if (
      state.PaymentInteractionDetails.paymentMethodGateways.online &&
      state.PaymentInteractionDetails.paymentMethodGateways.online.card &&
      Object.keys(
        state.PaymentInteractionDetails.paymentMethodGateways.online
      ).includes("card")
    ) {
      state.PaymentInteractionDetails.availablePaymentGateways.push(
        state.PaymentInteractionDetails.paymentMethodGateways.online.card
      );
      state.PaymentInteractionDetails.paymentModes["online"] = ["card"];
      state.UIInteractions.isCardpossible = true;
    }

    // Storing the Stripe and Braintree keys
    state.PaymentIntegrations.stripeKeys.connectionToken =
      action.payload.data?.stripe_keys?.terminal_connection_token || null;
    state.PaymentIntegrations.stripeKeys.publicKey =
      action.payload.data?.stripe_keys?.public_key || null;
    state.braintreeConfigurations.client_token =
      action.payload.data?.braintree_keys?.client_token || "";

    // Checking for Braintree gateway if existing
    state.UIInteractions.isBraintree =
      state.PaymentInteractionDetails.availablePaymentGateways.includes(
        "braintree"
      );

    // Filtering the MenuStructure based on the incoming values
    state.UIInteractions.menuStructure = MenuStructure.filter(
      (menu) =>
        Object.keys(action.payload.data?.payment_method_gateways).includes(
          menu.MenuName
        ) &&
        menu.MenuPaymentgateway ===
          action.payload.data?.payment_method_gateways[menu.MenuName][
            Object.keys(
              action.payload.data?.payment_method_gateways[menu.MenuName]
            )[0]
          ]
    );

    // TODO : Ask Naresh to re-factor his required code structure
    // Code to be scraped
    // state.PaymentInteractionDetails.paymentGateway =
    //   action.payload.data?.payment_gateway || "stripe";
    // if (state.PaymentInteractionDetails.paymentGateway === "braintree") {
    //   state.PaymentInteractionDetails.paymentModes = {
    //     online: ["card"],
    //   };
    //   state.PaymentInteractionDetails.currentPaymentMode = "online";
    //   state.PaymentInteractionDetails.currentPaymentMethod =
    //     state.PaymentInteractionDetails.paymentModes.online[0];
    //   state.braintreeConfigurations.client_token =
    //     action.payload.data?.braintree_keys?.client_token || "";
    //   state.UIInteractions.menuStructure = MenuStructure.filter(
    //     (menu) =>
    //       Object.keys(state.PaymentInteractionDetails.paymentModes).includes(
    //         menu.MenuName
    //       ) &&
    //       menu.MenuPaymentgateway ===
    //         state.PaymentInteractionDetails.paymentGateway
    //   );
    //   state.UIInteractions.isBraintree = true;
    //   state.UIInteractions.viewStructure = ViewModes.filter(
    //     (vm) => vm.ViewName === "ShowBraintreeOnlineView"
    //   )[0];
    // } else {
    //   state.PaymentInteractionDetails.paymentModes = HelperMethods.JPJS(
    //     action.payload.data.payment_modes
    //   );
    //   state.UIInteractions.menuStructure = MenuStructure.filter(
    //     (menu) =>
    //       Object.keys(action.payload.data.payment_modes).includes(
    //         menu.MenuName
    //       ) &&
    //       menu.MenuPaymentgateway ===
    //         state.PaymentInteractionDetails.paymentGateway
    //   );
    //   if (
    //     Object.keys(action.payload.data.payment_modes).includes("in_person") &&
    //     state.PaymentInteractionDetails.paymentGateway === "stripe"
    //   ) {
    //     state.UIInteractions.isPOSpossible = true;
    //     state.PaymentIntegrations.stripeKeys.connectionToken =
    //       action.payload.data.stripe_keys.terminal_connection_token;
    //   }
    //   if (
    //     Object.keys(action.payload.data.payment_modes).includes("online") &&
    //     state.PaymentInteractionDetails.paymentGateway === "stripe"
    //   ) {
    //     state.UIInteractions.isCardpossible = true;
    //     state.PaymentIntegrations.stripeKeys.publicKey =
    //       action.payload.data.stripe_keys.public_key;
    //   }
    // }
    if (state.UIInteractions.isPOSpossible) {
      state.PaymentInteractionDetails.locations =
        state.PaymentInteractionDetails.createOrderPayload.data.locations;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "LocationAvailabilityCheckPending"
      )[0];
    } else {
      if (state.UIInteractions.isCardpossible) {
        switch (action.payload.data?.payment_method_gateways.online.card) {
          case "stripe":
            state.UIInteractions.viewStructure = ViewModes.filter(
              (vm) => vm.ViewName === "ShowOnlineView"
            )[0];
            break;
          case "braintree":
            state.PaymentInteractionDetails.currentPaymentMode = "online";
            state.PaymentInteractionDetails.currentPaymentMethod =
              state.PaymentInteractionDetails.paymentModes.online[0];
            state.UIInteractions.viewStructure = ViewModes.filter(
              (vm) => vm.ViewName === "ShowBraintreeOnlineView"
            )[0];
            break;
          default:
            break;
        }
      } else {
        if (
          state.PaymentInteractionDetails.availablePaymentGateways.length < 0
        ) {
          state.UIInteractions.applicationErrors =
            "No Payment Modes available. Please try after sometime.";
          state.UIInteractions.viewStructure = ViewModes.filter(
            (vm) => vm.ViewName === "CreateOrderInvalidRequest"
          )[0];
        }
      }
    }
  }
};

export const LocationAvailabilityPending = (state, action) => {
  const posDevices = JSON.parse(localStorage.getItem("posDevices"));
  if (state.PaymentInteractionDetails.locations.length > 0) {
    if (
      posDevices !== null &&
      state.PaymentInteractionDetails.locations.some(
        (loc) => loc.provider_location_id === posDevices.location
      )
    ) {
      SetSelectedLocation(state, { payload: posDevices.location });
    } else {
      SetSelectedLocation(state, {
        payload:
          state.PaymentInteractionDetails.locations[0].provider_location_id,
      });
    }
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "LocationAvailabilityCheckComplete"
    )[0];
  } else {
    state.UIInteractions.locationErrors = "No Locations Found";
    state.UIInteractions.posActionsDisabled = true;
    ShowView(state);
  }
};

export const CheckStripeTerminalConnection = (state, action) => {
  if (action.payload) {
    CheckStripeTerminalPass(state, action);
  } else {
    CheckStripeTerminalFail(state, action);
  }
};

export const CheckStripeTerminalPass = (state, action) => {};

export const CheckStripeTerminalFail = (state, action) => {
  state.UIInteractions.stripeErrors = "Terminal Connection Failed";
  state.UIInteractions.posActionsDisabled = true;
  state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
};

export const ShowPOSView = (state, action) => {
  state.PaymentInteractionDetails.currentPaymentMode = "in_person";
  state.PaymentInteractionDetails.currentPaymentMethod =
    state.PaymentInteractionDetails.paymentModes.in_person[0];
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowPOSView"
  )[0];
};

export const ShowOnlineView = (state, action) => {
  state.PaymentInteractionDetails.currentPaymentMode = "online";
  state.PaymentInteractionDetails.currentPaymentMethod =
    state.PaymentInteractionDetails.paymentModes.online[0];
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowOnlineView"
  )[0];
};

export const ShowBraintreeOnlineView = (state, action) => {
  state.PaymentInteractionDetails.currentPaymentMode = "online";
  state.PaymentInteractionDetails.currentPaymentMethod =
    state.PaymentInteractionDetails.paymentModes.online[0];
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowBraintreeOnlineView"
  )[0];
};

export const SetSelectedLocation = (state, action) => {
  if (action.payload !== "") {
    state.PaymentInteractionDetails.currentLocation =
      state.PaymentInteractionDetails.locations.filter(
        (loc) => loc.provider_location_id === action.payload
      )[0];
    state.UIInteractions.posActionsDisabled = true;
    state.UIInteractions.viewStructure = state.UIInteractions.viewStructure =
      ViewModes.filter((vm) => vm.ViewName === "DisconnectReader")[0];
    state.PaymentInteractionDetails.currentReader = {};
    state.PaymentInteractionDetails.readers = [];
    state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = true;
    state.UIInteractions.locationChangeHandler.startStage =
      "LoadLocationsReadersPending";
  } else {
    state.UIInteractions.posActionsDisabled = true;
    state.PaymentInteractionDetails.currentReader = {};
    state.PaymentInteractionDetails.readers = [];
    state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
  }
};

export const SetSelectedReader = (state, action) => {
  state.UIInteractions.stripeErrors = "";
  state.PaymentInteractionDetails.previousReader = HelperMethods.JPJS(
    state.PaymentInteractionDetails.currentReader
  );
  if (action.payload !== "") {
    state.PaymentInteractionDetails.currentReader =
      state.PaymentInteractionDetails.readers.filter(
        (reader) => reader.id === action.payload
      )[0];
    state.PaymentInteractionDetails.currentReader.status.toLowerCase() ===
    "offline"
      ? (state.UIInteractions.posActionsDisabled = true)
      : (state.UIInteractions.posActionsDisabled = false);
    state.PaymentInteractionDetails.processPaymentAtBackend =
      state.PaymentInteractionDetails.currentReader.process_payment_at_backend;
    if (state.PaymentInteractionDetails.processPaymentAtBackend) {
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ShowPOSView"
      )[0];
    } else {
      state.UIInteractions.stripeErrors =
        "We are connecting to your reader. Please wait...";
      // NOTE - Disconnecting from the previous connected reader
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "DisconnectReader"
      )[0];
    }
  } else {
    state.PaymentInteractionDetails.currentReader = {};
    state.UIInteractions.posActionsDisabled = true;
    if (!state.PaymentInteractionDetails.processPaymentAtBackend) {
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "DisconnectReader"
      )[0];
    }
  }
  SetLocalStorage(state);
};

export const SetPaymentView = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === action.payload
  )[0];
};

export const ReadersAvailabilityErrored = (state, action) => {
  state.UIInteractions.stripeErrors = action.payload.error.message;
  state.UIInteractions.posActionsDisabled = true;
  state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
};

export const ReadersAvailabilityComplete = (state, action) => {
  if (action.payload.error) {
    ReadersAvailabilityErrored(state, action);
  } else {
    state.UIInteractions.stripeErrors = "";
    let readers = JSON.parse(JSON.stringify(action.payload.discoveredReaders));
    readers.forEach((reader) => {
      // NOTE - The reader should be present in the createOrder response
      // If the reader is not coming from the backend - not to be handled from WFe as of now
      if (
        state.PaymentInteractionDetails.currentLocation.readers.some(
          (rdr) => rdr.reader_id === reader.id
        )
      ) {
        let newRdr =
          state.PaymentInteractionDetails.currentLocation.readers.filter(
            (rdr) => {
              return rdr.reader_id === reader.id;
            }
          )[0];
        reader.process_payment_at_backend = newRdr.process_payment_at_backend;
      }
    });
    state.PaymentInteractionDetails.readers = readers;
    if (state.PaymentInteractionDetails.readers.length > 0) {
      const posDevices = JSON.parse(localStorage.getItem("posDevices"));
      if (
        posDevices !== null &&
        state.PaymentInteractionDetails.readers.some(
          (reader) => reader.id === posDevices.reader
        )
      ) {
        state.PaymentInteractionDetails.currentReader =
          state.PaymentInteractionDetails.readers.filter(
            (reader) => reader.id === posDevices.reader
          )[0];
      } else {
        state.PaymentInteractionDetails.currentReader =
          state.PaymentInteractionDetails.readers[0];
        SetLocalStorage(state);
      }
      state.PaymentInteractionDetails.processPaymentAtBackend =
        state.PaymentInteractionDetails.currentReader.process_payment_at_backend;
      state.PaymentInteractionDetails.previousReader = HelperMethods.JPJS(
        state.PaymentInteractionDetails.currentReader
      );
      if (
        state.PaymentInteractionDetails.currentReader.status === "online" &&
        !state.PaymentInteractionDetails.processPaymentAtBackend
      ) {
        state.UIInteractions.stripeErrors =
          "We are connecting to your reader. Please wait...";
        state.UIInteractions.viewStructure = ViewModes.filter(
          (vm) => vm.ViewName === "ConnectReaderDisplay"
        )[0];
      } else {
        // We are doing this as when processPaymentAtBAckend is true, ConnectReader Flow is not called
        // And the ConnectReader flow upon success was updating the posActionsDisabled to false
        state.UIInteractions.posActionsDisabled =
          state.PaymentInteractionDetails.currentReader.status !== "online";
      }
    } else {
      state.UIInteractions.posErrors = "No Readers Found";
      state.UIInteractions.posActionsDisabled = true;
    }
    state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
  }
};

export const SetLocalStorage = (state) => {
  let posDevices = {
    location:
      state.PaymentInteractionDetails.currentLocation.provider_location_id,
    reader: state.PaymentInteractionDetails.currentReader.id,
  };
  localStorage.setItem("posDevices", JSON.stringify(posDevices));
};

export const ReconnectReader = (state, action) => {
  if (!state.PaymentInteractionDetails.isReaderConnected) {
    state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = true;
    state.UIInteractions.locationChangeHandler.startStage =
      "CheckReaderStatusAndUpdate";
    state.UIInteractions.stripeErrors =
      "We are fetching the latest status of the reader";
  }
};

export const SetReaderDisplay = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetReaderDisplay"
  )[0];
};

export const ClearReaderDisplay = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ClearReaderDisplay"
  )[0];
};

export const ReconnectReaderErrored = (state, action) => {
  state.UIInteractions.stripeErrors = action.payload.error.message;
  state.PaymentInteractionDetails.currentReader.status = "offline";
  state.PaymentInteractionDetails.isReaderConnected = false;
  state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
  state.UIInteractions.posActionsDisabled = true;
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetReconnectReaderErrored"
  )[0];
};

export const ReconnectReaderComplete = (state, action) => {
  state.PaymentInteractionDetails.currentReader.status = "online";
  state.PaymentInteractionDetails.isReaderConnected = true;
  state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
  state.UIInteractions.posActionsDisabled = false;
  state.UIInteractions.stripeErrors = "";
};

export const ToggleModal = (state, action) => {
  state.UIInteractions.showPosModal = action.payload;
};

export const SetProcessPosPayment = (state, action) => {
  state.PaymentInteractionDetails.disableRecheckButton = true;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PosProcessPaymentApiPending"
  )[0];
};

export const ConnectReaderDisplay = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ConnectReaderDisplay"
  )[0];
};

export const ClearReaderDisplayComplete = (state, action) => {
  state.UIInteractions.stripeErrors = "";
  state.UIInteractions.posActionsDisabled = false;
};

export const SetDisconnectReaderErrored = (state, action) => {
  let currentReader = JSON.parse(
    JSON.stringify(state.PaymentInteractionDetails.previousReader)
  );
  state.UIInteractions.stripeErrors =
    action.payload?.error?.message ||
    "Could not disconnect previous reader. Please try again.";
  state.PaymentInteractionDetails.currentReader = currentReader;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowPOSView"
  )[0];
};

export const SetDisconnectReaderComplete = (state, action) => {
  state.UIInteractions.stripeErrors = "";
  if (state.PaymentInteractionDetails.currentReader.hasOwnProperty("id")) {
    state.UIInteractions.posActionsDisabled = false;
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ConnectReaderDisplay"
    )[0];
  } else {
    state.UIInteractions.posActionsDisabled = true;
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowPOSView"
    )[0];
  }
};

export const ClearReaderDisplayErrored = (state, action) => {
  state.UIInteractions.stripeErrors =
    action.payload?.error?.message ||
    "No active connection found to the reader. Please refresh the status.";
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowPOSView"
  )[0];
};

export const SetReaderDisplayComplete = (state, action) => {
  state.UIInteractions.stripeErrors = "";
  state.UIInteractions.posActionsDisabled = false;
};

export const SetReaderDisplayErrored = (state, action) => {
  state.UIInteractions.stripeErrors =
    action.payload?.error?.message ||
    "Could not display the preview on the reader";
  state.UIInteractions.isPaymentStarted = false;
  state.UIInteractions.posActionsDisabled = true;
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowPOSView"
  )[0];
};

export const SetStripeLoadedErrored = (state, action) => {
  state.UIInteractions.onlineErrors = action.payload.SetStripeLoadedErrored;
  state.UIInteractions.isPaymentStarted = false;
  state.PaymentInteractionDetails.onlineActionsDisabled = true;
};

export const SetCardSubmitError = (state, action) => {
  state.UIInteractions.onlineErrors = action.payload.SetCardSubmitError;
  state.UIInteractions.isPaymentStarted = false;
  state.PaymentInteractionDetails.onlineActionsDisabled = true;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCardValidationErrored"
  )[0];
};

export const SetCardValidation = (state, action) => {
  state.UIInteractions.isPaymentStarted = true;
  state.PaymentInteractionDetails.disableRecheckButton = true;
  state.PaymentInteractionDetails.cardProcessingPayload = action.payload;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCardValidation"
  )[0];
};

export const SetOnlineError = (state, action) => {
  state.UIInteractions.onlineErrors = action.payload.error;
  state.UIInteractions.onlineActionsDisabled = action.payload.action;
};

export const SetCardProcessing = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCardProcessOrderPending"
  )[0];
};

export const SetCardProcessOrderErrored = (state, action) => {
  state.UIInteractions.onlineErrors =
    action.payload?.err?.response?.data?.detail ||
    "Could not contact Stripe to collect the payment";
  state.UIInteractions.isPaymentStarted = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCardProcessOrderErrored"
  )[0];
};

export const SetCardProcessOrderComplete = (state, action) => {
  state.PaymentIntegrations.cardClientSecret = action.payload.client_secret;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCardProcessOrderComplete"
  )[0];
};

export const PosProcessPaymentApiErrored = (state, action) => {
  state.UIInteractions.stripeErrors =
    action.payload?.err?.response?.data?.detail ||
    "Could not contact Stripe to collect the payment";
  state.UIInteractions.isPaymentStarted = false;
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PosProcessPaymentApiErrored"
  )[0];
};

export const PosProcessPaymentApiComplete = (state, action) => {
  state.PaymentIntegrations.posClientSecret = action.payload.client_secret;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PosProcessPaymentApiComplete"
  )[0];
};

export const ConfirmCardPaymentErrored = (state, action) => {
  if (action.payload.error) {
    if (action.payload.error.type === "invalid_request_error") {
      if (
        action.payload.error.code === "payment_intent_unexpected_state" &&
        action.payload.error.payment_intent.status === "canceled"
      ) {
        state.UIInteractions.onlineErrors =
          "This payment was cancelled previously.";
        state.UIInteractions.viewStructure = ViewModes.filter(
          (vm) => vm.ViewName === "ConfirmCardPaymentErrored"
        )[0];
      } else if (
        action.payload.error.code === "payment_intent_unexpected_state" &&
        action.payload.error.payment_intent.status === PaymentStatus.succeeded
      ) {
        state.UIInteractions.onlineErrors =
          "This payment has already succeeded after being previously confirmed.";
        state.UIInteractions.viewStructure = ViewModes.filter(
          (vm) => vm.ViewName === "ConfirmCardPaymentErrored"
        )[0];
      } else {
        state.UIInteractions.onlineErrors = action.payload.error.message;
        state.UIInteractions.isPaymentStarted = false;
        state.UIInteractions.viewStructure = ViewModes.filter(
          (vm) => vm.ViewName === "ConfirmCardPaymentErrored"
        )[0];
      }
    } else {
      state.UIInteractions.onlineErrors = action.payload.error.message;
      state.UIInteractions.isPaymentStarted = false;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ShowOnlineView"
      )[0];
    }
  } else {
    state.UIInteractions.onlineErrors =
      "Cannot connect to Stripe currently. Please try again later.";
    state.UIInteractions.isPaymentStarted = false;
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ConfirmCardPaymentErrored"
    )[0];
  }
};

export const ConfirmCardPaymentSuccess = (state, action) => {
  state.UIInteractions.isPaymentStarted = true;
  state.PaymentIntegrations.paymentStatus = action.payload.paymentIntent.status;
  state.PaymentIntegrations.paymentDate = String(
    moment
      .unix(action.payload.paymentIntent.created)
      .format("MMMM Do YYYY, h:mm:ss a")
  );
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CheckStatusPending"
  )[0];
};

export const CollectPaymentSuccess = (state, action) => {
  state.PaymentIntegrations.paymentIntent = action.payload.paymentIntent;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PosPaymentPending"
  )[0];
};

export const CollectPaymentErrored = (state, action) => {
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.stripeErrors = action.payload.error.message;
  state.UIInteractions.isPaymentStarted = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetCollectPaymentErrored"
  )[0];
};

export const ProcessPosPaymentSuccess = (state, action) => {
  state.PaymentIntegrations.paymentStatus = action.payload.paymentIntent.status;
  state.PaymentIntegrations.paymentDate = String(
    moment
      .unix(action.payload.paymentIntent.created)
      .format("MMMM Do YYYY, h:mm:ss a")
  );
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CheckStatusPending"
  )[0];
};

export const ProcessPosPaymentErrored = (state, action) => {
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.stripeErrors = action.payload.error.message;
  state.UIInteractions.isPaymentStarted = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetProcessPosPaymentErrored"
  )[0];
};

export const CheckStatusSuccess = (state, action) => {
  state.PaymentInteractionDetails.showPaymentStatus = true;
  state.PaymentInteractionDetails.currentPaymentMode =
    action.payload.payload.payment_mode;
  state.PaymentInteractionDetails.currentPaymentMethod =
    action.payload.payload.payment_method;

  switch (action.payload.payload.status.toLowerCase()) {
    case PaymentStatus.succeeded:
      // Works for all payment modes,methods and gateway, not specific for card
      SetCardPaymentSuccess(state, action);
      break;
    case PaymentStatus.failed:
      // Works for all payment modes,methods and gateway, not specific for card
      SetCardPaymentFailed(state, action);
      break;
    case PaymentStatus.in_progress:
      SetPaymentStatusPending(state, action);
      break;
    case PaymentStatus.processing:
      SetPaymentBackendProcessing(state, action);
      break;
    case PaymentStatus.cancelled:
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelSuccess"
      )[0];
      state.PaymentIntegrations.statusPayload = action.payload.token;
      state.PaymentIntegrations.paymentStatus =
        action.payload.payload.status.toLowerCase();
      break;
    default:
      SetPaymentStatusPending(state, action);
      break;
  }
  // if (action.payload.payload.status.toLowerCase() === PaymentStatus.succeeded) {
  //   SetCardPaymentSuccess(state, action);
  // } else if (action.payload.payload.status.toLowerCase() === PaymentStatus.failed) {
  //   SetCardPaymentFailed(state, action);
  // } else {
  //   SetPaymentStatusPending(state, action);
  // }
};

export const SetPaymentBackendProcessing = (state, action) => {
  state.PaymentIntegrations.statusPayload = action.payload.token;
  state.PaymentIntegrations.paymentStatus =
    action.payload.payload.status.toLowerCase();
  state.PaymentInteractionDetails.disableRecheckButton = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "BackendPaymentProcessing"
  )[0];
};

export const SetPaymentStatusPending = (state, action) => {
  state.PaymentIntegrations.statusPayload = action.payload.token;
  state.PaymentIntegrations.paymentStatus =
    action.payload.payload.status.toLowerCase();
  state.PaymentInteractionDetails.disableRecheckButton = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PaymentPending"
  )[0];
};

export const CheckStatusErrored = (state, action) => {
  state.UIInteractions.applicationErrors =
    action.payload?.err?.response?.data?.detail || "";
  state.PaymentInteractionDetails.disableRecheckButton = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CheckStatusErrored"
  )[0];
};

export const SetCardPaymentFailed = (state, action) => {
  state.PaymentIntegrations.statusPayload = action.payload.token;
  state.UIInteractions.isPaymentStarted = false;
  state.PaymentInteractionDetails.showPaymentStatus = false;
  if (state.PaymentInteractionDetails.currentPaymentMethod === "card") {
    state.UIInteractions.onlineErrors =
      "Your payment attempt was unsuccessful. Please try again.";
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowOnlineView"
    )[0];
  }
  if (state.PaymentInteractionDetails.currentPaymentMethod === "card_present") {
    state.UIInteractions.stripeErrors =
      "Your payment attempt was unsuccessful. Please try again.";
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowPOSView"
    )[0];
  }
  if (state.UIInteractions.isBraintree) {
    state.UIInteractions.onlineErrors =
      "Your payment attempt was unsuccessful. Please try again.";
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowBraintreeOnlineView"
    )[0];
  }
  // state.PaymentIntegrations.paymentStatus =
  //   action.payload.payload.status.toLowerCase();
  // state.UIInteractions.viewStructure = ViewModes.filter(
  //   (vm) => vm.ViewName === "PaymentFailure"
  // )[0];
};

export const SetCardPaymentSuccess = (state, action) => {
  state.PaymentIntegrations.statusPayload = action.payload.token;
  state.PaymentIntegrations.receiptUrl =
    action.payload.payload.additional_info?.receipt_url;
  state.PaymentIntegrations.paymentStatus =
    action.payload.payload.status.toLowerCase();

  if (
    state.PaymentInteractionDetails.paymentMethodGateways.online.card &&
    state.PaymentInteractionDetails.paymentMethodGateways.online.card.toLowerCase() ===
      "braintree" &&
    state.PaymentInteractionDetails.currentPaymentMode === "online" &&
    state.PaymentInteractionDetails.currentPaymentMethod === "card"
  ) {
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "RedirectBack"
    )[0];
  } else {
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "PaymentSuccess"
    )[0];
  }
};

export const SetRedirectBack = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "RedirectBack"
  )[0];
};

export const ShowView = (state) => {
  if (state.UIInteractions.isCardpossible) {
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowOnlineView"
    )[0];
  } else {
    state.UIInteractions.viewStructure = ViewModes.filter(
      (vm) => vm.ViewName === "ShowPOSView"
    )[0];
  }
};

export const SetPaymentCancel = (state, action) => {
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "SetPaymentCancel"
  )[0];
};

export const CancelPaymentCardSuccess = (state, action) => {
  switch (action.payload.data.payload.status.toLowerCase()) {
    case PaymentStatus.cancelled:
      state.PaymentInteractionDetails.showPaymentStatus = true;
      state.PaymentIntegrations.statusPayload = action.payload.data.token;
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.PaymentIntegrations.receiptUrl =
        action.payload.data.payload.additional_info?.receipt_url;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelSuccess"
      )[0];
      break;
    case PaymentStatus.succeeded:
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.UIInteractions.applicationErrors =
        action.payload.error ||
        "Could not cancel the payment as payment is already succeeded";
      state.PaymentIntegrations.statusPayload = action.payload.data.token;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelErrored"
      )[0];
      break;
    default:
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.UIInteractions.onlineErrors =
        action.payload.error ||
        "Could not cancel the payment. Please try again later.";
      state.PaymentIntegrations.statusPayload = action.payload.data.token;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelCardErrored"
      )[0];
      break;
  }
};

export const CancelPaymentPosSuccess = (state, action) => {
  switch (action.payload.data.payload.status.toLowerCase()) {
    case PaymentStatus.cancelled:
      state.PaymentInteractionDetails.showPaymentStatus = true;
      state.PaymentIntegrations.statusPayload = action.payload.data.token;
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.PaymentIntegrations.receiptUrl =
        action.payload.data.payload.additional_info?.receipt_url;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelSuccess"
      )[0];
      break;
    case PaymentStatus.succeeded:
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.UIInteractions.applicationErrors =
        action.payload.error ||
        "Could not cancel the payment as payment is already succeeded";
      state.PaymentIntegrations.statusPayload = action.payload.data.token;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelErrored"
      )[0];
      break;
    case PaymentStatus.initialized:
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.UIInteractions.stripeErrors =
        action.payload.error ||
        "Could not cancel the payment. Please try again later.";
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelPosErrored"
      )[0];
      break;
    case PaymentStatus.in_progress:
      state.PaymentIntegrations.paymentStatus =
        action.payload.data.payload.status.toLowerCase();
      state.UIInteractions.onlineErrors =
        action.payload.error ||
        "Could not cancel the payment. Please try again later.";
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PaymentCancelPosErrored"
      )[0];
      break;
    default:
      break;
  }
};

export const CancelPaymentCardErrored = (state, action) => {
  state.UIInteractions.onlineErrors =
    action.payload?.err?.response?.data?.detail ||
    "Cannot cancel the payment. Try again later.";
  state.PaymentInteractionDetails.showPaymentStatus = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PaymentCancelCardErrored"
  )[0];
};

export const CancelPaymentPosErrored = (state, action) => {
  state.UIInteractions.stripeErrors =
    action.payload?.err?.response?.data?.detail ||
    "Cannot cancel the payment. Try again later.";
  state.PaymentInteractionDetails.showPaymentStatus = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PaymentCancelPosErrored"
  )[0];
};

export const SetPaymentStarted = (state, action) => {
  state.UIInteractions.isPaymentStarted = action.payload;
};

export const SetCheckstatusPending = (state, action) => {
  state.PaymentInteractionDetails.disableRecheckButton = true;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ReCheckStatusPending"
  )[0];
};

export const GetStatusPayloadSuccess = (state, action) => {
  state.PaymentIntegrations.statusPayload = action.payload.token;
  state.PaymentIntegrations.paymentStatus =
    action.payload.payload.status.toLowerCase();
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "RedirectBack"
  )[0];
};

export const GetStatusPayloadErrored = (state, action) => {
  state.UIInteractions.applicationErrors =
    action.payload?.err?.response?.data?.detail ||
    "Could not redirect back. Please try again.";
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "GetStatusPayloadErrored"
  )[0];
};

export const SetGetStatusPayload = (state, action) => {
  state.UIInteractions.applicationErrors = "";
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "GetStatusPayload"
  )[0];
};

export const CheckConnectionStatus = (state, action) => {
  state.UIInteractions.stripeErrors =
    "We are checking connection with your reader";
  state.UIInteractions.posActionsDisabled = true;
  state.UIInteractions.locationChangeHandler.isLocationChangeNeeded = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CheckReaderConnectionStatus"
  )[0];
};

export const CheckReaderConnectionSuccess = (state, action) => {
  state.UIInteractions.posActionsDisabled = true;
  switch (action.payload) {
    case "connected":
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "DisconnectReader"
      )[0];
      break;
    case "connecting":
      state.UIInteractions.stripeErrors = "We are connecting to your reader...";
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ShowPOSView"
      )[0];
      break;
    case "not_connected":
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ConnectReaderDisplay"
      )[0];
      break;
    default:
      break;
  }
};

export const CheckReaderConnectionErrored = (state, action) => {
  state.PaymentInteractionDetails.isReaderConnected = false;
  state.UIInteractions.stripeErrors =
    action.payload.error?.message ||
    "We couldn't refresh the status of the connection to your reader.";
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "ShowPOSView"
  )[0];
};

export const ConfirmPosPaymentBackendErrored = (state, action) => {
  state.UIInteractions.showPosModal = false;
  state.UIInteractions.stripeErrors =
    action.payload.err?.response?.data?.detail ||
    "Could not confirm the payment. Please try again.";
  state.UIInteractions.isPaymentStarted = false;
  state.PaymentInteractionDetails.disableRecheckButton = false;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "PosBackendPaymentErrored"
  )[0];
};

export const ConfirmPosPaymentBackendSuccess = (state, action) => {
  state.PaymentIntegrations.paymentStatus =
    action.payload.payload.status.toLowerCase();
  state.PaymentInteractionDetails.disableRecheckButton = true;
  state.UIInteractions.viewStructure = ViewModes.filter(
    (vm) => vm.ViewName === "CheckStatusPending"
  )[0];
};
