import React from "react";
import PaymentViewContainer from "./PaymentModule/components/PaymentViewContainer";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "./AdminModule/adminComponents/AdminLogin";
import ForgetPassword from "./AdminModule/adminComponents/ForgetPassword";
import { ProtectedRoute } from "./AdminModule/utils/ProtectedRoute";
import StatusPayloadPage from "./PaymentModule/PaymentStatusPayload/StatusPayloadPage";
import NavbarAdmin from "./AdminModule/adminComponents/NavbarAdmin";

export default function RouteList({ TestCallBacks }) {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<PaymentViewContainer TestCallBacks={TestCallBacks} />}
        />
        <Route
          path="/payment"
          element={<PaymentViewContainer TestCallBacks={TestCallBacks} />}
        />
        <Route path="/payment/status" element={<StatusPayloadPage />} />
        <Route path="/admin/login" element={<AdminLogin />}></Route>
        <Route
          path="/admin/forgot_password"
          element={
            <ProtectedRoute>
              <ForgetPassword />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute>
              <NavbarAdmin />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="*" element={null}></Route>
      </Routes>
    </div>
  );
}
