import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Button, Tooltip, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function TenantTable() {
  const {
    selectedIntegration,
    selectedIntegration_id,
    searchTenantVariable,
    addNewTenant,
    selectedTenant,
    setNewSelectedTenant,
    getAllTenants,
    deleteTenant,
    filterTenants,
    pagination,
    tenantsDisplayList,
    setPage,
  } = useIntegrationHook();

  useEffect(() => {
    if (
      selectedIntegration.integration_code &&
      selectedIntegration.record_status === "U"
    ) {
      getAllTenants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration.integration_code, selectedIntegration_id]);

  // const [page, setPage] = React.useState(1);
  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  return (
    <div className="tenant-table">
      <div className="Tenant-header">
        <Typography className="Tenant-header-text">Tenants</Typography>
        <div className="TenantTableActions">
          <Tooltip title="Add New Tenant" arrow placement="right-start">
            <IconButton
              className="add-tenant-button"
              data-testid="add-tenant-test-button"
              onClick={() => {
                addNewTenant();
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Paper className="paper-searchbox">
            <IconButton className="search-button" aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              className="search-input"
              placeholder="Search Tenant"
              inputProps={{ "aria-label": "search tenant" }}
              value={searchTenantVariable}
              onChange={(e) => {
                if (e.target.value === "") {
                  getAllTenants();
                } else {
                  filterTenants(e.target.value);
                }
              }}
            />
          </Paper>
        </div>
      </div>
      <div className="add-tenant-pagination">
        <Pagination
          size="small"
          count={Math.ceil(
            selectedIntegration.tenants.length / pagination.tenantsPerPage
          )}
          page={pagination.tenantsPage}
          onChange={(e, value) => setPage(value)}
          color="primary"
          className="custom-pagination"
        />
      </div>
      <div className="error-tenant-table">
        {tenantsDisplayList === undefined || tenantsDisplayList.length === 0 ? (
          <p className="no-payment-message">No Tenants Found!</p>
        ) : null}
      </div>
      <div className="Tenant-Card-Table">
        {tenantsDisplayList.map((tenant, index) => (
          <Button
            className="override-button-o"
            key={index}
            data-testid="tenants-list-integrations"
            onClick={(e) => {
              setNewSelectedTenant(tenant.Tnt_id);
            }}
          >
            <div
              className={`${
                tenant.changed_ind ? "errorbordernone" : "errorborder"
              } Tenant-One-Info ${
                tenant.Tnt_id === selectedTenant?.Tnt_id
                  ? "selectedTenant"
                  : "unselectedTenant"
              }`}
            >
              <div className="Tenant-name-div">
                <Typography className="Tenant-name">
                  {tenant.display_name}
                </Typography>
              </div>
              <div className="Tenant-code-div">
                <Typography className="Tenant-code">
                  {tenant.tenant_code}
                </Typography>
              </div>

              <div className="Tenant-action-buttons">
                {tenant.changed_ind === true ? (
                  <Tooltip title="Unsaved changes" arrow placement="top">
                    <ErrorOutlineIcon className="unsaved-icon" />
                  </Tooltip>
                ) : null}
                {/* <IconButton>
                  <img
                    alt="Edit Button"
                    src="/Images/icon _edit_.svg"
                    width="15px"
                    height="15px"
                  />
                </IconButton> */}
                <IconButton
                  onClick={(e) => {
                    deleteTenant({
                      intcode: selectedIntegration.integration_code,
                      tenant: tenant,
                    });
                  }}
                >
                  <img
                    alt="Delete Button"
                    src="/Images/icon _trash_.svg"
                    width="15px"
                    height="15px"
                  />
                </IconButton>
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
}
