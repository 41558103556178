import React, { useState, useEffect } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import EditableConfig from "./CustomControls/EditableConfig";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import AuthForm from "./AuthForm";
import OrderForm from "./OrderForm";
import ApiForm from "./ApiForm";
import copysvg from "../../Assets/Copy_svg.svg";
import copysuccess from "../../Assets/copied_green.svg";

export default function TenantKeys() {
  const {
    selectedTenant,
    isEditableTenant,
    tenantUpdate,
    addAuthKeyInUI,
    getTenantMetaData,
    getAuthMetaData,
    getApiMetaData,
    addOrderPayloadInUI,
    addApiCallbackInUI,
    getOrderMetaData,
    deleteauthkey,
    deleteorderkey,
    getSubscriptionStatus,
  } = useIntegrationHook();
  let EditableConfig_1 = JPJS(getTenantMetaData);
  let EditableConfig_2 = JPJS(getTenantMetaData);
  let EditableConfig_3 = JPJS(getAuthMetaData);
  let EditableConfig_4 = JPJS(getOrderMetaData);
  let EditableConfig_5 = JPJS(getApiMetaData);

  let EditableConfig_6 = JPJS(getTenantMetaData);
  let EditableConfig_7 = JPJS(getTenantMetaData);
  let EditableConfig_8 = JPJS(getTenantMetaData);

  EditableConfig_1.updating_key = "stripepublickey";
  EditableConfig_2.updating_key = "stripeprivatekey";

  EditableConfig_6.updating_key = "braintreepublickey";
  EditableConfig_7.updating_key = "braintreeprivatekey";
  EditableConfig_8.updating_key = "braintreemerchantid";

  EditableConfig_3.updating_key = "authkey";
  EditableConfig_4.updating_key = "orderpayloadkey";
  EditableConfig_5.updating_key = "apicallbacks";

  const [copyState, setcopyState] = useState({ index: null, type: null });
  useEffect(() => {
    if (copyState.index !== null) {
      const timer = setTimeout(() => {
        setcopyState({ index: null, type: null });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copyState]);

  return (
    <div className="Tenant-keys">
      <div className="callback-box">
        <div className="api-header-admin">
          <label className="header-text-apiadmin">API Callback URLs</label>

          {isEditableTenant ? (
            <Tooltip
              title="Add New API Callback URL"
              arrow
              placement="right-start"
            >
              <IconButton
                className="add-apicallbackurl-button"
                data-testid="add-apicallbackurl-button"
                onClick={(e) => {
                  addApiCallbackInUI();
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          {selectedTenant?.api_callback_subscriptions.length > 0 ? (
            <Tooltip
              title="Refresh URL Subscription Status"
              arrow
              placement="right-start"
            >
              <IconButton
                variant="outlined"
                className="api-refresh-button"
                onClick={(e) => {
                  getSubscriptionStatus(selectedTenant);
                }}
              >
                <RefreshIcon className="api-refresh-icon" />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>

        {selectedTenant?.api_callback_subscriptions.map(
          (subscription, index) => (
            <ApiForm
              subscription={subscription}
              index={index}
              key={subscription.Api_id}
            />
          )
        )}
        {selectedTenant?.status_error ? (
          <div className="error-message">{selectedTenant?.status_error}</div>
        ) : null}
      </div>
      <div className="admin-key-box">
        <div className="admin-key-header">Payment Gateway Keys</div>
        <div className="admin-key-entry">
          <div className="admin-key-label">Public Stripe Key</div>
          <div className="admin-key-value">
            <EditableConfig
              type={selectedTenant}
              value={
                selectedTenant.payment_gateway_key_paths?.stripe?.public_key ||
                ""
              }
              classNameprop="admin-key-typo"
              dataTestIdProp="tenant-public-stripe-key-input"
              updateValue={tenantUpdate}
              controlMetaData={EditableConfig_1}
              newcssforinput="inputbggrey"
            />
            {selectedTenant.public_stripe_error !== null ? (
              <div className="error-message">
                {selectedTenant.public_stripe_error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="admin-key-entry">
          <div className="admin-key-label">Private Stripe Key</div>
          <div className="admin-key-value">
            <EditableConfig
              type={selectedTenant}
              value={
                selectedTenant.payment_gateway_key_paths?.stripe?.private_key
              }
              classNameprop="admin-key-typo"
              dataTestIdProp="tenant-private-stripe-key-input"
              updateValue={tenantUpdate}
              controlMetaData={EditableConfig_2}
              newcssforinput="inputbggrey"
            />
            {selectedTenant.private_stripe_error !== null ? (
              <div className="error-message">
                {selectedTenant.private_stripe_error}
              </div>
            ) : null}
          </div>
        </div>
        {selectedTenant.stripe_error !== null ? (
          <div className="error-message">{selectedTenant.stripe_error}</div>
        ) : null}
        <div className="admin-key-entry">
          <div className="admin-key-label">Private Braintree Key</div>
          <div className="admin-key-value">
            <EditableConfig
              type={selectedTenant}
              value={
                selectedTenant.payment_gateway_key_paths?.braintree?.private_key
              }
              classNameprop="admin-key-typo"
              dataTestIdProp="tenant-private-stripe-key-input"
              updateValue={tenantUpdate}
              controlMetaData={EditableConfig_7}
              newcssforinput="inputbggrey"
            />
            {selectedTenant.private_braintree_error !== null ? (
              <div className="error-message">
                {selectedTenant.private_braintree_error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="admin-key-entry">
          <div className="admin-key-label">Public Braintree Key</div>
          <div className="admin-key-value">
            <EditableConfig
              type={selectedTenant}
              value={
                selectedTenant.payment_gateway_key_paths?.braintree?.public_key
              }
              classNameprop="admin-key-typo"
              dataTestIdProp="tenant-private-stripe-key-input"
              updateValue={tenantUpdate}
              controlMetaData={EditableConfig_6}
              newcssforinput="inputbggrey"
            />
            {selectedTenant.public_braintree_error !== null ? (
              <div className="error-message">
                {selectedTenant.public_braintree_error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="admin-key-entry">
          <div className="admin-key-label">Merchant ID</div>
          <div className="admin-key-value">
            <EditableConfig
              type={selectedTenant}
              value={
                selectedTenant.payment_gateway_key_paths?.braintree?.merchant_id
              }
              classNameprop="admin-key-typo"
              dataTestIdProp="tenant-private-stripe-key-input"
              updateValue={tenantUpdate}
              controlMetaData={EditableConfig_8}
              newcssforinput="inputbggrey"
            />
            {selectedTenant.merchant_id_error !== null ? (
              <div className="error-message">
                {selectedTenant.merchant_id_error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="tenant-key-line" />
      </div>
      <div className="admin-key-box">
        <div className="admin-key-header">
          Authorization Keys
          {isEditableTenant ? (
            <Tooltip title="Add Auth Key" arrow placement="right-start">
              <IconButton
                className="add-verification-auth-button"
                data-testid="add-verification-auth-button"
                onClick={(e) => {
                  addAuthKeyInUI();
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
        <div className="admin-key-entry-new">
          <div className="admin-key-label">Authorization Key</div>
          <div className="admin-key-value">
            {isEditableTenant ? (
              <>
                {selectedTenant.auth_keys.map((authkey, index) => (
                  <div className="key-form-container">
                    <div className="editableconfig">
                      <div className="disabled-field">{authkey.thumbprint}</div>
                    </div>

                    <div className="admin-key-newaction">
                      <IconButton
                        onClick={() => {
                          deleteauthkey(authkey.Auth_id);
                        }}
                      >
                        <img
                          alt="Delete Button"
                          src="/Images/icon _trash_.svg"
                          width="15px"
                          height="15px"
                        />
                      </IconButton>
                      <CopyToClipboard
                        text={
                          authkey.key_value
                            ? authkey.key_value
                            : authkey.thumbprint
                        }
                        onCopy={() =>
                          setcopyState({ index: index, type: "auth" })
                        }
                      >
                        <IconButton>
                          <img
                            src={
                              copyState.index === index &&
                              copyState.type === "auth"
                                ? copysuccess
                                : copysvg
                            }
                            width="15px"
                            height="15px"
                          />
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {selectedTenant.auth_keys.map((authkey, index) => (
                  <div className="display-keys-main">
                    <div className="display-key-value">
                      {authkey.thumbprint}
                    </div>
                    <CopyToClipboard
                      text={
                        authkey.key_value
                          ? authkey.key_value
                          : authkey.thumbprint
                      }
                      onCopy={() =>
                        setcopyState({ index: index, type: "auth" })
                      }
                    >
                      <IconButton sx={{ padding: 0 }}>
                        <img
                          src={
                            copyState.index === index &&
                            copyState.type === "auth"
                              ? copysuccess
                              : copysvg
                          }
                          width="15px"
                          height="15px"
                        />
                      </IconButton>
                    </CopyToClipboard>
                  </div>
                ))}
              </>
            )}
            {selectedTenant.new_auth_keys?.map((authkey, index) => (
              <AuthForm authkey={authkey} index={index} key={authkey.Auth_id} />
            ))}

            {selectedTenant.auth_error !== null ? (
              <div className="error-message">{selectedTenant.auth_error}</div>
            ) : null}
          </div>
        </div>
        <div className="tenant-key-line" />
      </div>
      <div className="admin-key-box">
        <div className="admin-key-header">
          Integrator's Verification Keys
          {isEditableTenant ? (
            <Tooltip title="Add Verification Key" arrow placement="right-start">
              <IconButton
                className="add-verification-op-button"
                data-testid="add-verification-op-button"
                onClick={(e) => {
                  addOrderPayloadInUI();
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
        <div className="admin-key-entry-new">
          <div className="admin-key-label">Verification Key</div>
          <div className="admin-key-value">
            <div className="multiple-handle">
              {isEditableTenant ? (
                <>
                  {selectedTenant.order_payload_keys.map((orderkey, index) => (
                    <div className="key-form-container">
                      <div className="editableconfig">
                        <div className="disabled-field">
                          {orderkey.thumbprint}
                        </div>
                      </div>

                      <div className="admin-key-newaction">
                        <IconButton
                          onClick={() => {
                            deleteorderkey(orderkey.Order_id);
                          }}
                        >
                          <img
                            alt="Delete Button"
                            src="/Images/icon _trash_.svg"
                            width="15px"
                            height="15px"
                          />
                        </IconButton>
                        <CopyToClipboard
                          text={
                            orderkey.key_value
                              ? orderkey.key_value
                              : orderkey.thumbprint
                          }
                          onCopy={() =>
                            setcopyState({ index: index, type: "order" })
                          }
                        >
                          <IconButton>
                            <img
                              src={
                                copyState.index === index &&
                                copyState.type === "order"
                                  ? copysuccess
                                  : copysvg
                              }
                              width="15px"
                              height="15px"
                            />
                          </IconButton>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {selectedTenant.order_payload_keys.map((orderkey, index) => (
                    <div className="display-keys-main">
                      <div className="display-key-value">
                        {orderkey.thumbprint}
                      </div>
                      <CopyToClipboard
                        text={
                          orderkey.key_value
                            ? orderkey.key_value
                            : orderkey.thumbprint
                        }
                        onCopy={() =>
                          setcopyState({ index: index, type: "order" })
                        }
                      >
                        <IconButton sx={{ padding: 0 }}>
                          <img
                            src={
                              copyState.index === index &&
                              copyState.type === "order"
                                ? copysuccess
                                : copysvg
                            }
                            width="15px"
                            height="15px"
                          />
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  ))}
                </>
              )}
              {selectedTenant.new_order_payload_keys.map((orderkey, index) => (
                <OrderForm orderkey={orderkey} key={index} />
              ))}
              {selectedTenant.order_error !== null ? (
                <div className="error-message">
                  {selectedTenant.order_error}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="tenant-key-line" />
      </div>
      {isEditableTenant ? null : (
        <div className="admin-key-box">
          <div className="admin-key-header">
            Quicket Pay's Verification Keys
          </div>
          <div className="admin-key-entry">
            <div className="admin-key-label">Verification Key</div>
            <div className="admin-key-value">
              <div className="key-form-container">
                <div className="editableconfig">
                  <EditableConfig
                    thisObjectName="public_status_payload_keys"
                    type={selectedTenant}
                    value={selectedTenant.public_status_payload_keys}
                    classNameprop="admin-key-value"
                    dataTestIdProp="tenant-public-status-payload-key-input"
                    newcssforinput="inputbggrey"
                    disable_edit_list={selectedTenant.disable_edit_list}
                  />
                </div>

                {/* <div className="admin-key-newaction">
                      <CopyToClipboard text={value} onCopy={() => setcopyState(true)}>
                        <IconButton>
                          <img
                            src={copyState ? copysuccess : copysvg}
                            width="15px"
                            height="15px"
                          />
                        </IconButton>
                      </CopyToClipboard>
                    </div> */}
              </div>
            </div>
          </div>
          <div className="tenant-key-line" />
        </div>
      )}
    </div>
  );
}
