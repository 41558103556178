import React, { useEffect, useState } from "react";
import { IconButton, Button, Paper, InputBase, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IntegrationOnboarding from "./IntegrationOnboarding";
import TenantOnboarding from "./TenantOnboarding";
import ErrorIcon from "@mui/icons-material/Error";
import AlertModal from "./AlertModal";
import { useDispatch } from "react-redux";
import { logout } from "../external/Api";

const NavbarAdmin = () => {
  const dispatch = useDispatch();
  const {
    sidebarOpen,
    listOfIntegrations,
    searchIntegrationVariable,
    selectedIntegration,
    addNewIntegration,
    setNewSelectedIntegration,
    getAllIntegrationAtLoad,
    deleteIntegration,
    filterIntegrations,
  } = useIntegrationHook();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(sidebarOpen);
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  useEffect(() => {
    getAllIntegrationAtLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarClass = sidebarCollapsed ? "collapsed-admin" : "";
  // const layoutClass = sidebarCollapsed
  //   ? "collapsed-layout"
  //   : "sidebar-rest-admin";

  return (
    <div className="admin-onboarding-container">
      <AlertModal />
      <div className="admin-top-nav">
        <img
          src="/Images/quicketsolutionslogo.svg"
          alt="Quicket Logo"
          width={130}
          height={60}
          className="admin-top-nav-logo"
        />
        <div className="admin-top-nav-navbar-text">
          {" "}
          Quicket Payment Administration{" "}
        </div>
        <Button
          variant="outlined"
          className="logoutbutton"
          data-testid="log-out-button"
          onClick={()=>dispatch(logout())}
        >
          Log out
        </Button>
      </div>
      <div className="admin-layout-container">
        <div className={`Sidebar-admin ${sidebarClass}`}>
          <div className="main-tab">
            <div className="List-Sidebar-admin">
              <div className="open-menu-toggle">
                <IconButton onClick={toggleSidebar}>
                  <MenuOpenIcon className="menu-icon" />
                </IconButton>
              </div>
              <div className="seprator" />
              <div className="actions">
                <Tooltip
                  title="Filter Integration"
                  arrow
                  placement="right-start"
                >
                  <Paper className="paper-searchbox">
                    <IconButton className="search-button" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      className="search-input"
                      placeholder="Filter Integration"
                      inputProps={{ "aria-label": "search tenant" }}
                      value={searchIntegrationVariable}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          getAllIntegrationAtLoad();
                        } else {
                          filterIntegrations(e.target.value);
                        }
                      }}
                    />
                  </Paper>
                </Tooltip>
                <Tooltip title="Add Integration" arrow placement="right-start">
                  <Button
                    data-testid="add-integration-test-button"
                    className="add-integration-button"
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    onClick={(event) => {
                      addNewIntegration();
                    }}
                  >
                    Add
                    <p className="button-text"> &nbsp; Integration</p>
                  </Button>
                </Tooltip>
                <div className="line"></div>
                {listOfIntegrations === undefined ||
                listOfIntegrations.length === 0 ? (
                  <p className="no-error-message">No integration found.</p>
                ) : null}

                {searchIntegrationVariable === null &&
                  listOfIntegrations.map((integration, index) => (
                    <Tooltip
                      key={integration.Int_id}
                      title={integration.integration_name}
                      arrow
                      placement="right-start"
                    >
                      <div
                        className={`menu-1 ${
                          integration.changed_ind
                            ? "errorbordernone"
                            : "errorborder"
                        } ${
                          integration.Int_id === selectedIntegration?.Int_id
                            ? "selectedItem"
                            : "unselectedItem"
                        }`}
                        key={index}
                        data-testid="menu-items-list-integrations"
                        onClick={(e) => {
                          setNewSelectedIntegration(integration.Int_id);
                        }}
                        startIcon={
                          integration.Int_id === selectedIntegration?.Int_id ? (
                            <img
                              alt="Selected Icon"
                              src="/Images/Category.svg"
                              width="20px"
                              height="20px"
                              className="iconapp"
                            />
                          ) : (
                            <img
                              alt="Category Icon"
                              src="/Images/Categorybw.svg"
                              width="20px"
                              height="20px"
                              className="iconapp"
                            />
                          )
                        }
                      >
                        <p className="text">{integration.integration_name}</p>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteIntegration(integration);
                          }}
                        >
                          {integration.Int_id ===
                          selectedIntegration?.Int_id ? (
                            <img
                              alt="Delete Button"
                              src="/Images/icon _trash_.svg"
                              width="15px"
                              height="15px"
                            />
                          ) : (
                            <img
                              alt="Delete Button"
                              src="/Images/trash_black.svg"
                              width="15px"
                              height="15px"
                            />
                          )}
                        </IconButton>
                        {integration.changed_ind === true ? (
                          <ErrorIcon className="unsaved-icon" />
                        ) : null}
                      </div>
                    </Tooltip>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            sidebarCollapsed ? "collapsed-layout" : "sidebar-rest-admin"
          }`}
        >
          <div className="cardone-io">
            <IntegrationOnboarding />
          </div>
          <div className="cardtwo-to">
            <TenantOnboarding />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarAdmin;
