import { Box, Typography } from "@mui/material";
import React from "react";
import { ContinueButton } from "../actionButtons/allActions";
import CancelIcon from "@mui/icons-material/Cancel";
import usePaymentStatusHook from "../../hooksAndUiInteractions/PaymentStatusHook";

export default function PaymentFailure() {
  const {paymentStatus,paymentErrorMessage, total, currency,currencySymbol, paymentId} = usePaymentStatusHook()
  return (
    <div>
      <>
      <div className="payment-status-failure">
        <div>
          <CancelIcon className="icon-payment" />
        </div>
        <div>
          <Typography className="status-text">
            {paymentStatus}
            {paymentErrorMessage}
          </Typography>
        </div>
        <div>
        {currencySymbol ? (
              <Typography className="amount-text">
                {currencySymbol} {total}
              </Typography>
            ) : (
              <Typography className="amount-text">
                {total} {currency}
              </Typography>
            )}
        </div>
        {/* <div>
          <Typography className="transaction-id-text">
            Transaction ID: {paymentId}
          </Typography>
        </div> */}
        <div>
          <Typography className="date-text">
            24th March 2023, 04:56PM
          </Typography>
        </div>
      </div>
      <Box className="main-button-section">
        {/* <RetryPayment /> */}
        <ContinueButton />
      </Box>
      </>
    </div>
  );
}
