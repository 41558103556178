import React from "react";
import TopNavBar from "../commonComponents/TopNavBar";
import PaymentOrderDetails from "../commonComponents/PaymentOrderDetails";

export default function PaymentResultView() {
  return (
    <>
      <div>
        <TopNavBar />
      </div>
      <div>
        <PaymentOrderDetails/>
      </div>
    </>
  );
}
