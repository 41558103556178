import React from "react";
import { Typography } from "@mui/material";

export default function EditableConfig({
  type,
  value,
  classNameprop,
  dataTestIdProp,
  updateValue,
  controlMetaData,
  placeholder,
  newcssforinput,
  disable_edit_list,
  thisObjectName,
}) {
  const handleInputChange = (e) => {
    e.preventDefault();
    controlMetaData.updating_value = e.target.value;
    updateValue(controlMetaData);
  };
  let isEditableNew =
    (!disable_edit_list ||
      disable_edit_list.length === 0 ||
      !disable_edit_list.includes(thisObjectName)) &&
    type["isEditable"];
  return (
    <>
      {isEditableNew ? (
        <input
          className={`jira-text-field ${newcssforinput}`}
          data-testid={dataTestIdProp}
          onChange={handleInputChange}
          type={type}
          value={value || ""}
          placeholder={placeholder}
        />
      ) : (
        <Typography className={classNameprop}> {value}</Typography>
      )}
    </>
  );
}
