import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { paymentCustomAxios } from "./customAxiosInstance";
import { loadStripeTerminal } from "@stripe/terminal-js";

const apiVersions = {
  VERSION_1: "202305011200",
  VERSION_2: "202308281915",
  VERSION_3: "202309151229",
  VERSION_4: "202310110537",
  VERSION_5: "202310251200",
  VERSION_6: "202311021600",
  VERSION_7: "202403121400",
};

let conn_token = null;
var terminal;
let headers = null;
let orderPayloadToken = null;
let currentReaderDetails = null;
let dispatch = null;

export const unexpectedDisconnect = async () => {
  alert("Disconnected from reader");
  dispatch(connectReaderHandler(currentReaderDetails));
};

export const returnToken = async () => {
  const res = await paymentCustomAxios.post(
    "v1/payments/order/create",
    { order_jwt: orderPayloadToken },
    { headers: headers }
  );
  const data = await res.data;
  conn_token = await data.data.stripe_keys.terminal_connection_token;
  return conn_token;
};

export const createTerminal = async () => {
  const StripeTerminal = await loadStripeTerminal();
  terminal = StripeTerminal.create({
    onFetchConnectionToken: returnToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });
};

export const loadConfig = createAsyncThunk(
  "get/config",
  async (_, ThunkAPI) => {
    const res = await axios.get("/config.json");
    const data = await res.data;
    localStorage.setItem("API_ENDPOINT", data.apiEndpoint);
    paymentCustomAxios.defaults.baseURL = localStorage.getItem("API_ENDPOINT");
    dispatch = ThunkAPI.dispatch;
    return data;
  }
);

export const createOrderApi = createAsyncThunk(
  "create_order_api",
  async (tokens, { rejectWithValue }) => {
    orderPayloadToken = tokens.orderPayloadToken;
    headers = {
      Accept: `application/json;version=${apiVersions.VERSION_2}`,
    };
    if (tokens.authToken) {
      headers["Authorization"] = `Bearer ${tokens.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.post(
        "v1/payments/order/create",
        { order_jwt: tokens.orderPayloadToken },
        { headers: headers }
      );
      conn_token = await res.data.data.stripe_keys?.terminal_connection_token;
      return res.data;
    } catch (error) {
      return rejectWithValue({ err: error });
    }
  }
);

export const discoverReaders = createAsyncThunk(
  "/discoverReaders",
  async (currentLocation) => {
    try {
      await createTerminal();
      const config = {
        simulated: false,
        location: `${currentLocation.provider_location_id}`,
      };
      const discoverResult = await terminal.discoverReaders(config);
      return discoverResult;
    } catch (error) {
      return "not_connected";
    }
  }
);

export const getStatusDiscoverReaders = createAsyncThunk(
  "/getStatusDiscoverReaders",
  async (request, { rejectWithValue }) => {
    try {
      const config = {
        simulated: false,
        location: `${request.provider_location_id}`,
      };
      const discoverResult = await terminal.discoverReaders(config);
      return discoverResult;
    } catch (error) {
      return rejectWithValue({ value: error });
    }
  }
);

export const checkReaderConnection = createAsyncThunk(
  "/checkConnectionForReaderAndTerminal",
  async () => {
    try {
      const res = await terminal.getConnectionStatus();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const connectReaderHandler = createAsyncThunk(
  "/connectReader",
  async (currentReader) => {
    try {
      currentReaderDetails = currentReader;
      const connectResult = await terminal.connectReader(currentReader, {
        fail_if_in_use: true,
      });
      return connectResult;
    } catch (error) {
      console.log(error);
    }
  }
);

export const disconnectReader = createAsyncThunk(
  "/disconnectReader",
  async () => {
    try {
      const res = await terminal.disconnectReader();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const setDisplayReader = createAsyncThunk(
  "/readerDisplay",
  async (requestBody) => {
    try {
      const res = await terminal.setReaderDisplay({
        type: "cart",
        cart: {
          line_items: [
            {
              description: requestBody.referenceId.toString(),
              amount: Math.floor(requestBody.amount * 100),
              quantity: 1,
            },
          ],
          tax: Math.floor(requestBody.tax * 100),
          total: Math.floor(requestBody.total * 100),
          currency: "usd",
        },
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const clearReaderDisplay = createAsyncThunk(
  "/clear_reader_display",
  async () => {
    try {
      const res = await terminal.clearReaderDisplay();
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const submitCardPayment = createAsyncThunk(
  "/submit_card_payment",
  async (request) => {
    const { event, stripe, elements } = request;
    event.preventDefault();
    if (!stripe || !elements) {
      return { SetStripeLoadedErrored: "Stripe Not Loaded" };
    }
    return request;
  }
);

export const setCardValidation = createAsyncThunk(
  "/card_validation_check",
  async (request) => {
    const { elements } = request;
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return { SetCardSubmitError: submitError?.message };
    } else {
      return request;
    }
  }
);

export const processPaymentApi = createAsyncThunk(
  "/process_payment_api",
  async (request, { rejectWithValue }) => {
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_2}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.post(
        `/v1/payments/process/${request.paymentId}`,
        request.postMessage,
        {
          headers: header,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue({ err: error });
    }
  }
);

export const confirmCardPayment = createAsyncThunk(
  "/confirm_card_payment",
  async (request, { rejectWithValue }) => {
    const elements = request.cardProcessingPayload.elements;
    const stripe = request.cardProcessingPayload.stripe;
    const clientSecret = request.clientSecret;
    try {
      const response = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const collectPaymentMethod = createAsyncThunk(
  "/collect_payment_method",
  async (request) => {
    const res = await terminal.collectPaymentMethod(request);
    return res;
  }
);

export const processPosPayment = createAsyncThunk(
  "/process_pos_payment",
  async (request) => {
    const res = await terminal.processPayment(request);
    return res;
  }
);

export const checkStatus = createAsyncThunk(
  "/check_status",
  async (request) => {
    let result = null;
    let timeoutId = null;
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_3}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    return new Promise((resolve, reject) => {
      let intervalId = setInterval(() => {
        paymentCustomAxios
          .get(`/v1/payments/check_status/${request.paymentId}`, {
            params: {
              order: request.orderPayloadToken,
            },
            headers: header,
          })
          .then((res) => {
            result = res.data;
            if (
              res.data.payload.status.toLowerCase() !== "in_progress" &&
              res.data.payload.status.toLowerCase() !== "processing"
            ) {
              clearInterval(intervalId);
              resolve(res.data);
              clearTimeout(timeoutId);
            }
          })
          .catch((error) => {
            console.log(error);
            reject({ err: error });
          });
      }, 1000);
      timeoutId = setTimeout(() => {
        resolve(result);
        clearInterval(intervalId);
      }, 30000);
    });
  }
);

export const getStatusPayload = createAsyncThunk(
  "/check status call for status payload",
  async (request, { rejectWithValue }) => {
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_3}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.get(
        `/v1/payments/check_status/${request.paymentId}`,
        {
          params: {
            order: request.orderPayloadToken,
          },
          headers: header,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue({ err: error });
    }
  }
);

export const cancelPayment = createAsyncThunk(
  "/cancel_payment",
  async (request, { rejectWithValue }) => {
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_3}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.patch(
        `/v1/payments/cancel/${request.paymentId}`,
        {
          order_jwt: request.orderPayloadToken,
        },
        {
          headers: header,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue({ err: error });
    }
  }
);

export const confirmPosPaymentBackend = createAsyncThunk(
  "/pos_confirm_payment_backend",
  async (request, { rejectWithValue }) => {
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_7}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.post(
        `/v1/payments/${request.paymentId}/confirm`,
        request.postMessage,
        {
          headers: header,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue({ err: error });
    }
  }
);

export const confirmBraintreePayment = createAsyncThunk(
  "/braintree_confirm_payment",
  async (request, { rejectWithValue }) => {
    const header = {
      Accept: `application/json;version=${apiVersions.VERSION_7}`,
    };
    if (request.authToken) {
      header["Authorization"] = `Bearer ${request.authToken}`;
    }
    try {
      const res = await paymentCustomAxios.post(
        `/v1/payments/${request.paymentId}/confirm`,
        request.request,
        {
          headers: header,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue({ err: error });
    }
  }
);
