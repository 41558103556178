import { createAsyncThunk } from "@reduxjs/toolkit";
import { Amplify, Auth } from "aws-amplify";
import axios from "axios";
import { customAxios } from "./customAxios";

const apiVersions = {
  VERSION_1: "202305011200",
  VERSION_2: "202308281915",
  VERSION_3: "202309151229",
  VERSION_4: "202310110537",
  VERSION_5: "202310251200",
  VERSION_6: "202311021600",
};

export const getURL = (
  MethodType,
  APIName,
  integration_code,
  tenant_code,
  provider_location_id,
  reader_id
) => {
  switch (MethodType) {
    case "GET":
      switch (APIName) {
        case "INTEGRATION":
          return `/v1/integrations/`;
        case "TENANT":
          return `/v1/${integration_code}/tenants`;
        case "LOCATION":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/`;
        case "READER":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/${provider_location_id}/readers`;
        case "SUBSCRIPTION":
          return `/v1/${integration_code}/tenants/${tenant_code}/subscription`;
        default:
          break;
      }
      break;
    case "POST":
      switch (APIName) {
        case "INTEGRATION":
          return `/v1/integrations/onboarding`;
        case "TENANT":
          return `/v1/tenants/onboarding`;
        case "LOCATION":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/`;
        case "READER":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/${provider_location_id}/readers`;
        default:
          break;
      }
      break;
    case "PATCH":
      switch (APIName) {
        case "INTEGRATION":
          return `/v1/integrations/${integration_code}`;
        case "TENANT":
          return `/v1/${integration_code}/tenants/${tenant_code}`;
        case "LOCATION":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/${provider_location_id}`;
        default:
          break;
      }
      break;
    case "DELETE":
      switch (APIName) {
        case "INTEGRATION":
          return `/v1/integrations/${integration_code}`;
        case "TENANT":
          return `/v1/${integration_code}/tenants/${tenant_code}`;
        case "LOCATION":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/${provider_location_id}`;
        case "READER":
          return `/v1/${integration_code}/tenants/${tenant_code}/locations/${provider_location_id}/readers/${reader_id}`;
        default:
          break;
      }
      break;
    default:
      break;
  }
};

export const getRequest = (MethodType, APIName) => {
  switch (MethodType) {
    case "POST":
      switch (APIName) {
        case "INTEGRATION":
          return (selectedIntegration) => {
            return {
              integration_name: selectedIntegration.integration_name,
              integration_code: selectedIntegration.integration_code,
              // service_charges: selectedIntegration.service_charges,
              service_charges_percentage:
                selectedIntegration.service_charges_percentage,
              enabled_payment_gateways:
                selectedIntegration.enabled_payment_gateways,
              enabled_payment_methods: {
                online: selectedIntegration.enabled_payment_methods.online.map(
                  (mode) => mode.code
                ),
                in_person:
                  selectedIntegration.enabled_payment_methods.in_person.map(
                    (mode) => mode.code
                  ),
              },
              enabled_payment_method_gateways:
                selectedIntegration.enabled_payment_method_gateways,
            };
          };
        case "TENANT":
          return (selectedIntegration, selectedTenant) => {
            let api_callbacks = [];
            selectedTenant.api_callback_subscriptions.forEach((api) => {
              api_callbacks.push(api.callback_url);
            });
            let authkeys = [];
            selectedTenant.auth_keys
              .concat(selectedTenant.new_auth_keys)
              .forEach((auth) => {
                let authkeytosend = auth.key_value.replace(/\\n/g, "\n");
                authkeys.push({
                  key_value: authkeytosend,
                });
              });
            let orderpayloadkeys = [];
            selectedTenant.order_payload_keys
              .concat(selectedTenant.new_order_payload_keys)
              .forEach((opk) => {
                let publicKeyToSend = opk.key_value.replace(/\\n/g, "\n");
                orderpayloadkeys.push({
                  key_value: publicKeyToSend,
                });
              });

            let stripeKeys =
              selectedTenant.payment_gateway_key_paths.stripe
                .isPublicKeyChanged &&
              selectedTenant.payment_gateway_key_paths.stripe
                .isPrivateKeyChanged
                ? selectedTenant.payment_gateway_key_paths.stripe
                : null;
            let braintreeKeys =
              selectedTenant.payment_gateway_key_paths.braintree
                .isPublicKeyChanged &&
              selectedTenant.payment_gateway_key_paths.braintree
                .isPrivateKeyChanged &&
              selectedTenant.payment_gateway_key_paths.braintree
                .isMerchantIdChanged
                ? selectedTenant.payment_gateway_key_paths.braintree
                : null;

            let gateway_keys = {};
            if (stripeKeys) {
              gateway_keys.stripe = stripeKeys;
            }
            if (braintreeKeys) {
              gateway_keys.braintree = braintreeKeys;
            }

            const postRequest = {
              display_name: selectedTenant.display_name,
              tenant_code: selectedTenant.tenant_code,
              service_charges: selectedTenant.service_charges || null,
              service_charges_percentage:
                selectedTenant.service_charges_percentage || null,
              integration_code: selectedIntegration.integration_code,
              enabled_payment_gateways: selectedTenant.enabled_payment_gateways,
              enabled_payment_methods: {
                online: selectedTenant.enabled_payment_methods.online.map(
                  (mode) => mode.code
                ),
                in_person: selectedTenant.enabled_payment_methods.in_person.map(
                  (mode) => mode.code
                ),
              },
              api_callback_urls: api_callbacks,
              auth_keys: authkeys,
              order_payload_keys: orderpayloadkeys,
              payment_gateway_keys: gateway_keys,
              enabled_payment_method_gateways:
                selectedTenant.enabled_payment_method_gateways,
            };
            return postRequest;
          };
        case "LOCATION":
          return (location) => {
            const postRequest = {
              display_name: location.display_name,
              address: {
                line1: location.address.line1,
                city: location.address.city,
                state: location.address.state,
                country: location.address.country,
                postal_code: location.address.postal_code,
              },
              process_payment_at_backend: location.process_payment_at_backend,
            };
            return postRequest;
          };
        case "READER":
          return (reader) => {
            const PostRequest = {
              pairing_code: reader.pairing_code,
              reader_label: reader.reader_label,
              process_payment_at_backend: reader.process_payment_at_backend,
            };
            return PostRequest;
          };
        default:
          break;
      }
      break;
    case "PATCH":
      switch (APIName) {
        case "INTEGRATION":
          return (selectedIntegration) => {
            const onlinePaymentMethod =
              selectedIntegration.enabled_payment_methods.online.map(
                (mode) => mode.code
              ) ||
              selectedIntegration.braintree_enabled_payment_methods.online.map(
                (mode) => mode.code
              );
            return {
              integration_name: selectedIntegration.integration_name,
              // service_charges: selectedIntegration.service_charges,
              service_charges_percentage:
                selectedIntegration.service_charges_percentage,
              enabled_payment_gateways:
                selectedIntegration.enabled_payment_gateways,
              enabled_payment_methods: {
                online: onlinePaymentMethod,
                in_person:
                  selectedIntegration.enabled_payment_methods.in_person.map(
                    (mode) => mode.code
                  ),
              },
              enabled_payment_method_gateways:
                selectedIntegration.enabled_payment_method_gateways,
            };
          };
        case "TENANT":
          return (selectedIntegration, selectedTenant) => {
            let onlinePaymentMethod =
              selectedIntegration.enabled_payment_methods.online.map(
                (mode) => mode.code
              ) ||
              selectedIntegration.braintree_enabled_payment_methods.online.map(
                (mode) => mode.code
              );
            let api_callbacks = [];
            selectedTenant.api_callback_subscriptions.map((api) => {
              api_callbacks.push(api.callback_url);
            });
            let auth_wfe = [];
            selectedTenant.auth_keys
              .concat(selectedTenant.new_auth_keys)
              .forEach((auth) => {
                if (auth.id === null) {
                  let authkeytosend = auth.key_value?.replace(/\\n/g, "\n");
                  auth_wfe.push({
                    key_value: authkeytosend,
                  });
                } else {
                  auth_wfe.push(auth);
                }
              });

            let order_wfe = [];
            selectedTenant.order_payload_keys
              .concat(selectedTenant.new_order_payload_keys)
              .forEach((order) => {
                if (order.id === null) {
                  let orderkeytosend = order.key_value?.replace(/\\n/g, "\n");
                  order_wfe.push({
                    key_value: orderkeytosend,
                  });
                } else {
                  order_wfe.push(order);
                }
              });
            let sendingStripeData = {};

            if (
              selectedTenant.payment_gateway_key_paths.stripe
                ?.isPublicKeyChanged &&
              selectedTenant.payment_gateway_key_paths.stripe
                ?.isPrivateKeyChanged
            ) {
              sendingStripeData.stripe = {
                ...selectedTenant.payment_gateway_key_paths.stripe,
              };
            }

            if (
              selectedTenant.payment_gateway_key_paths.braintree
                ?.isPublicKeyChanged &&
              selectedTenant.payment_gateway_key_paths.braintree
                ?.isPrivateKeyChanged &&
              selectedTenant.payment_gateway_key_paths.braintree
                ?.isMerchantIdChanged
            ) {
              sendingStripeData.braintree = {
                ...selectedTenant.payment_gateway_key_paths.braintree,
              };
            }
            if (Object.keys(sendingStripeData).length === 0) {
              sendingStripeData = null;
            }
            const postRequest = {
              display_name: selectedTenant.display_name,
              tenant_code: selectedTenant.tenant_code,
              service_charges: selectedTenant.service_charges || null,
              service_charges_percentage:
                selectedTenant.service_charges_percentage || null,
              integration_code: selectedIntegration.integration_code,
              enabled_payment_gateways: selectedTenant.enabled_payment_gateways,
              enabled_payment_methods: {
                online: onlinePaymentMethod,
                in_person: selectedTenant.enabled_payment_methods.in_person.map(
                  (mode) => mode.code
                ),
              },
              api_callback_urls: [...api_callbacks],
              payment_gateway_keys: sendingStripeData,
              order_payload_keys: [...order_wfe] || null,
              auth_keys: [...auth_wfe] || null,
              enabled_payment_method_gateways:
                selectedTenant.enabled_payment_method_gateways,
            };
            return postRequest;
          };
        case "LOCATION":
          return (location) => {
            const postRequest = {
              display_name: location.display_name,
              process_payment_at_backend: location.process_payment_at_backend,
              address: {
                line1: location.address.line1,
                city: location.address.city,
                state: location.address.state,
                country: location.address.country,
                postal_code: location.address.postal_code,
              },
            };
            return postRequest;
          };
        default:
          break;
      }
      break;
    default:
      break;
  }
};

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const loadAdminConfig = createAsyncThunk("get/config", async () => {
  // val, { rejectWithValue }
  // try {
  const res = await axios.get("/config.json");
  const data = await res.data;
  localStorage.setItem("API_ENDPOINT", data.apiEndpoint);
  customAxios.defaults.baseURL = localStorage.getItem("API_ENDPOINT");
  return data;
});

export const cognitoLogin = createAsyncThunk(
  "cognito/login",
  async (val, { rejectWithValue }) => {
    const { username, password, aws_region, client_id, user_pool } = val;
    try {
      Amplify.configure({
        Auth: {
          region: aws_region,
          userPoolId: user_pool,
          userPoolWebClientId: client_id,
        },
      });

      const data = await Auth.signIn(username, password);
      return data.signInUserSession;
    } catch (err) {
      return rejectWithValue({ value: err, scenario: "login_failed" });
    }
  }
);

export const getCode = createAsyncThunk(
  "/get_code",
  async (val, { rejectWithValue }) => {
    const [username, aws_region, client_id, user_pool] = val;
    try {
      if (!isValidEmail(username.trim())) {
        return rejectWithValue({
          value: {},
          scenario: "invalid_email_forget_password",
        });
      }

      Amplify.configure({
        Auth: {
          region: aws_region,
          userPoolId: user_pool,
          userPoolWebClientId: client_id,
        },
      });

      // Send confirmation code to user's email
      const data = await Auth.forgotPassword(username.trim());
      // alert("Code generated, check your mail");
      return { value: data, scenario: "otp_generated" };
    } catch (err) {
      return rejectWithValue({ value: err, scenario: "otp_generation_failed" });
    }
  }
);

export const checkUserSession = createAsyncThunk(
  "/check_user_session",
  async (val, { rejectWithValue }) => {
    const [accessToken, aws_region, client_id, user_pool] = val;
    try {
      Amplify.configure({
        Auth: {
          region: aws_region,
          userPoolId: user_pool,
          userPoolWebClientId: client_id,
        },
      });
      const data = await Auth.currentSession();
      return { value: data, currentToken: accessToken };
    } catch (err) {
      return rejectWithValue({ value: "" });
    }
  }
);

export const setNewPassword = createAsyncThunk(
  "/set_new_password",
  async (val, { rejectWithValue }) => {
    const [username, newPassword, code, aws_region, client_id, user_pool] = val;

    try {
      Amplify.configure({
        Auth: {
          region: aws_region,
          userPoolId: user_pool,
          userPoolWebClientId: client_id,
        },
      });

      // Collect confirmation code and new password, then
      const data = await Auth.forgotPasswordSubmit(
        username.trim(),
        code.trim(),
        newPassword.trim()
      );

      return data;
    } catch (err) {
      return rejectWithValue({
        value: err,
        scenario: "forget_password_failed",
      });
    }
  }
);

export const addIntegrationApi = createAsyncThunk(
  "/add_integration",
  async (request, { rejectWithValue }) => {
    try {
      let postData = getRequest(
        "POST",
        "INTEGRATION"
      )(request.selectedIntegration);
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
        Accept: `application/json;version=${apiVersions.VERSION_6}`,
      };
      const res = await customAxios.post(
        getURL("POST", "INTEGRATION"),
        postData,
        {
          headers: headers,
        }
      );
      const data = await res.data;
      return { value: data, scenario: "integration_saved" };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        integration: request.selectedIntegration,
        scenario: "integration_save_failed",
      });
    }
  }
);

export const addTenantApi = createAsyncThunk(
  "/add_tenant_api",
  async (request, { rejectWithValue }) => {
    try {
      let postRequest = getRequest("POST", "TENANT")(
        request.selectedIntegration,
        request.selectedTenant
      );
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
        Accept: `application/json;version=${apiVersions.VERSION_5}`,
      };
      const res = await customAxios.post(
        getURL("POST", "TENANT"),
        postRequest,
        {
          headers: headers,
        }
      );
      const data = await res.data;
      return { value: data, scenario: "tenant_saved" };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        tenant: request.selectedTenant,
        scenario: "tenant_save_failed",
      });
    }
  }
);

export const addLocationApi = createAsyncThunk(
  "/add_new_location",
  async (request, { rejectWithValue }) => {
    let postData = getRequest("POST", "LOCATION")(request.selectedLocation);
    try {
      const res = await customAxios.post(
        getURL(
          "POST",
          "LOCATION",
          request.selectedIntegration.integration_code,
          request.selectedTenant.tenant_code
        ),
        postData,
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );

      const data = await res.data;
      console.log(data);
      console.log(request.selectedLocation);
      console.log(request.selectedIntegration);
      console.log(request.selectedTenant);
      return {
        resData: data,
        location: request.selectedLocation,
        integration: request.selectedIntegration,
        tenant: request.selectedTenant,
        scenario: "location_saved",
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        location: request.selectedLocation,
        scenario: "location_save_failed",
      });
    }
  }
);
export const updateLocationApi = createAsyncThunk(
  "/update_new_location",
  async (request, { rejectWithValue }) => {
    let postData = getRequest("PATCH", "LOCATION")(request.selectedLocation);
    try {
      const res = await customAxios.patch(
        getURL(
          "PATCH",
          "LOCATION",
          request.selectedIntegration.integration_code,
          request.selectedTenant.tenant_code,
          request.selectedLocation.provider_location_id
        ),
        postData,
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const data = await res.data;
      return {
        resData: data,
        location: request.selectedLocation,
        integration: request.selectedIntegration,
        tenant: request.selectedTenant,
        scenario: "location_updated",
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        location: request.selectedLocation,
        scenario: "location_update_failed",
      });
    }
  }
);

export const addReaderApi = createAsyncThunk(
  "/add_new_reader",
  async (request, { rejectWithValue }) => {
    let postRequest = getRequest("POST", "READER")(request.reader);
    try {
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
      };
      const res = await customAxios.post(
        getURL(
          "POST",
          "READER",
          request.integration,
          request.tenant,
          request.loc
        ),
        postRequest,
        {
          headers: headers,
        }
      );
      const dataresponse = await res.data;
      const updatedData = {
        integration: request.integration,
        tenant: request.tenant,
        location: request.loc,
        value: dataresponse,
        rdrid: request.reader.Rdr_id,
      };
      return { data: updatedData, scenario: "reader_saved" };
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        return rejectWithValue({
          error: error.code,
          scenario: "reader_save_failed",
        });
      } else {
        return rejectWithValue({
          value: error.response,
          locid: request.locid,
          reader: request.reader,
          scenario: "reader_save_failed",
        });
      }
    }
  }
);

export const deleteReaderApi = createAsyncThunk(
  "/delete_reader",
  async (request, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
      };
      const response = await customAxios.delete(
        getURL(
          "DELETE",
          "READER",
          request.integration,
          request.tenant,
          request.loc,
          request.reader
        ),
        {
          headers: headers,
        }
      );
      const res = await response.data;
      const responsedata = {
        loc: request.loc,
        reader: request.reader,
      };
      return { data: responsedata, scenario: "reader_deleted" };
    } catch (error) {
      return rejectWithValue({
        value: error.response,
        scenario: "reader_delete_failed",
      });
    }
  }
);

export const getAllIntegrationApi = createAsyncThunk(
  "/get_all_integration",
  async (tokens, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${tokens.getaccesstoken}`,
      };
      const res = await customAxios.get("/v1/integrations/", {
        headers: headers,
      });
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue({
        value: error.response,
        scenario: "api_not_available",
      });
    }
  }
);

export const getAllTenantsApi = createAsyncThunk(
  "/get_all_tenants",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.get(
        getURL("GET", "TENANT", request.selectedIntegration.integration_code),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        return rejectWithValue({
          error: error.code,
          scenario: "tenant_not_found",
        });
      } else {
        return rejectWithValue({
          value: error.response,
          scenario: "api_not_available",
        });
      }
    }
  }
);

export const getAllReadersApi = createAsyncThunk(
  "/get_all_readers",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.get(
        getURL(
          "GET",
          "READER",
          request.integration,
          request.tenant,
          request.loc
        ),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const data = await res.data;
      const updatedData = { location: request.loc, value: data };
      return updatedData;
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        location: request.loc,
        scenario: "api_not_available",
      });
    }
  }
);

export const getAllLocationsApi = createAsyncThunk(
  "/get_all_locations",
  async (tokens) => {
    const headers = {
      Authorization: `Bearer ${tokens.getaccesstoken}`,
    };
    const res = await customAxios.get("v1/integrations/", {
      headers: headers,
    });
    const data = await res.data;
    return data;
  }
);

export const updateIntegrationApi = createAsyncThunk(
  "/update_integration",
  async (request, { rejectWithValue }) => {
    try {
      let postData = getRequest(
        "PATCH",
        "INTEGRATION"
      )(request.selectedIntegration);
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
        Accept: `application/json;version=${apiVersions.VERSION_6}`,
      };
      const res = await customAxios.patch(
        getURL(
          "PATCH",
          "INTEGRATION",
          request.selectedIntegration.integration_code
        ),
        postData,
        {
          headers: headers,
        }
      );
      const data = await res.data;
      return { value: data, scenario: "integration_updated" };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        integration: request.selectedIntegration,
        scenario: "integration_update_failed",
      });
    }
  }
);
export const updateTenantApi = createAsyncThunk(
  "/update_tenant_api",
  async (request, { rejectWithValue }) => {
    try {
      let postRequest = getRequest("PATCH", "TENANT")(
        request.selectedIntegration,
        request.selectedTenant
      );
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
        Accept: `application/json;version=${apiVersions.VERSION_5}`,
      };
      const res = await customAxios.patch(
        getURL(
          "PATCH",
          "TENANT",
          request.selectedIntegration.integration_code,
          request.selectedTenant.tenant_code
        ),
        postRequest,
        {
          headers: headers,
        }
      );
      const data = await res.data;
      return { value: data, scenario: "tenant_updated" };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        newval: error,
        integration: request.selectedIntegration,
        tenant: request.selectedTenant,
        scenario: "tenant_update_failed",
      });
    }
  }
);

export const deleteLocationApi = createAsyncThunk(
  "/delete_location",
  async (request, thunkAPI) => {
    try {
      const res = await customAxios.delete(
        getURL(
          "DELETE",
          "LOCATION",
          request.integration_code,
          request.tenant_code,
          request.location_id
        ),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const resData = await res.data;
      resData.value = request.location_id;
      resData.scenario = "location_deleted";
      return resData;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        value: error.response.data.detail,
        scenario: "location_delete_failed",
      });
    }
  }
);

export const deleteTenantApi = createAsyncThunk(
  "/delete_tenant",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.delete(
        getURL(
          "DELETE",
          "TENANT",
          request.integration,
          request.tenant.tenant_code
        ),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const resp = await res.data;
      const responsedata = {
        integration: request.integration,
        tenant: request.tenant,
      };
      return { data: responsedata, scenario: "tenant_deleted" };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error.response,
        scenario: "tenant_delete_failed",
      });
    }
  }
);

export const deleteIntegrationApi = createAsyncThunk(
  "/delete_integration",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.delete(
        getURL("DELETE", "INTEGRATION", request.integration.integration_code),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      // if (res.status === 200) {
      //   let resdata = {};
      //   resdata.value = request.integration;
      //   resdata.scenario = "integration_deleted";
      //   return resdata;
      // }
      const resp = await res.data;
      const responsedata = {
        integration: request.integration,
      };
      return { data: responsedata, scenario: "integration_deleted" };
    } catch (error) {
      return rejectWithValue({
        value: error.response,
        scenario: "integration_delete_failed",
      });
    }
  }
);

export const getSubscriptionApi = createAsyncThunk(
  "/get_subsciption_status",
  async (request, { rejectWithValue }) => {
    const headers = {
      Authorization: `Bearer ${request.getaccesstoken}`,
    };
    try {
      const res = await customAxios.get(
        `/v1/${request.integration_code}/tenants/${request.tenant.tenant_code}/subscription`,
        {
          headers: headers,
        }
      );
      const data = await res.data;
      return {
        value: data,
        scenario: "refresh_status_complete",
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        value: error,
        scenario: "refresh_status_errored",
      });
    }
  }
);

export const discardIntegration = createAsyncThunk(
  "/discard integration",
  async (request, { rejectWithValue }) => {
    try {
      const headers = {
        Authorization: `Bearer ${request.getaccesstoken}`,
      };
      const res = await customAxios.get("/v1/integrations/", {
        headers: headers,
      });
      const data = await res.data;
      return {
        value: data,
        integration: request.integration,
      };
    } catch (error) {
      return rejectWithValue({
        error: error,
        scenario: "discard_not_available",
      });
    }
  }
);

export const discardTenant = createAsyncThunk(
  "/discard tenant",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.get(
        getURL("GET", "TENANT", request.integration.integration_code),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const data = await res.data;
      return {
        value: data,
        integration: request.integration,
        tenant: request.tenant,
      };
    } catch (error) {
      return rejectWithValue({
        error: error,
        scenario: "discard_not_available",
      });
    }
  }
);

export const discardLocation = createAsyncThunk(
  "/discard location",
  async (request, { rejectWithValue }) => {
    try {
      const res = await customAxios.get(
        getURL(
          "GET",
          "LOCATION",
          request.integration.integration_code,
          request.tenant.tenant_code
        ),
        {
          headers: {
            Authorization: `Bearer ${request.getaccesstoken}`,
          },
        }
      );
      const data = await res.data;
      return {
        value: data,
        tenant: request.tenant,
        location: request.location,
      };
    } catch (error) {
      return rejectWithValue({
        error: error,
        scenario: "discard_not_available",
      });
    }
  }
);

export const clearJwtToken = () => {
  delete customAxios.defaults.headers.common.Authorization;
};

export const logout = createAsyncThunk(
  "/logout",
  async (_, { rejectWithValue }) => {
    try {
      const res = await Auth.signOut({ global: true });
      clearJwtToken();
      return true;
    } catch (error) {
      return rejectWithValue({
        value: error,
        scenario: "log_out_errored",
      });
    }
  }
);
