import { useDispatch, useSelector } from "react-redux";
import { submitCardPayment } from "../external/Api";
import { SetOnlineError } from "../../slice/PaymentSlice";
import { useElements, useStripe } from "@stripe/react-stripe-js";

export const useStripePaymentElementHook = () => {
  const appearance = {
    theme: "flat",
  };

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements(appearance);

  const cardRequestBody = {
    payment_mode: useSelector(
      (state) => state.main.PaymentInteractionDetails.currentPaymentMode
    ),
    payment_method: useSelector(
      (state) => state.main.PaymentInteractionDetails.currentPaymentMethod
    ),
    order_jwt: useSelector(
      (state) => state.main.PaymentInteractionDetails.orderPayloadToken
    ),
  };
  const onlineErrors = useSelector(
    (state) => state.main.UIInteractions.onlineErrors
  );

  const setProcessPayment = (e) => {
    dispatch(
      submitCardPayment({
        stripe: stripe,
        elements: elements,
        event: e,
        postData: cardRequestBody,
      })
    );
  };

  const setErrorsEmpty = (event) => {
    dispatch(
      SetOnlineError({
        error: "",
        action: false,
      })
    );
  };

  return {
    onlineErrors,
    setProcessPayment,
    setErrorsEmpty,
  };
};

export default useStripePaymentElementHook;
