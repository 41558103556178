import React from "react";
import { ProcessPosPaymentAction } from "../actionButtons/allActions";
import IconButton from '@mui/material/IconButton';
import usePosMethodViewHook from "../../hooksAndUiInteractions/PosMethodViewHook";
import { useAllActionsHook } from "../../hooksAndUiInteractions/allActionsHook";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function PosModal() {

  const { showPosModal } = usePosMethodViewHook();
  const { setToggleModal } = useAllActionsHook()

  return (
    <div>

      <Dialog
        open={showPosModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setToggleModal(false) }}
        aria-describedby="alert-dialog-slide-description"
        data-testid="pos-modal-test"
      >

        <div className="dialog-div">
          <p>
            Please confirm amount breakup displayed on POS.
          </p>
          <IconButton onClick={() => { setToggleModal(false) }}
            className="close-modal" data-testid="close-modal-button-test"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogActions>
          <ProcessPosPaymentAction />
          {/* <Button className="close-modal"> Close </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
