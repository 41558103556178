import React, { useEffect } from "react";

import { Box, TextField, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useLoginHook } from "../adminHooks/loginHook";
import AlertModal from "./AlertModal";
export default function ForgetPassword() {
  const {
    username,
    codeGenerated,
    newPassword,
    code,
    isPasswordUpdated,
    takeInput,
    fetchCode,
    submitNewPassword,
  } = useLoginHook();
  const navigate = useNavigate();

  useEffect(() => {
    if (isPasswordUpdated) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordUpdated]);

  return (
    <div className="auth-page-container">
      <AlertModal />
      <Box className="login-container">
        <Box className="login-form-container">
          <img
            src="/Images/quicketsolutionslogo.svg"
            alt=""
            style={{ marginBottom: 15 }}
          />
          <Typography variant="h6" className="forget-pass">
            Password reset for Quicket Pay Administration
          </Typography>
          <TextField
            disabled={codeGenerated}
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            value={username}
            autoComplete="username"
            autoFocus
            onChange={(event) => {
              takeInput(event.target.name, event.target.value);
            }}
          />
          <Button
            disabled={codeGenerated}
            type="submit"
            fullWidth
            variant="contained"
            data-testid="get-otp-button"
            onClick={(e) => {
              e.preventDefault();
              fetchCode();
            }}
          >
            Get Code
          </Button>
          <br />
          <TextField
            required
            fullWidth
            // disabled={!codeGenerated} 
            // NOTE - Removing this disabled check
            // To reproduce the bug - Forget Password, then enter new password with incorrect format -> the field to enter new password remains disabled.
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            value={newPassword}
            autoComplete="newPassword"
            onChange={(event) => {
              takeInput(event.target.name, event.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            // disabled={!codeGenerated}
            // NOTE - Removing this disabled check
            // To reproduce the bug - Forget Password, then enter new password with incorrect format -> the field to enter new password remains disabled.
            name="code"
            label="Code"
            type="text"
            id="code"
            value={code}
            autoComplete="code"
            onChange={(event) => {
              takeInput(event.target.name, event.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            data-testid="set-new-pass-button"
            onClick={(e) => {
              e.preventDefault();
              submitNewPassword();
            }}
          >
            Set new password
          </Button>
          <div>
            <Link
              //   onClick={() => handlePopState(this.props)}
              className="link-url"
              to="/admin/login"
            >
              Already a user?
            </Link>
          </div>
        </Box>
      </Box>
    </div>
  );
}
