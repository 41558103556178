export const setToken = () => {
  const authToken = new URLSearchParams(window.location.search).get("auth_token");
  const orderPayloadToken = new URLSearchParams(window.location.search).get("order");
  const tokens = {
    authToken : authToken,
    orderPayloadToken : orderPayloadToken
  };
  return tokens;
};

export const redirectBack = (request) => {
  if(new URL(request.url).search){
    window.location.href = `${request.url}&token=${request.payload}`
  }else{
    window.location.href = `${request.url}?token=${request.payload}`
  }
}