import React from "react";
import { TextField, Autocomplete, Typography } from "@mui/material";

import useLocationsAndReadersHook from "../../hooksAndUiInteractions/LocationsAndReadersHook";
import { RefreshPosStatus } from "../actionButtons/allActions";

export default function LocationsAndReaders() {
  const {
    locations,
    readers,
    poserrors,
    locationerrors,
    currentLocation,
    currentReader,
    setLocation,
    setReader,
  } = useLocationsAndReadersHook();

  return (
    <div className="content-locations">
      <Typography className="locationslabel"> Locations </Typography>

      {locations.length > 0 ? (
        <Autocomplete
          disablePortal
          size="small"
          autoComplete
          ListboxProps={{
            style: { maxHeight: 150, fontSize: 14 },
          }}
          id="combo-box-demo"
          options={locations}
          disableClearable={true}
          value={currentLocation}
          getOptionLabel={(option) => option.display_name}
          onChange={(event, value) =>
            setLocation(value ? value.provider_location_id : "")
          }
          data-testid="locations-autocomplete"
          className="autocomplete-container"
          renderOption={(props, option) => (
            <li {...props} key={option.provider_location_id}>
              {option.display_name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="" />}
        />
      ) : (
        <Autocomplete
          disabled
          size="small"
          id="combo-box-demo"
          options={[]}
          className="autocomplete-container"
          data-testid="locations-disabled-autocomplete"
          renderInput={(params) => (
            <TextField {...params} label={locationerrors} />
          )}
        />
      )}

      <Typography className="locationslabel"> Readers </Typography>
      {readers.length > 0 ? (
        <div className="reader-status-div">
          <Autocomplete
            disablePortal
            autoComplete
            size="small"
            id="combo-box-demo"
            ListboxProps={{
              style: { maxHeight: 100, fontSize: 14 },
            }}
            options={readers}
            value={currentReader}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, value) => setReader(value ? value.id : "")}
            className="autocomplete-container-reader"
            data-testid="readers-autocomplete"
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="" />}
          />
          {readers.length > 0 && currentReader.hasOwnProperty("id") ? (
            <div className="reader-status">
              {currentReader.status === "offline" ? (
                <div className="offline-label">Offline</div>
              ) : currentReader.status === "online" ? (
                <div className="online-label">Online</div>
              ) : null}
              <RefreshPosStatus />
            </div>
          ) : null}
        </div>
      ) : (
        <Autocomplete
          disabled
          size="small"
          id="combo-box-demo"
          options={[]}
          className="autocomplete-container"
          data-testid="readers-disabled-autocomplete"
          renderInput={(params) => <TextField {...params} label={poserrors} />}
        />
      )}
    </div>
  );
}
