import { useDispatch, useSelector } from "react-redux";
import {
  ToggleModal,
  SetProcessPosPayment,
  SetReaderDisplay,
  SetRedirectBack,
  SetPaymentCancel,
  ClearReaderDisplay,
  SetCheckstatusPending,
  SetGetStatusPayload,
  CheckConnectionStatus,
  setDiscoverReaders,
  SetPosBackendPayment,
} from "../../slice/PaymentSlice";

export const useAllActionsHook = () => {
  const dispatch = useDispatch();
  const onlineActionsDisabled = useSelector(
    (state) => state.main.UIInteractions.onlineActionsDisabled
  );
  const posActionsDisabled = useSelector(
    (state) => state.main.UIInteractions.posActionsDisabled
  );
  const statusPayload = useSelector(
    (state) => state.main.PaymentIntegrations.statusPayload
  );
  const isPaymentStarted = useSelector(
    (state) => state.main.UIInteractions.isPaymentStarted
  );
  const processPaymentAtBackend = useSelector(
    (state) => state.main.PaymentInteractionDetails.processPaymentAtBackend
  );
  const disableRecheckButton = useSelector(
    (state) => state.main.PaymentInteractionDetails.disableRecheckButton
  );
  const disableReconnect = posActionsDisabled;

  const reconnectReader = () => {
    processPaymentAtBackend
      ? dispatch(setDiscoverReaders())
      : dispatch(CheckConnectionStatus());
    //dispatch(CheckConnectionStatus());
  };

  const setToggleModal = (value) => {
    dispatch(ToggleModal(value));
    if (value === true) {
      dispatch(SetReaderDisplay());
    } else {
      dispatch(ClearReaderDisplay());
    }
  };

  const setProcessPosPayment = (value) => {
    dispatch(SetProcessPosPayment());
  };

  const redirectBack = () => {
    if (statusPayload === "" || statusPayload === null) {
      dispatch(SetGetStatusPayload());
    } else {
      dispatch(SetRedirectBack());
    }
  };

  const setPaymentCancel = () => {
    dispatch(SetPaymentCancel());
  };

  const recheckStatus = () => {
    dispatch(SetCheckstatusPending());
  };

  const posBackend = () => {
    dispatch(SetPosBackendPayment());
  };

  return {
    onlineActionsDisabled,
    posActionsDisabled,
    disableReconnect,
    isPaymentStarted,
    disableRecheckButton,
    reconnectReader,
    setToggleModal,
    setProcessPosPayment,
    redirectBack,
    setPaymentCancel,
    recheckStatus,
    posBackend,
  };
};
