import { useSelector } from "react-redux";
import { useState } from "react";

const useCardMethodComponentHook = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const publicKey = useSelector(
    (state) => state.main.PaymentIntegrations.stripeKeys.publicKey
  );

  const options = {
    mode: "payment",
    payment_method_types: ["card"],
    amount:Math.floor(useSelector((state) => state.main.IntegratingClientDetails.amountDetails.total) * 100),
    currency: useSelector((state) =>
      state.main.IntegratingClientDetails.amountDetails.currency.toLowerCase()
    ),
    appearance: {
      theme: "stripe",
    },
  };
  const onlineErrors = useSelector(
    (state) => state.main.UIInteractions.onlineErrors
  );

  return {
    publicKey,
    options,
    stripePromise,
    setStripePromise,
    onlineErrors,
  };
};

export default useCardMethodComponentHook;
