import { configureStore } from "@reduxjs/toolkit";
import { MainReducer } from "../slice/PaymentSlice";
import { AdminReducer } from "../slice/AdminSlice";

export const mainStore = configureStore({
  reducer: {
    main: MainReducer,
    admin: AdminReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
