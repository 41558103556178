import { AdminTypes } from "./adminConstants";
export let adminInitState = {
  isModalOpen: false,
  isSaveModalOpen: false,
  isSidebarOpen: false,
  userCredentials: {
    username: "",
    password: "",
    codeGenerated: false,
    newPassword: "",
    code: "",
    isPasswordUpdated: false,
  },
  configVariables: {
    aws_region: "",
    user_pool: "",
    client_id: "",
    configLoaded: false,
  },
  accessTokens: {
    accessToken: "",
    refreshToken: "",
  },
  errorHelperTexts: {
    emailError: null,
    emailHelperText: "",
    passwordError: null,
    passWordHelperText: "",
    canLogin: null,
  },
  integrationManagement: {
    adminType: AdminTypes.GLOBAL,
    nextNewId: 10000,
    selectedIntegration_id: null,
    listOfIntegrations: [],
    searchVariables: {
      readerInput: null,
      locationInput: null,
      tenantInput: null,
      integrationInput: null,
    },
  },
  alertAndErrors: {
    loginAlerts: null,
    forgetPaswordAlerts: null,
    currentAlert: null,
    tenantAlerts: null,
    integrationAlerts: null,
    locationAlerts: null,
    readerAlerts: null,
  },
  pagination: {
    tenantsPerPage: 10,
    tenantsPage: 1,
    locationsPerPage: 10,
    locationsPage: 1,
  },
};

export let listofreaders1 = [
  {
    Rdr_id: "RDR-0001",
    isEditable: false,
    pairing_code: "puppies-plug-could",
    reader_label: "Test reader one",
    reader_id: "TA",
  },
  {
    Rdr_id: "RDR-0002",
    isEditable: false,
    pairing_code: "puppies-plug-could",
    reader_label: "Test reader two",
    reader_id: "TA",
  },
];
export let listoflocations1 = [
  {
    Loc_id: "LOC-0001",
    isExpanded: false,
    toggleToEdit: true,
    isEditable: false,
    display_name: "Location 1",
    address: {
      line1: "123 Main Street",
      city: "New York",
      state: "NY",
      country: "US",
      postal_code: "10001",
    },
    readers: listofreaders1,
  },
  {
    Loc_id: "LOC-0002",
    isExpanded: false,
    toggleToEdit: false,
    isEditable: false,
    display_name: "Location two",
    address: {
      line1: "123 Main Street",
      city: "New York",
      state: "NY",
      country: "US",
      postal_code: "10001",
    },
    readers: [],
  },
  {
    Loc_id: "LOC-0003",
    isExpanded: false,
    toggleToEdit: true,
    isEditable: false,
    display_name: "Location 3hbhbhvf",
    address: {
      line1: "123 Main Street",
      city: "New York",
      state: "NY",
      country: "US",
      postal_code: "10001",
    },
    readers: [],
  },
];
export let listoftenants1 = [
  {
    Tnt_id: "TNT-0001",
    IsDuplicate: false,
    isEditable: false,
    display_name: "OA-ROK Tenant",
    tenant_code: "ROK",
    payment_gateway_key_paths: {
      stripe: {
        public_key: "testpublicstripekey",
        private_key: "testprivatestripekey",
      },
    },
    api_callback_subscriptions: [
      {
        date_created: "",
        callback_url: "https://example-domain.com/callback",
        subscription_status: "ACTIVE",
      },
    ],
    admin_auth_keys: ["testauthkey"],
    order_payload_keys: ["testorderpayloadkey"],
    public_status_payload_keys: "testpublicstatuspayloadkey",
    locations: listoflocations1,
  },
  {
    Tnt_id: "TNT-0002",
    isEditable: false,
    display_name: "SEL",
    tenant_code: "SELCODE",
    payment_gateway_key_paths: {
      stripe: {
        public_key: "testpublicstripekey",
        private_key: "testprivatestripekey",
      },
    },
    api_callback_subscriptions: [
      {
        date_created: "",
        callback_url: "https://example-domain.com/callback",
        subscription_status: "ACTIVE",
      },
    ],
    admin_auth_keys: ["testauthkey"],
    order_payload_keys: ["testorderpayloadkey"],
    public_status_payload_keys: "testpublicstatuspayloadkey",
    locations: [
      {
        Loc_id: "LOC-0001",
        isExpanded: false,
        toggleToEdit: true,
        display_name: "Location 1",
        address: {
          line1: "123 Main Street",
          city: "New York",
          state: "NY",
          country: "US",
          postal_code: "10001",
        },
        readers: [
          {
            pairing_code: "puppies-plug-could",
            reader_label: "Test reader",
          },
        ],
      },
      {
        Loc_id: "LOC-0002",
        isExpanded: false,
        toggleToEdit: false,
        display_name: "Location 1",
        address: {
          line1: "123 Main Street",
          city: "New York",
          state: "NY",
          country: "US",
          postal_code: "10001",
        },
        readers: [
          {
            pairing_code: "puppies-plug-could",
            reader_label: "Test reader",
          },
        ],
      },
      {
        Loc_id: "LOC-0003",
        isExpanded: false,
        toggleToEdit: true,
        display_name: "Location 3hbhbhvf",
        address: {
          line1: "123 Main Street",
          city: "New York",
          state: "NY",
          country: "US",
          postal_code: "10001",
        },
        readers: [],
      },
    ],
  },
];
export let listofintegrations = [
  {
    Int_id: "INT-0001",
    isEditable: false,
    integration_name: "One App",
    integration_code: "OA",
    braintree_enabled_payment_methods: {
      online: [
        {
          display: "Card",
          code: "card",
        },
      ],
    },
    enabled_payment_methods: {
      online: [
        {
          display: "Card",
          code: "card",
        },
      ],
      in_person: [
        {
          display: "POS",
          code: "card_present",
        },
      ],
    },
    service_charges: "0.055",
    selectedTenant_id: "TNT-0001",
    tenants: listoftenants1,
  },
  {
    Int_id: "INT-0002",
    isEditable: false,
    integration_name: "Muni Stickers",
    integration_code: "MUNI",
    braintree_enabled_payment_methods: {
      online: [
        {
          display: "Card",
          code: "card",
        },
      ],
    },
    enabled_payment_methods: {
      online: [
        {
          display: "Card",
          code: "card",
        },
      ],
      in_person: [
        {
          display: "POS",
          code: "card_present",
        },
      ],
    },
    service_charges: "0.055",
    selectedTenant_id: null,
    tenants: [],
  },
];
