import { useDispatch, useSelector } from "react-redux";
import { SetPaymentView } from "../../slice/PaymentSlice";

const useSideNavigationHook = () => {
  const dispatch = useDispatch();

  const viewStructure = useSelector(
    (state) => state.main.UIInteractions.viewStructure
  );

  const menuStructure = useSelector(
    (state) => state.main.UIInteractions.menuStructure
  );

  const isPaymentStarted = useSelector(
    (state) => state.main.UIInteractions.isPaymentStarted
  );

  const setPaymentMethod = (menuTag) => {
    dispatch(SetPaymentView(menuTag));
  };

  return {
    viewStructure,
    menuStructure,
    setPaymentMethod,
    isPaymentStarted
  };
};

export default useSideNavigationHook;
