import { useDispatch, useSelector } from "react-redux";
import {
  credentialInput,
  resetForgetVariables,
  validateInput,
} from "../../slice/AdminSlice";
import {
  checkUserSession,
  cognitoLogin,
  getCode,
  loadAdminConfig,
  setNewPassword,
} from "../external/Api";

export const useLoginHook = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state) => state.admin.accessTokens.accessToken
  );

  const username = useSelector((state) => state.admin.userCredentials.username);
  const password = useSelector((state) => state.admin.userCredentials.password);
  const codeGenerated = useSelector(
    (state) => state.admin.userCredentials.codeGenerated
  );
  const newPassword = useSelector(
    (state) => state.admin.userCredentials.newPassword
  );
  const code = useSelector((state) => state.admin.userCredentials.code);
  const isPasswordUpdated = useSelector(
    (state) => state.admin.userCredentials.isPasswordUpdated
  );
  const aws_region = useSelector(
    (state) => state.admin.configVariables.aws_region
  );
  const user_pool = useSelector(
    (state) => state.admin.configVariables.user_pool
  );

  const client_id = useSelector(
    (state) => state.admin.configVariables.client_id
  );

  const configLoaded = useSelector(
    (state) => state.admin.configVariables.configLoaded
  );

  const emailError = useSelector(
    (state) => state.admin.errorHelperTexts.emailError
  );
  const passwordError = useSelector(
    (state) => state.admin.errorHelperTexts.passwordError
  );
  const passWordHelperText = useSelector(
    (state) => state.admin.errorHelperTexts.passWordHelperText
  );
  const emailHelperText = useSelector(
    (state) => state.admin.errorHelperTexts.emailHelperText
  );
  const canLogin = useSelector(
    (state) => state.admin.errorHelperTexts.canLogin
  );
  const takeInput = (key, value) => {
    dispatch(credentialInput({ key: key, value: value }));
  };

  const getConfig = () => {
    dispatch(loadAdminConfig());
  };

  const validateInp = (username, password) => {
    dispatch(validateInput({ username: username, password: password }));
  };

  const cognitoSignIn = (username, password, aws_region, client_id) => {
    dispatch(
      cognitoLogin({
        username: username,
        password: password,
        aws_region: aws_region,
        client_id: client_id,
        user_pool: user_pool,
      })
    );
  };

  const fetchCode = () => {
    dispatch(getCode([username, aws_region, client_id, user_pool]));
  };

  const submitNewPassword = () => {
    dispatch(
      setNewPassword([
        username,
        newPassword,
        code,
        aws_region,
        client_id,
        user_pool,
      ])
    );
  };

  const resetVariables = () => {
    dispatch(resetForgetVariables());
  };

  const setTokenOnReload = (accessToken) => {
    // NOTE - Checking the current state accessToken is same as the local storage one
    // To verify the session is valid or not.
    dispatch(checkUserSession([accessToken, aws_region, client_id, user_pool]));
  };

  return {
    username,
    password,
    aws_region,
    user_pool,
    client_id,
    emailError,
    passwordError,
    emailHelperText,
    passWordHelperText,
    canLogin,
    codeGenerated,
    newPassword,
    code,
    isPasswordUpdated,
    accessToken,
    configLoaded,
    validateInp,
    cognitoSignIn,
    takeInput,
    getConfig,
    fetchCode,
    submitNewPassword,
    resetVariables,
    setTokenOnReload,
  };
};
