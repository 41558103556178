import { Typography } from "@mui/material";
import React from "react";
import useTransitionViewHook from "../hooksAndUiInteractions/TransitionViewHook";
import TopNavBar from "./commonComponents/TopNavBar";
import usePaymentOrderDetailsHook from "../hooksAndUiInteractions/PaymentOrderDetailsHook";

export default function TransitionView() {
  const { transitionMode, applicationErrors } = useTransitionViewHook();

  const { displayRefId, description } = usePaymentOrderDetailsHook();

  return (
    <div>
      <TopNavBar />
      {transitionMode ? (
        <>
          <div className="main-transition-div">
            {transitionMode?.icon}
            <Typography
              className={`application-loading-ty ${
                transitionMode.mode === "payment_inprogress_mode" ||
                transitionMode.mode === "backend_payment_processing_mode" ||
                transitionMode.mode === "recheck_status" ||
                transitionMode.mode === "payment_processing_mode"
                  ? ""
                  : "no-description"
              }`}
            >
              {applicationErrors
                ? applicationErrors
                : transitionMode.main_message_text}
            </Typography>
            {transitionMode.mode === "payment_inprogress_mode" ||
            transitionMode.mode === "backend_payment_processing_mode" ||
            transitionMode.mode === "recheck_status" ||
            transitionMode.mode === "payment_processing_mode" ? (
              <>
                <div className="id-description">
                  <Typography className="transition-ref-id">
                    {description}
                  </Typography>
                  <Typography className="transition-ref-id">
                    Reference Id - {displayRefId}
                  </Typography>
                </div>
              </>
            ) : null}
            <div>
              {transitionMode.buttons.map((b, index) => (
                <React.Fragment key={index}>
                  <div className="button-container-recheck">{b}</div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      ) : null}

      {/* <div data-testid ="page">{sampleTransitionView}</div>
        <div data-testid="responseData">{sampleResponse.count}</div> */}
      {/* <Button
        variant="contained"
        onClick={() => dispatch(sampleApi({ input: input, check: true }))}
      >
        Click Me
      </Button> */}
    </div>
  );
}
