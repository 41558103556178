import React from "react";
import LocationsAndReaders from "./LocationsAndReaders";
import {
  ConfirmPaymentBackend,
  PreviewAndProceedOnPOS,
  ProcessPosPaymentAction,
} from "../actionButtons/allActions";
import { CancelPayment } from "../actionButtons/allActions";
import usePosMethodViewHook from "../../hooksAndUiInteractions/PosMethodViewHook";
import PosModal from "./PosModal";

export default function PosMethodComponent() {
  const { isPOSpossible, stripeErrors, processPaymentAtBackend } =
    usePosMethodViewHook();

  return (
    <div>
      {isPOSpossible === true ? (
        <>
          <div className="pos-card-main">
            <LocationsAndReaders />
          </div>
          <div className="error-div-main">
            {stripeErrors ? stripeErrors : ""}
          </div>
          <div className="new-button" data-testid="pos-buttons-test">
            {processPaymentAtBackend ? (
              <>
                <ConfirmPaymentBackend />
              </>
            ) : (
              <>
                <div className="row-button">
                  <PreviewAndProceedOnPOS />
                  <ProcessPosPaymentAction />
                </div>
              </>
            )}
            <div className="cancel-div">
              <CancelPayment />
            </div>
          </div>
          <PosModal />
        </>
      ) : (
        stripeErrors
      )}
    </div>
  );
}
