import React from "react";
import useAppHook from "../hooksAndUiInteractions/AppHook";
import { ViewComponents } from "../../stateAndConstants/paymentStateAndConstants/constants";


export default function PaymentViewContainer() {
  const { viewStructure } = useAppHook();

  return (
    <div>
      {ViewComponents[viewStructure.MainView]}
    </div>
  );
}
