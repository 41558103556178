import React, { useEffect } from "react";
import dropin from "braintree-web-drop-in";
import useDropinHook from "./DropinHook";
import { Box, Button } from "@mui/material";
import { CancelPayment } from "../actionButtons/allActions";

export default function DropinComponent() {
  const {
    clientToken,
    isBraintree,
    setBraintreeError,
    onlineErrors,
    dropinInstance,
    setBraintreeInstance,
    handleRequestPayment,
  } = useDropinHook();

  useEffect(() => {
    if (isBraintree) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: clientToken,
            container: "#dropin-container",
            card: {
              overrides: {
                fields: {
                  number: {
                    placeholder: "1111 1111 1111 1111",
                  },
                },
                styles: {
                  input: {
                    "font-size": "16px",
                  },
                },
              },
            },
          },
          function (error, instance) {
            if (error) {
              setBraintreeError(error);
            } else {
              setBraintreeInstance(instance);
            }
          }
        );

      if (dropinInstance) {
        dropinInstance.teardown().then(() => {
          console.log("teared successfully");
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [isBraintree]);

  return (
    <div>
      <>
        <div className="stripe-payment-card-main">
          <div
            className="dropin-container-div"
            id="dropin-container"
            data-testid="dropin-container-test"
          ></div>
        </div>
        <div className="error-div-main">
          {onlineErrors && <div>{onlineErrors}</div>}
        </div>
        <Box className="main-button-section" data-testid="card-buttons-test">
          <Button
            id="submit-button"
            data-testid="braintree-submit-button-test"
            className="process-payment-button"
            onClick={handleRequestPayment}
          >
            Submit Payment
          </Button>
          <div className="cancel-div">
            <CancelPayment />
          </div>
        </Box>
      </>
    </div>
  );
}
