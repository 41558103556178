import React from "react";
import { useStatusPageHook } from "./StatusPayloadHook";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import TopNavBar from "../components/commonComponents/TopNavBar";

export default function StatusPayloadPage() {
  const { payload } = useStatusPageHook();
  return (
    <div className="Main-status-payload-page">
      <TopNavBar />
      <Card className='c-card'>
        <div className='c-card-header'>
          <Typography variant='h6' className='c-card-header-title'> Payment Status </Typography>
        </div>
        <div class="c-border-section">
          <div class="c-border-left"></div>
          <div class="c-border-right"></div>
          <div class="c-dotted-line"></div>
        </div>
        <div className="content-body">
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Payment ID</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.payment_id}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Reference ID</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.reference_id}</Typography>
          </div>
          {/* <div className="one-data">
            <Typography className="title-onedata" width={120}>Payment Mode</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.payment_mode}</Typography>
          </div> */}
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Payment Method</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.payment_method}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Payment Status</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.status}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Payment Currency</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.currency}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Card Last Digits</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.card.card_last_4}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Card Brand</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.card.card_brand}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Card Type</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.card.card_type}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Card Expiry Year</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.card.exp_year}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Card Expiry Month</Typography>
            <Typography>:</Typography>
            <Typography className="response-onedata" width={280}>{payload.card.exp_month}</Typography>
          </div>
          <div className="one-data">
            <Typography className="title-onedata" width={120}>Reciept URL</Typography>
            <Typography>:</Typography>
            <a href={payload.additional_info.receipt_url} target="_blank" rel="noopener noreferrer" className="response-onedata">
               Reciept URL Link
            </a>
          </div>
          
        </div>

      </Card>

    </div>
  );
}
