import { createSlice } from "@reduxjs/toolkit";
import { adminInitState } from "../stateAndConstants/adminStateAndConstants/adminInitialState";
import {
  addIntegrationApi,
  cognitoLogin,
  getAllIntegrationApi,
  getCode,
  loadAdminConfig,
  setNewPassword,
  getAllTenantsApi,
  addReaderApi,
  getAllReadersApi,
  addLocationApi,
  updateLocationApi,
  deleteLocationApi,
  deleteReaderApi,
  deleteTenantApi,
  deleteIntegrationApi,
  updateTenantApi,
  updateIntegrationApi,
  addTenantApi,
  getSubscriptionApi,
  discardIntegration,
  discardTenant,
  discardLocation,
  logout,
  checkUserSession,
} from "../AdminModule/external/Api";
import { isValidEmail } from "../AdminModule/external/Api";
import {
  TemplateIntegration,
  TemplateTenant,
  TemplateLocation,
  TemplateReader,
  alertObject,
  selInt,
  selTnt,
  selCurrentLoc,
  generateNewID,
  getSupportedPaymentModes,
  allPaymentModes,
  nonSupportedModes,
  getCurrentEditingStatus,
  JPJS,
  convertValues,
  TemplateAuth,
  TemplateOrder,
  TemplateApi,
  allBraintreePaymentModes,
} from "../stateAndConstants/adminStateAndConstants/adminConstants";

export const AdminSlice = createSlice({
  name: "admin",
  initialState: adminInitState,
  reducers: {
    credentialInput: (state, action) => {
      const { key, value } = action.payload;
      state.errorHelperTexts.emailError = false;
      state.errorHelperTexts.emailHelperText = "";
      state.errorHelperTexts.passwordError = false;
      state.errorHelperTexts.passWordHelperText = "";
      state.userCredentials[key] = value;
    },
    resetForgetVariables: (state, action) => {
      state.userCredentials.isPasswordUpdated = false;
      state.userCredentials.code = "";
      state.userCredentials.newPassword = "";
      state.userCredentials.codeGenerated = false;
    },
    validateInput: (state, action) => {
      const { username, password } = action.payload;
      const isValidEm = isValidEmail(username);

      if (!isValidEm) {
        state.errorHelperTexts.emailError = true;
        state.errorHelperTexts.canLogin = false;
        state.errorHelperTexts.emailHelperText = "Email is invalid";
      } else if (password.length === 0) {
        state.errorHelperTexts.passwordError = true;
        state.errorHelperTexts.passWordHelperText =
          "Password is a required field";
        state.errorHelperTexts.canLogin = false;
      } else {
        state.errorHelperTexts.canLogin = true;
      }
    },
    filterIntegration: (state, action) => {
      let searchvar = action.payload;
      if (action.payload !== "") {
        state.integrationManagement.listOfIntegrations =
          state.integrationManagement.listOfIntegrations.filter(
            (integration) => {
              return (
                integration.integration_name
                  .toLowerCase()
                  .includes(searchvar.toLowerCase()) ||
                integration.integration_code
                  .toLowerCase()
                  .includes(searchvar.toLowerCase())
              );
            }
          );
        state.integrationManagement.selectedIntegration_id =
          state.integrationManagement.listOfIntegrations[0]?.Int_id;
      }
    },
    filterTenant: (state, action) => {
      const selectedIntegration = selInt(state.integrationManagement);
      let searchvar = action.payload;
      if (action.payload !== "") {
        selectedIntegration.tenants = selectedIntegration.tenants.filter(
          (tenant) => {
            return (
              tenant.display_name
                .toLowerCase()
                .includes(searchvar.toLowerCase()) ||
              tenant.tenant_code.toLowerCase().includes(searchvar.toLowerCase())
            );
          }
        );
        selectedIntegration.selectedTenant_id =
          selectedIntegration.tenants[0]?.Tnt_id;
      }
    },
    filterLocation: (state, action) => {
      const selectedTenant = selTnt(state.integrationManagement);
      let searchvar = action.payload;
      let locations = JPJS(selectedTenant.locations);
      if (action.payload !== "") {
        selectedTenant.locations = selectedTenant.locations.filter(
          (location) => {
            return (
              location.display_name
                .toLowerCase()
                .includes(searchvar.toLowerCase()) ||
              location.provider_location_id
                .toLowerCase()
                .includes(searchvar.toLowerCase())
            );
          }
        );
        if (selectedTenant.locations.length < 0) {
          selectedTenant.locations = locations;
        }
      } else {
        selectedTenant.locations = locations;
      }
    },
    togglePaymentReducer: (state, action) => {
      const { type, value, gateway, isChecked } = action.payload;
      console.log(type);
      const selectedIntegration = selInt(state.integrationManagement);
      const enabledMethods = selectedIntegration.enabled_payment_methods[type];
      const braintreeEnabledPaymentMethods =
        selectedIntegration.braintree_enabled_payment_methods[type];
      if (isChecked) {
        if (!selectedIntegration.enabled_payment_gateways.includes(gateway)) {
          selectedIntegration.enabled_payment_gateways.push(gateway);
        }
        if (gateway === "braintree") {
          if (braintreeEnabledPaymentMethods.includes(value.code)) {
            selectedIntegration.braintree_enabled_payment_methods[type] =
              braintreeEnabledPaymentMethods.filter(
                (item) => item !== value.code
              );
          } else {
            selectedIntegration.braintree_enabled_payment_methods[type].push(
              value
            );
          }
        }
        if (!enabledMethods.some((val) => val.code === value.code)) {
          selectedIntegration.enabled_payment_methods[type].push(value);
        }

        selectedIntegration.enabled_payment_method_gateways[type][value.code] =
          gateway;
      } else {
        selectedIntegration.enabled_payment_method_gateways[type][value.code] =
          "";
        if (selectedIntegration.enabled_payment_gateways.includes(gateway)) {
          if (
            !(
              selectedIntegration.enabled_payment_method_gateways.in_person
                .card_present === "stripe" ||
              selectedIntegration.enabled_payment_method_gateways.online
                .card === "stripe"
            )
          ) {
            // Doing this If condition as - if any one of them is stripe then should not be removed from enabled_gateways
            selectedIntegration.enabled_payment_gateways =
              selectedIntegration.enabled_payment_gateways.filter(
                (g) => g !== gateway
              );
          }
        }
        if (gateway === "braintree") {
          if (braintreeEnabledPaymentMethods.includes(value.code)) {
            selectedIntegration.braintree_enabled_payment_methods[type] =
              braintreeEnabledPaymentMethods.filter(
                (item) => item.code !== value.code
              );
          } else {
            selectedIntegration.braintree_enabled_payment_methods[type] =
              enabledMethods.filter((item) => item.code !== value.code);
          }
        }
        if (enabledMethods.some((val) => val.code === value.code)) {
          selectedIntegration.enabled_payment_methods[type] =
            enabledMethods.filter((item) => item.code !== value.code);
        } else {
          selectedIntegration.enabled_payment_methods[type] =
            enabledMethods.filter((item) => item.code !== value.code);
        }
      }
      if (selectedIntegration.record_status !== "A") {
        selectedIntegration.record_status = "E";
        selectedIntegration.changed_ind = true;
      }
    },
    toggleTenantPaymentReducer: (state, action) => {
      const { type, value, gateway, isChecked } = action.payload;
      const selectedTenant = selTnt(state.integrationManagement);
      const enabledMethods = selectedTenant.enabled_payment_methods[type];
      const braintreeEnabledPaymentMethods =
        selectedTenant.braintree_enabled_payment_methods[type];
      if (isChecked) {
        if (!selectedTenant.enabled_payment_gateways.includes(gateway)) {
          selectedTenant.enabled_payment_gateways.push(gateway);
        }
        if (gateway === "braintree") {
          if (braintreeEnabledPaymentMethods.includes(value.code)) {
            selectedTenant.braintree_enabled_payment_methods[type] =
              braintreeEnabledPaymentMethods.filter(
                (item) => item !== value.code
              );
          } else {
            selectedTenant.braintree_enabled_payment_methods[type].push(value);
          }
        }
        if (enabledMethods.some((val) => val.code === value.code)) {
          selectedTenant.enabled_payment_methods[type] = enabledMethods.filter(
            (item) => item !== value.code
          );
        } else {
          selectedTenant.enabled_payment_methods[type].push(value);
        }
        selectedTenant.enabled_payment_method_gateways[type][value.code] =
          gateway;
      } else {
        selectedTenant.enabled_payment_method_gateways[type][value.code] = "";
        if (selectedTenant.enabled_payment_gateways.includes(gateway)) {
          if (
            !(
              selectedTenant.enabled_payment_method_gateways.in_person
                .card_present === "stripe" ||
              selectedTenant.enabled_payment_method_gateways.online.card ===
                "stripe"
            )
          ) {
            // Doing this If condition as - if any one of them is stripe then should not be removed from enabled_gateways
            selectedTenant.enabled_payment_gateways =
              selectedTenant.enabled_payment_gateways.filter(
                (g) => g !== gateway
              );
          }
        }
        if (gateway === "braintree") {
          if (braintreeEnabledPaymentMethods.includes(value.code)) {
            selectedTenant.braintree_enabled_payment_methods[type] =
              braintreeEnabledPaymentMethods.filter(
                (item) => item !== value.code
              );
          } else {
            selectedTenant.braintree_enabled_payment_methods[type] =
              enabledMethods.filter((item) => item.code !== value.code);
          }
        }
        if (enabledMethods.some((val) => val.code === value.code)) {
          selectedTenant.enabled_payment_methods[type] = enabledMethods.filter(
            (item) => item !== value.code
          );
        }
      }
      if (selectedTenant.record_status !== "A") {
        selectedTenant.record_status = "E";
        selectedTenant.changed_ind = true;
      }
    },
    closeAlertBox: (state, action) => {
      state.isModalOpen = action.payload;
    },
    closeSaveAlertBox: (state, action) => {
      state.isSaveModalOpen = action.payload;
    },
    setSelectedIntegration: (state, action) => {
      state.isSaveModalOpen = false;
      state.integrationManagement.selectedIntegration_id = action.payload;
    },
    setSelectedTenant: (state, action) => {
      selInt(state.integrationManagement).selectedTenant_id = action.payload;
    },
    UpdateUIValue: (state, action) => {
      let controlMetaData = action.payload;
      switch (controlMetaData.Updating_Section) {
        case "INTEGRATION":
          state.integrationManagement?.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              if (j.record_status !== "A") {
                j.record_status = "E";
              }
              j.changed_ind = true;
              switch (controlMetaData.updating_key) {
                case "service_charges_percentage":
                  if (isNaN(controlMetaData.updating_value)) {
                    j[controlMetaData.updating_key] =
                      controlMetaData.updating_value;
                    j.service_charges_error =
                      "Please enter a valid service charge";
                  } else {
                    j[controlMetaData.updating_key] =
                      controlMetaData.updating_value;
                    j.service_charges_error = null;
                  }
                  break;
                default:
                  j[controlMetaData.updating_key] =
                    controlMetaData.updating_value;
                  j.changed_ind = true;
                  break;
              }
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        case "TENANT":
          state.integrationManagement?.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              j.tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  if (h.record_status !== "A") {
                    h.record_status = "E";
                  }
                  h.changed_ind = true;
                  switch (controlMetaData.updating_key) {
                    case "service_charges_percentage":
                      if (isNaN(controlMetaData.updating_value)) {
                        h[controlMetaData.updating_key] =
                          controlMetaData.updating_value;
                        h.service_charges_error =
                          "Please enter a valid service charge";
                      } else {
                        h[controlMetaData.updating_key] =
                          controlMetaData.updating_value;
                        h.service_charges_error = null;
                      }
                      break;
                    case "stripepublickey":
                      h.public_stripe_error = null;
                      if (controlMetaData.updating_value !== null) {
                        let obj = { ...h.payment_gateway_key_paths.stripe };
                        obj.public_key = controlMetaData.updating_value;
                        obj.isPublicKeyChanged = true;
                        h.payment_gateway_key_paths.stripe = { ...obj };
                      }
                      break;
                    case "stripeprivatekey":
                      h.private_stripe_error = null;
                      if (controlMetaData.updating_value !== null) {
                        let objnew = { ...h.payment_gateway_key_paths.stripe };
                        objnew.private_key = controlMetaData.updating_value;
                        objnew.isPrivateKeyChanged = true;
                        h.payment_gateway_key_paths.stripe = { ...objnew };
                      }
                      break;
                    case "braintreeprivatekey":
                      h.private_braintree_error = null;
                      if (controlMetaData.updating_value !== null) {
                        let objnew = {
                          ...h.payment_gateway_key_paths.braintree,
                        };
                        objnew.private_key = controlMetaData.updating_value;
                        objnew.isPrivateKeyChanged = true;
                        h.payment_gateway_key_paths.braintree = { ...objnew };
                      }
                      break;
                    case "braintreepublickey":
                      h.public_braintree_error = null;
                      if (controlMetaData.updating_value !== null) {
                        let obj = { ...h.payment_gateway_key_paths.braintree };
                        obj.public_key = controlMetaData.updating_value;
                        obj.isPublicKeyChanged = true;
                        h.payment_gateway_key_paths.braintree = { ...obj };
                      }
                      break;
                    case "braintreemerchantid":
                      h.merchant_id_error = null;
                      if (controlMetaData.updating_value !== null) {
                        let obj = { ...h.payment_gateway_key_paths.braintree };
                        obj.merchant_id = controlMetaData.updating_value;
                        obj.isMerchantIdChanged = true;
                        h.payment_gateway_key_paths.braintree = { ...obj };
                      }
                      break;
                    default:
                      h[controlMetaData.updating_key] =
                        controlMetaData.updating_value;
                      break;
                  }
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        case "API_CALLBACK":
          state.integrationManagement.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              let tenants = j.tenants;
              tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  if (h.record_status !== "A") {
                    h.record_status = "E";
                  }
                  let newapikeys = h.api_callback_subscriptions;
                  let resultapi = newapikeys.filter((x) => {
                    return x.Api_id === controlMetaData.ID_Level[4];
                  })[0];
                  resultapi.api_error = null;
                  resultapi.callback_url = controlMetaData.updating_value;
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        case "AUTHKEY":
          state.integrationManagement.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              let tenants = j.tenants;
              tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  if (h.record_status !== "A") {
                    h.record_status = "E";
                  }
                  let newauthkeys = h.new_auth_keys;
                  let consolevar = newauthkeys.filter((x) => {
                    return x.Auth_id === controlMetaData.ID_Level[4];
                  })[0];
                  h.auth_error = null;
                  consolevar.key_value = controlMetaData.updating_value;
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        case "ORDERPAYLOAD":
          state.integrationManagement.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              let tenants = j.tenants;
              tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  if (h.record_status !== "A") {
                    h.record_status = "E";
                  }
                  h.order_error = null;
                  let neworderkeys = h.new_order_payload_keys;
                  let consolevara = neworderkeys.filter((x) => {
                    return x.Order_id === controlMetaData.ID_Level[4];
                  })[0];
                  consolevara.key_value = controlMetaData.updating_value;
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;

        case "LOCATION":
          state.integrationManagement.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              let tenants = j.tenants;
              tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  let locations = h.locations;
                  locations
                    .filter((g) => g.Loc_id === controlMetaData.ID_Level[2])
                    .map((i) => {
                      if (!i.isLocationAdded) {
                        i.record_status = "E";
                      }
                      i.changed_ind = true;
                      switch (controlMetaData.updating_key) {
                        case "address_line":
                          i.lineError = "";
                          i.address.line1 = controlMetaData.updating_value;
                          break;
                        case "address_city":
                          i.cityError = "";
                          i.address.city = controlMetaData.updating_value;
                          break;
                        case "address_state":
                          i.stateError = "";
                          i.address.state = controlMetaData.updating_value;
                          break;
                        case "address_country":
                          i.countryError = "";
                          i.address.country = controlMetaData.updating_value;
                          break;
                        case "address_postal":
                          i.postalError = "";
                          i.address.postal_code =
                            controlMetaData.updating_value;
                          break;
                        default:
                          i.nameError = "";
                          i[controlMetaData.updating_key] =
                            controlMetaData.updating_value;
                          break;
                      }
                    });
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        case "READER":
          state.integrationManagement.listOfIntegrations
            .filter((m) => m.Int_id === controlMetaData.ID_Level[0])
            .map((j) => {
              let tenants = j.tenants;
              tenants
                .filter((a) => a.Tnt_id === controlMetaData.ID_Level[1])
                .map((h) => {
                  let locations = h.locations;
                  locations
                    .filter((g) => g.Loc_id === controlMetaData.ID_Level[2])
                    .map((i) => {
                      let readers = i.readers;
                      readers
                        .filter((l) => l.Rdr_id === controlMetaData.ID_Level[3])
                        .map((k) => {
                          k.changed_ind = true;
                          k[controlMetaData.updating_key] =
                            controlMetaData.updating_value;
                        });
                    });
                });
            });
          getCurrentEditingStatus(selInt(state.integrationManagement));
          break;
        default:
          break;
      }
    },
    addapicallreducer: (state, action) => {
      const newApiKey = {
        ...TemplateApi,
      };
      newApiKey.Api_id = generateNewID(state, "API");
      selTnt(state.integrationManagement).api_callback_subscriptions.push(
        newApiKey
      );
    },
    addauthkeyreducer: (state, action) => {
      const newAuthKey = {
        ...TemplateAuth,
      };
      newAuthKey.Auth_id = generateNewID(state, "AUTH");
      selTnt(state.integrationManagement).new_auth_keys?.push(newAuthKey);
    },
    deleteauthkeyreducer: (state, action) => {
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.auth_keys = [
          ...tenantToUpdate.auth_keys.filter(
            (auth) => auth.Auth_id !== action.payload
          ),
        ];
        tenantToUpdate.record_status = "E";
        tenantToUpdate.changed_ind = true;
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    closeauthkeyreducer: (state, action) => {
      let tenant = selTnt(state.integrationManagement);
      if (tenant) {
        tenant.new_auth_keys = tenant.new_auth_keys.filter(
          (ekey) => ekey.Auth_id !== action.payload
        );
        if (tenant.new_auth_keys.length < 0) {
          tenant.record_status = "U";
          tenant.changed_ind = false;
        }
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    closeapiformreducer: (state, action) => {
      let tenant = selTnt(state.integrationManagement);
      if (tenant) {
        tenant.api_callback_subscriptions =
          tenant.api_callback_subscriptions.filter(
            (api) => api.Api_id !== action.payload.Api_id
          );
        tenant.record_status = "E";
        tenant.changed_ind = true;
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    addorderpayloadreducer: (state, action) => {
      const newOrderKey = {
        ...TemplateOrder,
      };
      newOrderKey.Order_id = generateNewID(state, "ORDER");
      selTnt(state.integrationManagement).new_order_payload_keys.push(
        newOrderKey
      );
    },
    deleteorderkeyreducer: (state, action) => {
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.order_payload_keys = [
          ...tenantToUpdate.order_payload_keys.filter(
            (auth) => auth.Order_id !== action.payload
          ),
        ];
        tenantToUpdate.record_status = "E";
        tenantToUpdate.changed_ind = true;
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    closeorderkeyreducer: (state, action) => {
      let tenant = selTnt(state.integrationManagement);
      if (tenant) {
        tenant.new_order_payload_keys = tenant.new_order_payload_keys.filter(
          (ekey) => ekey.Order_id !== action.payload
        );
        if (tenant.new_order_payload_keys.length < 0) {
          tenant.record_status = "U";
          tenant.changed_ind = false;
        }
      }
      getCurrentEditingStatus(selInt(state.integrationManagement));
    },
    addIntegration: (state, action) => {
      const newIntegration = {
        ...TemplateIntegration,
        isEditable: true,
        record_status: "A",
        changed_ind: true,
      };
      newIntegration.Int_id = generateNewID(state, "INT");
      newIntegration.tenants = [];
      newIntegration.stripe_supported_payment_methods =
        getSupportedPaymentModes(allPaymentModes, nonSupportedModes);
      newIntegration.braintree_supported_payment_methods =
        allBraintreePaymentModes;
      state.integrationManagement.selectedIntegration_id =
        newIntegration.Int_id;
      state.integrationManagement.listOfIntegrations.push(newIntegration);
    },
    addTenant: (state, action) => {
      const newTenant = {
        ...TemplateTenant,
        isEditable: true,
        record_status: "A",
        changed_ind: true,
      };
      newTenant.Tnt_id = generateNewID(state, "TNT");
      newTenant.stripe_supported_payment_methods = getSupportedPaymentModes(
        allPaymentModes,
        nonSupportedModes
      );
      newTenant.braintree_supported_payment_methods = allBraintreePaymentModes;
      selInt(state.integrationManagement).selectedTenant_id = newTenant.Tnt_id;
      selInt(state.integrationManagement).tenants.push(newTenant);
      getCurrentEditingStatus(selInt(state.integrationManagement));
    },
    addLocation: (state, action) => {
      const newLocation = {
        ...TemplateLocation,
        isExpanded: true,
        toggleToEdit: false,
        isEditable: true,
        isLocationAdded: true,
        isLocationEdited: false,
        record_status: "A",
        changed_ind: true,
      };
      newLocation.Loc_id = generateNewID(state, "LOC");
      selTnt(state.integrationManagement).selectedLocation_id =
        newLocation.Loc_id;
      selTnt(state.integrationManagement).changed_ind = true;
      selTnt(state.integrationManagement).locations.push(newLocation);
      getCurrentEditingStatus(selInt(state.integrationManagement));
    },
    addReader: (state, action) => {
      const newReader = JSON.parse(JSON.stringify(TemplateReader));
      newReader.Rdr_id = generateNewID(state, "RDR");
      newReader.record_status = "A";
      newReader.changed_ind = true;
      newReader.isEditable = true;
      let loc = selCurrentLoc(state.integrationManagement, action.payload);
      loc.isExpanded = true;
      loc.toggleToEdit = true;
      loc.record_status = "E";
      loc.changed_ind = true;
      getCurrentEditingStatus(selInt(state.integrationManagement));
      loc.readers.push(newReader);
    },
    closeIntegrationCard: (state, action) => {
      const integration = action.payload;
      if (integration) {
        state.integrationManagement.listOfIntegrations = [
          ...state.integrationManagement.listOfIntegrations.filter(
            (int) => int.Int_id !== action.payload.Int_id
          ),
        ];
      }
    },
    closeTenantCard: (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) => code.integration_code === action.payload.integration
        );
      if (integrationToUpdate) {
        integrationToUpdate[0].tenants = integrationToUpdate[0].tenants.filter(
          (tenant) => tenant.Tnt_id !== action.payload.tenant.Tnt_id
        );
      }
      getCurrentEditingStatus(selInt(state.integrationManagement));
    },
    closeLocationCard: (state, action) => {
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.locations = [
          ...tenantToUpdate.locations.filter(
            (loc) => loc.Loc_id !== action.payload
          ),
        ];
        tenantToUpdate.locations.map((e) => {
          if (e.record_status !== "A" || e.record_status !== "E") {
            tenantToUpdate.record_status = "U";
            tenantToUpdate.changed_ind = false;
          }
        });
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    closeReaderCard: (state, action) => {
      let loc = selCurrentLoc(
        state.integrationManagement,
        action.payload.locid
      );
      if (loc) {
        loc.readers = loc.readers.filter(
          (reader) => reader.Rdr_id !== action.payload.rdrid
        );
        // loc.changed_ind = false;
        //
        loc.readers.forEach((e) => {
          if (e.record_status !== "A") {
            loc.changed_ind = false;
          }
        });
        loc.record_status = "U";
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
    },
    enableEditing: (state, action) => {
      selInt(state.integrationManagement).isEditable = true;
    },
    enableEditingTenant: (state, action) => {
      selTnt(state.integrationManagement).isEditable = true;
    },
    enableEditingLocation: (state, action) => {
      let loc = selCurrentLoc(state.integrationManagement, action.payload);
      loc.isEditable = true;
      loc.isExpanded = true;
      loc.toggleToEdit = false;
    },
    toggleViewLocationReader: (state, action) => {
      let loc = selCurrentLoc(state.integrationManagement, action.payload);
      loc.isExpanded = true;
      loc.toggleToEdit = !loc.toggleToEdit;
    },
    closeLocationAccordion: (state, action) => {
      let loc = selCurrentLoc(state.integrationManagement, action.payload);
      loc.isExpanded = !loc.isExpanded;
    },
    triggerDeleteError: (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isModalOpen = true;
    },
    setTenantPage: (state, action) => {
      state.pagination.tenantsPage = action.payload;
    },
    setLocationPage: (state, action) => {
      state.pagination.locationsPage = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(loadAdminConfig.fulfilled, (state, action) => {
      state.configVariables.aws_region =
        action.payload.user_pool?.split("_")[0];
      state.configVariables.user_pool = action.payload.user_pool;
      state.configVariables.client_id = action.payload.client_id;
      state.configVariables.configLoaded = true;
    });
    builder.addCase(cognitoLogin.fulfilled, (state, action) => {
      if (action.payload.hasOwnProperty("accessToken")) {
        state.accessTokens.accessToken = action.payload.accessToken.jwtToken;
        state.accessTokens.refreshToken = action.payload.refreshToken.token;
        if (window.logRocketObj) {
          window.logRocketObj.identify(state.userCredentials.username || "");
        }
      }
    });
    builder.addCase(cognitoLogin.rejected, (state, action) => {
      const { code, message } = action.payload.value;
      let error = { ...alertObject(action.payload.scenario) };
      if (code) {
        error.code = code;
        error.main_message_text = message;
      }
      state.alertAndErrors.currentAlert = error;
      state.errorHelperTexts.emailError = false;
      state.errorHelperTexts.passwordError = false;
      state.errorHelperTexts.emailHelperText = "";
      state.errorHelperTexts.passWordHelperText = "";
      state.errorHelperTexts.canLogin = false;
      state.isModalOpen = true;
    });
    builder.addCase(getCode.fulfilled, (state, action) => {
      state.userCredentials.codeGenerated = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;

      state.isModalOpen = true;
    });
    builder.addCase(getCode.rejected, (state, action) => {
      const { code, message } = action.payload.value;
      let error = { ...alertObject(action.payload.scenario) };

      if (code) {
        error.code = code;
        error.main_message_text = message;
      }

      state.alertAndErrors.currentAlert = error;
      state.isModalOpen = true;
    });
    builder.addCase(setNewPassword.fulfilled, (state, action) => {
      if (action.payload === "SUCCESS") {
        state.userCredentials.isPasswordUpdated = true;
      }
    });
    builder.addCase(setNewPassword.rejected, (state, action) => {
      const { code, message } = action.payload.value;
      let error = { ...alertObject(action.payload.scenario) };

      state.userCredentials.isPasswordUpdated = false;
      state.userCredentials.username = "";
      state.userCredentials.newPassword = "";
      state.userCredentials.codeGenerated = false;
      state.userCredentials.code = "";

      if (code) {
        error.code = code;
        error.main_message_text = message;
      }

      state.alertAndErrors.currentAlert = error;
      state.isModalOpen = true;
    });

    builder.addCase(checkUserSession.fulfilled, (state, action) => {
      // NOTE - checkUserSession returns a new accessToken if previous one was expired.
      // If the previous token was expired, we need to ask user to re-login
      state.accessTokens.accessToken =
        action.payload.value.accessToken.jwtToken ===
        action.payload.currentToken
          ? action.payload.value.accessToken.jwtToken
          : "";
    });

    builder.addCase(checkUserSession.rejected, (state, action) => {
      state.accessTokens.accessToken = action.payload.value;
      state.accessTokens.refreshToken = action.payload.value;
    });

    builder.addCase(addIntegrationApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code === action.payload.value.integration_code
        );

      if (integrationToUpdate) {
        integrationToUpdate[0].isEditable = false;
        integrationToUpdate[0].Int_id =
          state.integrationManagement.selectedIntegration_id;
        integrationToUpdate[0].record_status = "U";
        integrationToUpdate[0].service_charges =
          action.payload.value.service_charges;
        integrationToUpdate[0].service_charges_percentage =
          action.payload.value.service_charges_percentage;
        integrationToUpdate[0].service_charges_error = null;
        integrationToUpdate[0].code_error = null;
        integrationToUpdate[0].changed_ind = false;
        integrationToUpdate[0].disable_edit_list = JPJS(["integration_code"]);
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
    });

    builder.addCase(addIntegrationApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
      let int = selInt(state.integrationManagement);
      int.code_error = action.payload.value.data.detail;
    });

    builder.addCase(addTenantApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code === action.payload.value.data.integration_code
        );
      const tenantToUpdate = integrationToUpdate[0].tenants.filter(
        (a) => a.tenant_code === action.payload.value.data.tenant_code
      )[0];
      action.payload.value.data.api_callback_subscriptions.forEach((api) => {
        api.Api_id = generateNewID(state, "API");
        api.api_error = null;
      });
      action.payload.value.data.auth_keys.forEach((auth) => {
        auth.Auth_id = generateNewID(state, "AUTH");
        auth.key_value = JSON.stringify(auth.key_value);
        auth.auth_error = null;
      });
      action.payload.value.data.order_payload_keys.forEach((order) => {
        order.Order_id = generateNewID(state, "ORDER");
        order.key_value = JSON.stringify(order.key_value);
        order.order_error = null;
      });

      if (tenantToUpdate) {
        tenantToUpdate.stripe_error = null;
        tenantToUpdate.public_stripe_error = null;
        tenantToUpdate.private_stripe_error = null;
        tenantToUpdate.order_error = null;
        tenantToUpdate.status_error = null;
        tenantToUpdate.auth_error = null;
        tenantToUpdate.code_error = null;
        tenantToUpdate.isEditable = false;
        tenantToUpdate.record_status = "U";
        tenantToUpdate.new_order_payload_keys = [];
        tenantToUpdate.new_auth_keys = [];
        tenantToUpdate.changed_ind = false;
        if (
          action.payload.value.data.payment_gateway_key_paths.stripe !== null
        ) {
          tenantToUpdate.payment_gateway_key_paths.stripe.private_key =
            action.payload.value.data.payment_gateway_key_paths.stripe.private_key;
          tenantToUpdate.payment_gateway_key_paths.stripe.public_key =
            action.payload.value.data.payment_gateway_key_paths.stripe.public_key;
        } else {
          if (
            tenantToUpdate.payment_gateway_key_paths.stripe?.isPrivateKeyChanged
          ) {
            tenantToUpdate.payment_gateway_key_paths.stripe.private_key = "";
            tenantToUpdate.payment_gateway_key_paths.stripe.isPrivateKeyChanged = false;
          }
          if (
            tenantToUpdate.payment_gateway_key_paths.stripe?.isPublicKeyChanged
          ) {
            tenantToUpdate.payment_gateway_key_paths.stripe.public_key = "";
            tenantToUpdate.payment_gateway_key_paths.stripe.isPublicKeyChanged = false;
          }
        }
        tenantToUpdate.service_charges_percentage =
          action.payload.value.data.service_charges_percentage ||
          integrationToUpdate[0].service_charges_percentage;
        tenantToUpdate.auth_keys = action.payload.value.data.auth_keys;
        tenantToUpdate.order_payload_keys =
          action.payload.value.data.order_payload_keys;
        tenantToUpdate.disable_edit_list = JPJS([
          "tenant_code",
          "public_status_payload_keys",
        ]);
        tenantToUpdate.public_status_payload_keys = JSON.stringify(
          JPJS(action.payload.value.data.status_payload_key)
        );
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }

      const errorTenant = action.payload.value.error;
      if (errorTenant?.detail.length > 0) {
        errorTenant.detail.forEach((err) => {
          switch (err.loc[0]) {
            case "payment_gateway_keys":
              tenantToUpdate.stripe_error = err.msg;
              break;
            case "api_callback_urls":
              let newarrayAPI =
                tenantToUpdate.api_callback_subscriptions.filter((api) => {
                  let words = err.msg.split(" ");
                  return words.includes(api.callback_url);
                });
              newarrayAPI[0].api_error = err.msg;

              break;
          }
        });
      }
      integrationToUpdate.selectedTenant_id = tenantToUpdate.Tnt_id;
      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
    });
    builder.addCase(addTenantApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
      let selectedTenant = selTnt(state.integrationManagement);
      let errorMsg = action.payload.value.data.detail;
      selectedTenant.record_status = "A";
      selectedTenant.changed_ind = true;
      switch (action.payload.value.status) {
        case 400:
          if (errorMsg.includes("OrderPayload")) {
            selectedTenant.order_error = errorMsg;
          } else if (errorMsg.includes("Auth")) {
            selectedTenant.auth_error = errorMsg;
          } else {
            state.alertAndErrors.currentAlert = {
              main_message_text: errorMsg,
            };
            state.isSaveModalOpen = false;
            state.isModalOpen = true;
          }
          break;
        case 422:
          errorMsg?.forEach((err) => {
            if (
              err.loc.some((e) => {
                return e === "api_callback_urls"; // Added "return" here
              })
            ) {
              selectedTenant.api_callback_subscriptions[
                err.loc[err.loc.length - 1]
              ].api_error = err.msg;
            } else if (
              err.loc.some((e) => {
                return e === "public_key"; // Added "return" here
              })
            ) {
              selectedTenant.public_stripe_error = err.msg;
            } else if (
              err.loc.some((e) => {
                return e === "private_key"; // Added "return" here
              })
            ) {
              selectedTenant.private_stripe_error = err.msg;
            } else if (
              err.loc.some((e) => {
                return e === "tenant_code"; // Added "return" here
              })
            ) {
              selectedTenant.code_error = err.msg;
            } else {
              state.alertAndErrors.currentAlert = {
                main_message_text: err.msg,
              };
              state.isSaveModalOpen = false;
              state.isModalOpen = true;
            }
          });
          break;
        default:
          break;
      }
    });

    builder.addCase(getAllIntegrationApi.fulfilled, (state, action) => {
      let integrations = action.payload;
      // state.integrationManagement.listOfIntegrations =
      const supportedModes = getSupportedPaymentModes(
        allPaymentModes,
        nonSupportedModes
      );

      integrations.forEach((int) => {
        if (
          !state.integrationManagement.listOfIntegrations.some(
            (list) => list.integration_code === int.integration_code
          )
        ) {
          state.integrationManagement.listOfIntegrations.push(int);
          if (state.integrationManagement.listOfIntegrations.length > 0) {
            console.log(JPJS(int));
            int.Int_id = generateNewID(state, "INT");
            int.isEditable = false;
            int.tenants = [];
            int.record_status = "U";
            int.changed_ind = false;
            int.disable_edit_list = JPJS(["integration_code"]);
            int.service_charges_error = null;
            int.code_error = null;
            int.stripe_supported_payment_methods = supportedModes;
            int.braintree_supported_payment_methods = allBraintreePaymentModes;

            // TODO : Use enabled_payment_method_gateways to handle more methods inside a mode
            int.enabled_payment_method_gateways = {
              online: {
                card: int.enabled_payment_method_gateways?.online?.card || "",
              },
              in_person: {
                card_present:
                  int.enabled_payment_method_gateways?.in_person
                    ?.card_present || "",
              },
            };
            int.enabled_payment_methods = {
              online:
                int.enabled_payment_method_gateways?.online?.card ===
                  "stripe" ||
                int.enabled_payment_methods?.online ||
                int.enabled_payment_gateways.includes("stripe")
                  ? Object.keys(
                      int.enabled_payment_method_gateways?.online
                    )?.map((keyar) => ({
                      code: keyar,
                      display: supportedModes.online.find(
                        (mode) => mode.code === keyar
                      ).display,
                    }))
                  : null,
              in_person:
                int.enabled_payment_method_gateways?.in_person?.card_present ===
                  "stripe" ||
                int.enabled_payment_methods?.in_person ||
                int.enabled_payment_gateways.includes("stripe")
                  ? Object.keys(
                      int.enabled_payment_method_gateways?.in_person
                    )?.map((modeCode) => ({
                      code: modeCode,
                      display: supportedModes.in_person.find(
                        (mode) => mode.code === modeCode
                      ).display,
                    }))
                  : null,
            };
            int.braintree_enabled_payment_methods = {
              online:
                int.enabled_payment_method_gateways?.online?.card ===
                  "braintree" ||
                int.enabled_payment_methods?.online ||
                int.enabled_payment_gateways.includes("braintree")
                  ? JPJS(
                      allBraintreePaymentModes.online?.filter(
                        (keyar) => keyar.code === "card"
                      )
                    )
                  : [],
            };
          }
        }
      });
      state.integrationManagement.selectedIntegration_id =
        state.integrationManagement.listOfIntegrations[0]?.Int_id;
    });

    builder.addCase(getAllIntegrationApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(updateIntegrationApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code === action.payload.value.integration_code
        );

      if (integrationToUpdate) {
        integrationToUpdate[0].isEditable = false;
        integrationToUpdate[0].Int_id =
          state.integrationManagement.selectedIntegration_id;
        integrationToUpdate[0].record_status = "U";
        integrationToUpdate[0].service_charges =
          action.payload.value.service_charges;
        integrationToUpdate[0].service_charges_percentage =
          action.payload.value.service_charges_percentage;
        integrationToUpdate[0].service_charges_error = null;
        integrationToUpdate[0].code_error = null;
        integrationToUpdate[0].changed_ind = false;
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }

      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
    });

    builder.addCase(updateIntegrationApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });
    builder.addCase(addReaderApi.fulfilled, (state, action) => {
      let reader = action.payload.data.value;

      let locid = action.payload.data.location;
      const locationToUpdate = selTnt(
        state.integrationManagement
      ).locations.find((location) => location.provider_location_id === locid);
      locationToUpdate.changed_ind = false;

      if (locationToUpdate) {
        const readerToUpdate = locationToUpdate.readers.filter((x) => {
          return x.Rdr_id === action.payload.data.rdrid;
        })[0];
        readerToUpdate.isEditable = false;
        readerToUpdate.record_status = "U";
        readerToUpdate.changed_ind = false;
        readerToUpdate.reader_id = reader.reader_id;
        readerToUpdate.process_payment_at_backend =
          reader.process_payment_at_backend;
      }

      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
    });

    builder.addCase(addReaderApi.rejected, (state, action) => {
      if (action.payload.error) {
        let error = { ...alertObject(action.payload.scenario) };
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      } else {
        let currentLocation = selCurrentLoc(
          state.integrationManagement,
          action.payload.locid
        );
        const readernotadded = currentLocation.readers.filter((rdr) => {
          return rdr.Rdr_id === action.payload.reader.Rdr_id;
        })[0];
        let errorMsg = action.payload.value.data.detail;
        switch (action.payload.value.status) {
          case 400:
            if (errorMsg.toLowerCase().includes("label")) {
              readernotadded.labelerror = errorMsg;
            } else {
              state.alertAndErrors.currentAlert = {
                main_message_text: errorMsg,
              };
              state.isSaveModalOpen = false;
              state.isModalOpen = true;
            }
            break;
          default:
            break;
        }
      }
    });
    builder.addCase(getAllTenantsApi.rejected, (state, action) => {
      if (action.payload.error) {
        let error = { ...alertObject(action.payload.scenario) };
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      } else {
        let errorMsg = action.payload.value.data.detail;
        state.alertAndErrors.currentAlert = {
          main_message_text: errorMsg,
        };
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      }
    });
    builder.addCase(getAllTenantsApi.fulfilled, (state, action) => {
      let integration = selInt(state.integrationManagement);
      let tenantObject = JPJS(action.payload.data);
      const supportedModes = getSupportedPaymentModes(
        allPaymentModes,
        nonSupportedModes
      );
      tenantObject.forEach((tenant) => {
        if (
          !integration.tenants.some(
            (tnt) => tnt.tenant_code === tenant.tenant_code
          )
        ) {
          integration.tenants.push(tenant);
          if (integration.tenants.length !== 0) {
            tenant.Tnt_id = generateNewID(state, "TNT");
            tenant.isEditable = false;
            tenant.record_status = "U";
            tenant.changed_ind = false;
            tenant.disable_edit_list = JPJS([
              "tenant_code",
              "public_status_payload_keys",
            ]);
            tenant.service_charges =
              tenant.service_charges === null
                ? integration.service_charges
                : tenant.service_charges;
            tenant.service_charges_percentage =
              tenant.service_charges_percentage === null
                ? integration.service_charges_percentage
                : tenant.service_charges_percentage;
            delete tenant.integration_code;
            tenant.enabled_payment_gateways =
              tenant.enabled_payment_gateways || [];
            tenant.stripe_supported_payment_methods = supportedModes;
            tenant.braintree_supported_payment_methods =
              allBraintreePaymentModes;
            // TODO : Use enabled_payment_method_gateways to handle more methods inside a mode
            tenant.enabled_payment_method_gateways = {
              online: {
                card:
                  tenant.enabled_payment_method_gateways?.online?.card || "",
              },
              in_person: {
                card_present:
                  tenant.enabled_payment_method_gateways?.in_person
                    ?.card_present || "",
              },
            };
            tenant.enabled_payment_methods = {
              online:
                tenant.enabled_payment_method_gateways?.online?.card ===
                  "stripe" ||
                tenant.enabled_payment_methods?.online ||
                tenant.enabled_payment_gateways.includes("stripe")
                  ? Object.keys(
                      tenant.enabled_payment_method_gateways?.online
                    )?.map((keyar) => ({
                      code: keyar,
                      display: supportedModes.online.find(
                        (mode) => mode.code === keyar
                      ).display,
                    }))
                  : [],
              in_person:
                tenant.enabled_payment_method_gateways?.in_person
                  ?.card_present === "stripe" ||
                tenant.enabled_payment_methods?.in_person ||
                tenant.enabled_payment_gateways.includes("stripe")
                  ? Object.keys(
                      tenant.enabled_payment_method_gateways?.in_person
                    )?.map((modeCode) => ({
                      code: modeCode,
                      display: supportedModes.in_person.find(
                        (mode) => mode.code === modeCode
                      ).display,
                    }))
                  : [],
            };
            tenant.braintree_enabled_payment_methods = {
              online:
                tenant.enabled_payment_method_gateways?.online?.card ===
                  "braintree" ||
                tenant.enabled_payment_methods?.online ||
                tenant.enabled_payment_gateways.includes("braintree")
                  ? JPJS(
                      allBraintreePaymentModes.online?.filter(
                        (keyar) => keyar.code === "card"
                      )
                    )
                  : [],
            };
            tenant.service_charges_error = null;
            tenant.public_status_payload_keys = JSON.stringify(
              JPJS(action.payload.status_payload_key)
            );
            tenant.new_auth_keys = [];
            tenant.auth_keys.forEach((auth) => {
              auth.Auth_id = generateNewID(state, "AUTH");
              auth.id = auth.id;
              auth.key_value = auth.key_value;
            });
            tenant.new_order_payload_keys = [];
            tenant.order_payload_keys.forEach((order) => {
              order.Order_id = generateNewID(state, "ORDER");
              order.id = order.id;
              order.key_value = order.key_value;
            });
            if (tenant.payment_gateway_key_paths.stripe) {
              tenant.payment_gateway_key_paths.stripe.isPublicKeyChanged = false;
              tenant.payment_gateway_key_paths.stripe.isPrivateKeyChanged = false;
            }
            tenant.api_callback_subscriptions.forEach((api) => {
              api.Api_id = generateNewID(state, "API");
            });
            tenant.locations.forEach((loc) => {
              loc.Loc_id = generateNewID(state, "LOC");
              loc.isEditable = true;
              loc.record_status = "U";
              loc.changed_ind = false;
              loc.isLocationAdded = false;
              loc.isLocationEdited = false;
              loc.readers = [];
            });
            tenant.selectedLocation_id = tenant?.locations[0]?.Loc_id || "";
          }
        }
      });
      integration.selectedTenant_id = integration.tenants[0]?.Tnt_id;
    });

    builder.addCase(getAllReadersApi.fulfilled, (state, action) => {
      let locid = action.payload?.location;
      let readerobj = action.payload.value;
      const locationToUpdate = selTnt(
        state.integrationManagement
      ).locations.find((location) => location.provider_location_id === locid);

      if (locationToUpdate) {
        readerobj.forEach((x) => {
          if (
            !locationToUpdate.readers.some((h) => h.reader_id === x.reader_id)
          ) {
            locationToUpdate.readers.push(x);
            x.Rdr_id = generateNewID(state, "RDR");
            x.reader_label = x.label;
            x.process_payment_at_backend = x.process_payment_at_backend;
            x.isEditable = false;
            x.record_status = "U";
            x.changed_ind = false;
          }
        });
      }
    });

    builder.addCase(getAllReadersApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(deleteReaderApi.fulfilled, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      let locid = action.payload.data.loc;
      const locationToUpdate = selTnt(
        state.integrationManagement
      ).locations.find((location) => location.provider_location_id === locid);

      if (locationToUpdate) {
        locationToUpdate.readers = locationToUpdate.readers.filter(
          (reader) => reader.reader_id !== action.payload.data.reader
        );
      }
    });

    builder.addCase(deleteReaderApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(deleteLocationApi.fulfilled, (state, action) => {
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.locations = [
          ...tenantToUpdate.locations.filter(
            (loc) => loc.provider_location_id !== action.payload.value
          ),
        ];
      }
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
    });

    builder.addCase(deleteLocationApi.rejected, (state, action) => {
      const message = action.payload.value;
      let error = { ...alertObject(action.payload.scenario) };
      if (error.code) {
        error.main_message_text = message;
      }
      state.alertAndErrors.currentAlert = error;
      state.isModalOpen = true;
      state.isSaveModalOpen = false;
    });

    builder.addCase(deleteTenantApi.fulfilled, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      let intid = action.payload.data.integration;
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) => code.integration_code === intid
        );
      if (integrationToUpdate) {
        integrationToUpdate[0].tenants = integrationToUpdate[0].tenants.filter(
          (tenant) =>
            tenant.tenant_code !== action.payload.data.tenant.tenant_code
        );
        integrationToUpdate[0].changed_ind =
          integrationToUpdate[0].record_status === "E" ? true : false;
      }
    });

    builder.addCase(deleteTenantApi.rejected, (state, action) => {
      let newval = action.payload.value.data.detail;
      state.alertAndErrors.currentAlert = {
        main_message_text: newval,
      };
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(deleteIntegrationApi.fulfilled, (state, action) => {
      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      const integration = action.payload.data.integration;
      if (integration) {
        state.integrationManagement.listOfIntegrations = JPJS(
          state.integrationManagement.listOfIntegrations.filter(
            (int) => int.Int_id !== integration.Int_id
          )
        );
      }
      state.integrationManagement.selectedIntegration_id = null;
    });

    builder.addCase(deleteIntegrationApi.rejected, (state, action) => {
      let newval = action.payload.value.data.detail;
      state.alertAndErrors.currentAlert = {
        main_message_text: newval,
      };
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(updateTenantApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code === action.payload.value.data.integration_code
        );
      const tenantToUpdate = integrationToUpdate[0].tenants.filter(
        (a) => a.tenant_code === action.payload.value.data.tenant_code
      )[0];
      action.payload.value.data.api_callback_subscriptions.forEach((api) => {
        api.Api_id = generateNewID(state, "API");
        api.api_error = null;
      });
      action.payload.value.data.auth_keys.forEach((auth) => {
        auth.Auth_id = generateNewID(state, "AUTH");
        auth.key_value = JSON.stringify(auth.key_value);
        auth.auth_error = null;
      });
      action.payload.value.data.order_payload_keys.forEach((order) => {
        order.Order_id = generateNewID(state, "ORDER");
        order.key_value = JSON.stringify(order.key_value);
        order.order_error = null;
      });

      if (tenantToUpdate) {
        tenantToUpdate.stripe_error = null;
        tenantToUpdate.public_stripe_error = null;
        tenantToUpdate.private_stripe_error = null;
        tenantToUpdate.order_error = null;
        tenantToUpdate.status_error = null;
        tenantToUpdate.auth_error = null;
        tenantToUpdate.code_error = null;
        tenantToUpdate.isEditable = false;
        tenantToUpdate.record_status = "U";
        tenantToUpdate.new_auth_keys = [];
        tenantToUpdate.new_order_payload_keys = [];
        tenantToUpdate.changed_ind = false;
        tenantToUpdate.public_status_payload_keys = JSON.stringify(
          JPJS(action.payload.value.data.status_payload_key)
        );
        if (
          action.payload.value.data.payment_gateway_key_paths.stripe === null
        ) {
          // To handle the scenario where one of the keys is sent in PATCH but not returned in the response
          // Both the keys should be sent otherwise the PATCH request body sends null value
          if (
            tenantToUpdate.payment_gateway_key_paths.stripe?.isPrivateKeyChanged
          ) {
            tenantToUpdate.payment_gateway_key_paths.stripe.private_key = "";
            tenantToUpdate.payment_gateway_key_paths.stripe.isPrivateKeyChanged = false;
          }
          if (
            tenantToUpdate.payment_gateway_key_paths.stripe?.isPublicKeyChanged
          ) {
            tenantToUpdate.payment_gateway_key_paths.stripe.public_key = "";
            tenantToUpdate.payment_gateway_key_paths.stripe.isPublicKeyChanged = false;
          }
        } else {
          tenantToUpdate.payment_gateway_key_paths.stripe.private_key =
            action.payload.value.data.payment_gateway_key_paths.stripe.private_key;
          tenantToUpdate.payment_gateway_key_paths.stripe.public_key =
            action.payload.value.data.payment_gateway_key_paths.stripe.public_key;
        }
        tenantToUpdate.service_charges_percentage =
          action.payload.value.data.service_charges_percentage ||
          integrationToUpdate[0].service_charges_percentage;
        tenantToUpdate.auth_keys = action.payload.value.data.auth_keys;
        tenantToUpdate.order_payload_keys =
          action.payload.value.data.order_payload_keys;
        tenantToUpdate.api_callback_subscriptions =
          action.payload.value.data.api_callback_subscriptions;
        tenantToUpdate.disable_edit_list = JPJS([
          "tenant_code",
          "public_status_payload_keys",
        ]);
        getCurrentEditingStatus(selInt(state.integrationManagement));
      }
      const errorTenant = action.payload.value.error;
      if (errorTenant?.detail.length > 0) {
        errorTenant.detail.forEach((err) => {
          switch (err.loc[0]) {
            case "payment_gateway_keys":
              tenantToUpdate.stripe_error = err.msg.concat(
                " Please Retry with correct keys"
              );
              break;
            case "api_callback_urls":
              let newarrayAPI =
                tenantToUpdate.api_callback_subscriptions.filter((api) => {
                  let words = err.msg.split(" ");
                  return words.includes(api.callback_url);
                });
              newarrayAPI[0].api_error = err.msg;

              break;
          }
        });
      }

      integrationToUpdate.selectedTenant_id = tenantToUpdate.Tnt_id;

      state.isSaveModalOpen = true;
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
    });

    builder.addCase(updateTenantApi.rejected, (state, action) => {
      if (action.payload.value?.data) {
        let errorMsg = action.payload.value.data.detail;
        let selectedTenant = selTnt(state.integrationManagement);
        let error = { ...alertObject(action.payload.scenario) };
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
        switch (action.payload.value.status) {
          case 400:
            if (errorMsg.includes("OrderPayload")) {
              selectedTenant.order_error = errorMsg;
            } else if (errorMsg.includes("Auth")) {
              selectedTenant.auth_error = errorMsg;
            } else {
              state.alertAndErrors.currentAlert = {
                main_message_text: errorMsg,
              };
              state.isSaveModalOpen = false;
              state.isModalOpen = true;
            }
            break;
          case 422:
            errorMsg?.forEach((err) => {
              if (
                err.loc.some((e) => {
                  return e === "api_callback_urls";
                })
              ) {
                selectedTenant.api_callback_subscriptions[
                  err.loc[err.loc.length - 1]
                ].api_error = err.msg;
              } else if (
                err.loc.some((e) => {
                  return e === "public_key";
                })
              ) {
                selectedTenant.public_stripe_error = err.msg;
              } else if (
                err.loc.some((e) => {
                  return e === "private_key";
                })
              ) {
                selectedTenant.private_stripe_error = err.msg;
              } else if (
                err.loc.some((e) => {
                  return e === "tenant_code";
                })
              ) {
                selectedTenant.code_error = err.msg;
              } else {
                state.alertAndErrors.currentAlert = {
                  main_message_text: err.msg,
                };
                state.isSaveModalOpen = false;
                state.isModalOpen = true;
              }
            });
            break;
          default:
            break;
        }
      } else {
        const { code, message } = action.payload.newval;
        let error = { ...alertObject(action.payload.scenario) };
        if (code) {
          error.code = code;
          error.main_message_text = message;
        }
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      }
    });
    builder.addCase(addLocationApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code ===
            action.payload.integration.integration_code
        );
      const tenantToUpdate = integrationToUpdate[0].tenants.filter(
        (a) => a.tenant_code === action.payload.tenant.tenant_code
      )[0];
      const locationadded = tenantToUpdate.locations.filter(
        (a) => a.Loc_id === action.payload.location.Loc_id
      )[0];
      if (locationadded) {
        locationadded.process_payment_at_backend =
          action.payload.location.process_payment_at_backend;
        locationadded.record_status = "U";
        locationadded.changed_ind = false;
        locationadded.provider_location_id =
          action.payload.resData.provider_location_id;
        locationadded.isExpanded = false;
      }
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      state.isModalOpen = false;
      getCurrentEditingStatus(selInt(state.integrationManagement));
    });

    builder.addCase(addLocationApi.rejected, (state, action) => {
      let tenant = selTnt(state.integrationManagement);
      const locationNotAdded = tenant.locations.filter((loc) => {
        return loc.Loc_id === action.payload.location.Loc_id;
      })[0];
      if (action.payload.value.data) {
        let errorMsg = action.payload.value.data.detail;
        switch (action.payload.value.status) {
          case 400:
            if (errorMsg.toLowerCase().includes("display name")) {
              locationNotAdded.nameError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("state")) {
              locationNotAdded.stateError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("country")) {
              locationNotAdded.countryError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("postal code")) {
              locationNotAdded.postalError = errorMsg;
            } else {
              state.alertAndErrors.currentAlert = {
                main_message_text: errorMsg,
              };
              state.isSaveModalOpen = false;
              state.isModalOpen = true;
            }
            break;
          case "500":
            let error = { ...alertObject(action.payload.scenario) };
            state.alertAndErrors.currentAlert = error;
            state.isSaveModalOpen = false;
            state.isModalOpen = true;
            break;
          default:
            break;
        }
      } else {
        let error = { ...alertObject(action.payload.scenario) };
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      }
    });
    builder.addCase(updateLocationApi.fulfilled, (state, action) => {
      const integrationToUpdate =
        state.integrationManagement.listOfIntegrations.filter(
          (code) =>
            code.integration_code ===
            action.payload.integration.integration_code
        );
      const tenantToUpdate = integrationToUpdate[0].tenants.filter(
        (a) => a.tenant_code === action.payload.tenant.tenant_code
      )[0];
      const locationadded = tenantToUpdate.locations.filter(
        (a) => a.Loc_id === action.payload.location.Loc_id
      )[0];
      if (locationadded) {
        locationadded.process_payment_at_backend =
          action.payload.location.process_payment_at_backend;
        locationadded.record_status = "U";
        locationadded.changed_ind = false;
        locationadded.provider_location_id =
          action.payload.resData.provider_location_id;
        locationadded.isExpanded = false;
      }
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      state.isModalOpen = false;
      getCurrentEditingStatus(selInt(state.integrationManagement));
    });

    builder.addCase(updateLocationApi.rejected, (state, action) => {
      let tenant = selTnt(state.integrationManagement);
      const locationNotAdded = tenant.locations.filter((loc) => {
        return loc.Loc_id === action.payload.location.Loc_id;
      })[0];
      if (action.payload.value.data) {
        let errorMsg = action.payload.value.data.detail;
        switch (action.payload.value.status) {
          case 400:
            if (errorMsg.toLowerCase().includes("display name")) {
              locationNotAdded.nameError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("state")) {
              locationNotAdded.stateError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("country")) {
              locationNotAdded.countryError = errorMsg;
            } else if (errorMsg.toLowerCase().includes("postal code")) {
              locationNotAdded.postalError = errorMsg;
            } else {
              state.alertAndErrors.currentAlert = {
                main_message_text: errorMsg,
              };
              state.isSaveModalOpen = false;
              state.isModalOpen = true;
            }
            break;
          case "500":
            let error = { ...alertObject(action.payload.scenario) };
            state.alertAndErrors.currentAlert = error;
            state.isSaveModalOpen = false;
            state.isModalOpen = true;
            break;
          default:
            break;
        }
      } else {
        let error = { ...alertObject(action.payload.scenario) };
        state.alertAndErrors.currentAlert = error;
        state.isSaveModalOpen = false;
        state.isModalOpen = true;
      }
    });

    builder.addCase(getSubscriptionApi.fulfilled, (state, action) => {
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.api_callback_subscriptions.forEach((api) => {
          let obj = action.payload.value.find(
            (v) => v.Endpoint === api.callback_url
          );
          api.subscription_status = obj
            ? obj.SubscriptionArn
              ? "ACTIVE"
              : "INACTIVE"
            : "UNSUBSCRIBED";
        });
      }
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      state.isModalOpen = false;
    });

    builder.addCase(getSubscriptionApi.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      const tenantToUpdate = selTnt(state.integrationManagement);
      if (tenantToUpdate) {
        tenantToUpdate.status_error =
          action.payload.value?.response?.data?.detail || error;
      }
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = true;
      state.isModalOpen = false;
    });

    builder.addCase(discardIntegration.fulfilled, (state, action) => {
      let integration = action.payload.value.find(
        (i) =>
          i.integration_code === action.payload.integration.integration_code
      );
      // state.integrationManagement.listOfIntegrations =
      const supportedModes = getSupportedPaymentModes(
        allPaymentModes,
        nonSupportedModes
      );
      let editedIntegration =
        state.integrationManagement.listOfIntegrations.find(
          (i) => i.Int_id === action.payload.integration.Int_id
        );
      if (editedIntegration) {
        editedIntegration.integration_name = integration.integration_name;
        editedIntegration.service_charges = integration.service_charges;
        editedIntegration.isEditable = false;
        editedIntegration.record_status = "U";
        editedIntegration.changed_ind = false;
        editedIntegration.disable_edit_list = JPJS(["integration_code"]);
        editedIntegration.stripe_supported_payment_methods = supportedModes;
        editedIntegration.braintree_supported_payment_methods =
          allBraintreePaymentModes;
        editedIntegration.enabled_payment_methods = {
          online: integration.enabled_payment_methods.online?.map((keyar) => ({
            code: keyar,
            display: supportedModes.online.find((mode) => mode.code === keyar)
              .display,
          })),
          in_person: integration.enabled_payment_methods.in_person?.map(
            (modeCode) => ({
              code: modeCode,
              display: supportedModes.in_person.find(
                (mode) => mode.code === modeCode
              ).display,
            })
          ),
        };
        // editedIntegration.braintree_enabled_payment_methods = {
        //   online: integration.braintree_enabled_payment_methods.online?.map(
        //     (keyar) => ({
        //       code: keyar,
        //       display: allBraintreePaymentModes.online.find(
        //         (mode) => mode.code === keyar
        //       ).display,
        //     })
        //   ),
        // };
      }
      state.integrationManagement.selectedIntegration_id =
        action.payload.integration.Int_id;
    });

    builder.addCase(discardIntegration.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(discardTenant.fulfilled, (state, action) => {
      let editedTenant = selInt(state.integrationManagement).tenants.find(
        (i) => i.Tnt_id === action.payload.tenant.Tnt_id
      );
      let tenantObject = action.payload.value.data.find(
        (i) => i.tenant_code === action.payload.tenant.tenant_code
      );
      // For auth keys, order payload keys and api callback urls
      // Either mapping using thumbprint/key_value/url and
      // checking each of the value if changed/present/absent
      // or completely clear out previous state and append new objects
      if (editedTenant && tenantObject) {
        editedTenant.display_name = tenantObject.display_name;
        editedTenant.service_charges = tenantObject.service_charges;
        editedTenant.isEditable = false;
        editedTenant.record_status = "U";
        editedTenant.changed_ind = false;
        editedTenant.disable_edit_list = JPJS([
          "tenant_code",
          "public_status_payload_keys",
        ]);
        editedTenant.service_charges =
          tenantObject.service_charges === null
            ? selInt(state.integrationManagement).service_charges
            : tenantObject.service_charges;
        editedTenant.service_charges_error = null;
        editedTenant.public_status_payload_keys = JSON.stringify(
          JPJS(action.payload.value.status_payload_key)
        );
        editedTenant.new_auth_keys = [];
        editedTenant.auth_keys = JPJS(tenantObject.auth_keys);
        editedTenant.auth_keys.forEach((auth) => {
          auth.Auth_id = generateNewID(state, "AUTH");
          auth.id = auth.id;
          auth.key_value = auth.key_value;
        });
        editedTenant.new_order_payload_keys = [];
        editedTenant.order_payload_keys = JPJS(tenantObject.order_payload_keys);
        editedTenant.order_payload_keys.forEach((order) => {
          order.Order_id = generateNewID(state, "ORDER");
          order.id = order.id;
          order.key_value = order.key_value;
        });
        if (editedTenant.payment_gateway_key_paths.stripe) {
          editedTenant.payment_gateway_key_paths.stripe.isPublicKeyChanged = false;
          editedTenant.payment_gateway_key_paths.stripe.isPrivateKeyChanged = false;
          editedTenant.payment_gateway_key_paths.stripe.public_key =
            tenantObject.payment_gateway_key_paths.stripe?.public_key;
          editedTenant.payment_gateway_key_paths.stripe.private_key =
            tenantObject.payment_gateway_key_paths.stripe?.private_key;
        }
        if (editedTenant.payment_gateway_key_paths.braintree) {
          editedTenant.payment_gateway_key_paths.braintree.isPublicKeyChanged = false;
          editedTenant.payment_gateway_key_paths.braintree.isPrivateKeyChanged = false;
          editedTenant.payment_gateway_key_paths.braintree.isMerchantIdChanged = false;
          editedTenant.payment_gateway_key_paths.braintree.public_key =
            tenantObject.payment_gateway_key_paths.braintree?.public_key;
          editedTenant.payment_gateway_key_paths.braintree.private_key =
            tenantObject.payment_gateway_key_paths.braintree?.private_key;
          editedTenant.payment_gateway_key_paths.braintree.merchant_id =
            tenantObject.payment_gateway_key_paths.braintree?.merchant_id;
        }
        editedTenant.api_callback_subscriptions = JPJS(
          tenantObject.api_callback_subscriptions
        );
        editedTenant.api_callback_subscriptions.forEach((api) => {
          api.Api_id = generateNewID(state, "API");
        });
      }
      getCurrentEditingStatus(selInt(state.integrationManagement));
    });

    builder.addCase(discardTenant.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(discardLocation.fulfilled, (state, action) => {
      let editedLocation = selInt(state.integrationManagement)
        .tenants.find((i) => i.Tnt_id === action.payload.tenant.Tnt_id)
        .locations.find((loc) => loc.Loc_id === action.payload.location.Loc_id);
      let locationObject = action.payload.value.locations.find(
        (i) =>
          i.provider_location_id ===
          action.payload.location.provider_location_id
      );

      if (editedLocation && locationObject) {
        editedLocation.display_name = locationObject.display_name;
        editedLocation.address = locationObject.address;
        editedLocation.process_payment_at_backend =
          locationObject.process_payment_at_backend;
        editedLocation.isEditable = true;
        editedLocation.record_status = "U";
        editedLocation.changed_ind = false;
        editedLocation.isLocationAdded = false;
        editedLocation.isLocationEdited = false;
      }
      getCurrentEditingStatus(selInt(state.integrationManagement));
    });

    builder.addCase(discardLocation.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.accessTokens.accessToken = null;
      state.accessTokens.refreshToken = null;
      window.location.reload();
    });

    builder.addCase(logout.rejected, (state, action) => {
      let error = { ...alertObject(action.payload.scenario) };
      state.alertAndErrors.currentAlert = action.payload.value.message || error;
      state.isSaveModalOpen = false;
      state.isModalOpen = true;
    });
  },
});

export const {
  credentialInput,
  resetForgetVariables,
  validateInput,
  UpdateUIValue,
  setSelectedIntegration,
  setSelectedTenant,
  addIntegration,
  addTenant,
  addLocation,
  addReader,
  enableEditing,
  enableEditingTenant,
  enableEditingLocation,
  toggleViewLocationReader,
  closeLocationAccordion,
  addauthkeyreducer,
  closeAlertBox,
  closeSaveAlertBox,
  triggerDeleteError,
  closeReaderCard,
  filterIntegration,
  filterTenant,
  deleteauthkeyreducer,
  closeauthkeyreducer,
  closeapiformreducer,
  closeLocationCard,
  closeIntegrationCard,
  closeTenantCard,
  addorderpayloadreducer,
  addapicallreducer,
  togglePaymentReducer,
  deleteorderkeyreducer,
  closeorderkeyreducer,
  filterLocation,
  setTenantPage,
  setLocationPage,
  setAccessToken,
  toggleTenantPaymentReducer,
} = AdminSlice.actions;

export const AdminReducer = AdminSlice.reducer;
