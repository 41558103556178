import { createSlice } from "@reduxjs/toolkit";
import { initState } from "../stateAndConstants/paymentStateAndConstants/initialState";
import {
  cancelPayment,
  checkReaderConnection,
  checkStatus,
  clearReaderDisplay,
  collectPaymentMethod,
  confirmBraintreePayment,
  confirmCardPayment,
  confirmPosPaymentBackend,
  connectReaderHandler,
  createOrderApi,
  disconnectReader,
  discoverReaders,
  getStatusDiscoverReaders,
  getStatusPayload,
  loadConfig,
  processPaymentApi,
  processPosPayment,
  setCardValidation,
  setDisplayReader,
  submitCardPayment,
} from "../PaymentModule/external/Api";
import {
  HelperMethods,
  ViewModes,
} from "../stateAndConstants/paymentStateAndConstants/constants";
import * as ss from "../stateAndConstants/paymentStateAndConstants/stateSets";

export const PaymentSlice = createSlice({
  name: "main",
  initialState: HelperMethods.JPJS(initState),
  reducers: {
    resetState: () => HelperMethods.JPJS(initState),
    SetConfigurationPending: (state, action) => {
      ss.SetConfigurationPending(state, action);
    },
    SetBackEndHealthPending: (state, action) => {
      ss.SetBackEndHealthPending(state, action);
    },
    SetBackEndHealthErrored: (state, action) => {
      ss.SetBackEndHealthErrored(state, action);
    },
    SetBackEndHealthComplete: (state, action) => {
      ss.SetBackEndHealthComplete(state, action);
    },
    SetCreateOrderPending: (state, action) => {
      ss.SetCreateOrderPending(state, action);
    },
    ValidateAndSetAmount: (state, action) => {
      ss.ValidateAndSetAmount(state, action);
    },
    LocationAvailabilityPending: (state, action) => {
      ss.LocationAvailabilityPending(state, action);
    },
    CheckStripeTerminalConnection: (state, action) => {
      ss.CheckStripeTerminalConnection(state, action);
    },
    ShowPOSView: (state, action) => {
      ss.ShowPOSView(state, action);
    },
    ShowOnlineView: (state, action) => {
      ss.ShowOnlineView(state, action);
    },
    SetSelectedLocation: (state, action) => {
      ss.SetSelectedLocation(state, action);
    },
    SetSelectedReader: (state, action) => {
      ss.SetSelectedReader(state, action);
    },
    // SetProcessOrderErrored: (state, action) => {
    //   ss.SetProcessOrderErrored(state, action);
    // },
    // SetProcessOrderComplete: (state, action) => {
    //   ss.SetProcessOrderComplete(state, action);
    // },
    SetPaymentView: (state, action) => {
      ss.SetPaymentView(state, action);
    },
    SetTokenPending: (state, action) => {
      ss.SetTokenPending(state, action);
    },
    SetToken: (state, action) => {
      if (
        action.payload.orderPayloadToken !== null &&
        action.payload.orderPayloadToken !== ""
      ) {
        ss.SetTokenComplete(state, action);
      } else {
        ss.SetTokenErrored(state, action);
      }
    },

    SetOnlineError: (state, action) => {
      ss.SetOnlineError(state, action);
    },
    ReconnectReader: (state, action) => {
      ss.ReconnectReader(state, action);
    },
    ToggleModal: (state, action) => {
      ss.ToggleModal(state, action);
    },
    ConnectReaderDisplay: (state, action) => {
      ss.ConnectReaderDisplay(state, action);
    },
    SetProcessPosPayment: (state, action) => {
      ss.SetProcessPosPayment(state, action);
    },
    SetReaderDisplay: (state, action) => {
      ss.SetReaderDisplay(state, action);
    },
    SetRedirectBack: (state, action) => {
      ss.SetRedirectBack(state, action);
    },
    SetPaymentCancel: (state, action) => {
      ss.SetPaymentCancel(state, action);
    },
    SetPaymentStarted: (state, action) => {
      ss.SetPaymentStarted(state, action);
    },
    ClearReaderDisplay: (state, action) => {
      ss.ClearReaderDisplay(state, action);
    },
    SetCheckstatusPending: (state, action) => {
      ss.SetCheckstatusPending(state, action);
    },
    SetGetStatusPayload: (state, action) => {
      ss.SetGetStatusPayload(state, action);
    },
    CheckConnectionStatus: (state, action) => {
      ss.CheckConnectionStatus(state, action);
    },
    setDiscoverReaders: (state, action) => {
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "SetDiscoverReaders"
      )[0];
      state.UIInteractions.stripeErrors =
        "We are fetching the latest status of the reader.";
    },
    SetPosBackendPayment: (state, action) => {
      state.PaymentInteractionDetails.disableRecheckButton = true;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "PosBackendPaymentPending"
      )[0];
    },
    SetBraintreeConfirmPaymentPending: (state, action) => {
      state.UIInteractions.isPaymentStarted = true;
      state.PaymentInteractionDetails.disableRecheckButton = true;
      state.braintreeConfigurations.paymentMethodNonce = action.payload.nonce;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "BraintreeConfirmPaymentPending"
      )[0];
    },
    ShowBraintreeOnlineView: (state,action) => {
      ss.ShowBraintreeOnlineView(state,action)
    }
  },

  extraReducers(builder) {
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      if (
        action.payload.hasOwnProperty("apiEndpoint") &&
        action.payload.apiEndpoint !== "" &&
        action.payload.apiEndpoint !== null
      ) {
        ss.SetConfigurationComplete(state, action);
      } else {
        ss.SetConfigurationErrored(state, action);
      }
    });

    builder.addCase(loadConfig.rejected, (state, action) => {
      ss.SetConfigurationErrored(state, action);
    });

    builder.addCase(createOrderApi.fulfilled, (state, action) => {
      if (action.payload.status === "success") {
        ss.SetCreateOrderComplete(state, action);
      } else {
        ss.SetCreateOrderErrored(state, action);
      }
    });

    builder.addCase(createOrderApi.rejected, (state, action) => {
      ss.SetCreateOrderRejected(state, action);
    });

    builder.addCase(discoverReaders.fulfilled, (state, action) => {
      if (action.payload === "not_connected") {
        ss.CheckStripeTerminalFail(state, action);
      } else {
        ss.ReadersAvailabilityComplete(state, action);
      }
    });

    builder.addCase(discoverReaders.rejected, (state, action) => {
      ss.ReadersAvailabilityErrored(state, action);
    });

    builder.addCase(checkReaderConnection.fulfilled, (state, action) => {
      if (action.payload?.error) {
        ss.CheckReaderConnectionErrored(state, action);
      } else {
        ss.CheckReaderConnectionSuccess(state, action);
      }
    });

    builder.addCase(checkReaderConnection.rejected, (state, action) => {
      ss.CheckReaderConnectionErrored(state, action);
    });

    builder.addCase(connectReaderHandler.fulfilled, (state, action) => {
      if (action.payload?.error) {
        ss.ReconnectReaderErrored(state, action);
      } else {
        ss.ReconnectReaderComplete(state, action);
      }
    });

    builder.addCase(connectReaderHandler.rejected, (state, action) => {
      ss.ReconnectReaderErrored(state, action);
    });

    builder.addCase(setDisplayReader.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.SetReaderDisplayErrored(state, action);
      } else {
        ss.SetReaderDisplayComplete(state, action);
      }
    });

    builder.addCase(setDisplayReader.rejected, (state, action) => {
      ss.SetReaderDisplayErrored(state, action);
    });

    builder.addCase(disconnectReader.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.SetDisconnectReaderErrored(state, action);
      } else {
        ss.SetDisconnectReaderComplete(state, action);
      }
    });

    builder.addCase(disconnectReader.rejected, (state, action) => {
      ss.SetDisconnectReaderErrored(state, action);
    });

    builder.addCase(clearReaderDisplay.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.ClearReaderDisplayErrored(state, action);
      } else {
        ss.ClearReaderDisplayComplete(state, action);
      }
    });

    builder.addCase(clearReaderDisplay.rejected, (state, action) => {
      ss.ClearReaderDisplayErrored(state, action);
    });

    builder.addCase(submitCardPayment.fulfilled, (state, action) => {
      if (action.payload.hasOwnProperty("SetStripeLoadedErrored")) {
        ss.SetStripeLoadedErrored(state, action);
      } else {
        ss.SetCardValidation(state, action);
      }
    });

    builder.addCase(setCardValidation.fulfilled, (state, action) => {
      if (action.payload.hasOwnProperty("SetCardSubmitError")) {
        ss.SetCardSubmitError(state, action);
      } else {
        ss.SetCardProcessing(state, action);
      }
    });

    builder.addCase(processPaymentApi.fulfilled, (state, action) => {
      if (state.PaymentInteractionDetails.currentPaymentMethod === "card") {
        ss.SetCardProcessOrderComplete(state, action);
      } else {
        ss.PosProcessPaymentApiComplete(state, action);
      }
    });

    builder.addCase(processPaymentApi.rejected, (state, action) => {
      if (state.PaymentInteractionDetails.currentPaymentMethod === "card") {
        ss.SetCardProcessOrderErrored(state, action);
      } else {
        ss.PosProcessPaymentApiErrored(state, action);
      }
    });

    builder.addCase(confirmCardPayment.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.ConfirmCardPaymentErrored(state, action);
      } else {
        ss.ConfirmCardPaymentSuccess(state, action);
      }
    });

    builder.addCase(confirmCardPayment.rejected, (state, action) => {
      ss.ConfirmCardPaymentErrored(state, action);
    });

    builder.addCase(collectPaymentMethod.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.CollectPaymentErrored(state, action);
      } else {
        ss.CollectPaymentSuccess(state, action);
      }
    });

    builder.addCase(collectPaymentMethod.rejected, (state, action) => {
      ss.CollectPaymentErrored(state, action);
    });

    builder.addCase(processPosPayment.fulfilled, (state, action) => {
      if (action.payload.error) {
        ss.ProcessPosPaymentErrored(state, action);
      } else {
        ss.ProcessPosPaymentSuccess(state, action);
      }
    });

    builder.addCase(processPosPayment.rejected, (state, action) => {
      ss.ProcessPosPaymentErrored(state, action);
    });

    builder.addCase(checkStatus.fulfilled, (state, action) => {
      ss.CheckStatusSuccess(state, action);
    });

    builder.addCase(checkStatus.rejected, (state, action) => {
      ss.CheckStatusErrored(state, action);
    });

    builder.addCase(cancelPayment.fulfilled, (state, action) => {
      if (state.PaymentInteractionDetails.currentPaymentMethod === "card") {
        ss.CancelPaymentCardSuccess(state, action);
      } else {
        ss.CancelPaymentPosSuccess(state, action);
      }
    });

    builder.addCase(cancelPayment.rejected, (state, action) => {
      if (state.PaymentInteractionDetails.currentPaymentMethod === "card") {
        ss.CancelPaymentCardErrored(state, action);
      } else {
        ss.CancelPaymentPosErrored(state, action);
      }
    });

    builder.addCase(getStatusPayload.fulfilled, (state, action) => {
      ss.GetStatusPayloadSuccess(state, action);
    });

    builder.addCase(getStatusPayload.rejected, (state, action) => {
      ss.GetStatusPayloadErrored(state, action);
    });

    builder.addCase(getStatusDiscoverReaders.fulfilled, (state, action) => {
      let readers = JSON.parse(
        JSON.stringify(action.payload.discoveredReaders)
      );
      let reader = readers.filter(
        (rdr) => rdr.id === state.PaymentInteractionDetails.currentReader.id
      )[0];
      state.PaymentInteractionDetails.currentReader.status = reader.status;
      state.UIInteractions.posActionsDisabled =
        reader.status.toLowerCase() === "offline";
      state.UIInteractions.stripeErrors = "";
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ShowPOSView"
      )[0];
    });

    builder.addCase(getStatusDiscoverReaders.rejected, (state, action) => {
      state.UIInteractions.stripeErrors = action.payload.value.message;
    });

    builder.addCase(confirmPosPaymentBackend.fulfilled, (state, action) => {
      ss.ConfirmPosPaymentBackendSuccess(state, action);
    });

    builder.addCase(confirmPosPaymentBackend.rejected, (state, action) => {
      ss.ConfirmPosPaymentBackendErrored(state, action);
    });

    builder.addCase(confirmBraintreePayment.fulfilled, (state, action) => {
      state.PaymentIntegrations.paymentStatus =
        action.payload.payload.status.toLowerCase();
      state.PaymentInteractionDetails.disableRecheckButton = true;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "CheckStatusPending"
      )[0];
    });

    builder.addCase(confirmBraintreePayment.rejected, (state, action) => {
      state.UIInteractions.onlineErrors =
        action.payload.err?.response?.data?.detail ||
        "Could not confirm the payment. Please try again.";
      state.UIInteractions.isPaymentStarted = false;
      state.PaymentInteractionDetails.disableRecheckButton = false;
      state.UIInteractions.viewStructure = ViewModes.filter(
        (vm) => vm.ViewName === "ShowBraintreeOnlineView"
      )[0];
    });
  },
});

export const {
  resetState,
  SetConfigurationPending,
  SetTokenPending,
  SetToken,
  SetCreateOrderPending,
  ValidateAndSetAmount,
  LocationAvailabilityPending,
  CheckStripeTerminalConnection,
  ShowPOSView,
  ShowOnlineView,
  SetPaymentView,
  SetSelectedLocation,
  SetSelectedReader,
  SetOnlineError,
  SetProcessOrderPending,
  ReconnectReader,
  ToggleModal,
  ConnectReaderDisplay,
  SetProcessPosPayment,
  SetReaderDisplay,
  ClearReaderDisplay,
  SetRedirectBack,
  SetPaymentCancel,
  SetPaymentStarted,
  SetCheckstatusPending,
  SetGetStatusPayload,
  CheckConnectionStatus,
  setDiscoverReaders,
  SetPosBackendPayment,
  SetBraintreeConfirmPaymentPending,
  ShowBraintreeOnlineView
} = PaymentSlice.actions;

export const MainReducer = PaymentSlice.reducer;
