import { useDispatch, useSelector } from "react-redux";
import { SetSelectedLocation,SetSelectedReader } from "../../slice/PaymentSlice";

const useLocationsAndReadersHook = () => {
  const dispatch = useDispatch();

  const locations = useSelector(
    (state) => state.main.PaymentInteractionDetails.locations
  );
    const currentLocation = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentLocation
  );
  const readers = useSelector(
    (state) => state.main.PaymentInteractionDetails.readers
  );
  const currentReader = useSelector(
    (state) => state.main.PaymentInteractionDetails.currentReader
  );
  const poserrors = useSelector(
    (state) => state.main.UIInteractions.posErrors
  );
  const locationerrors = useSelector((state)=>state.main.UIInteractions.locationErrors)

  const setLocation = (location) =>{
    dispatch(SetSelectedLocation(location))
  }

  const setReader = (id) => {
    dispatch(SetSelectedReader(id))
  }

  return {
    locations,
    readers,
    currentReader,
    currentLocation,
    poserrors,
    locationerrors,
    setLocation,
    setReader
  };
};

export default useLocationsAndReadersHook;
