import React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import useStripePaymentElementHook from "../../hooksAndUiInteractions/StripePaymentElementHook";


export default function StripePaymentElement() {
  const { onlineErrors, setProcessPayment, setErrorsEmpty } =
    useStripePaymentElementHook();

  return (
    <div>
      <form
        id="card-payment-form"
        onSubmit={(e) => {
          setProcessPayment(e);
        }}
        data-testid="payment-element-test"
      >
        <PaymentElement
          // To discard the previous errors on new user input
          onChange={(e)=>onlineErrors===""?null : setErrorsEmpty}
        />
      </form>
    </div>
  );
}
