import { useDispatch, useSelector } from "react-redux";
import {
  SetBraintreeConfirmPaymentPending,
  SetOnlineError,
} from "../../../slice/PaymentSlice";
import { useState } from "react";

const useDropinHook = () => {
  const dispatch = useDispatch();
  const [dropinInstance, setBraintreeInstance] = useState(undefined);

  const handleRequestPayment = () => {
    dropinInstance.requestPaymentMethod(function (
      requestPaymentMethodErr,
      payload
    ) {
      if (requestPaymentMethodErr) {
        console.log(requestPaymentMethodErr);
        setBraintreeError(requestPaymentMethodErr);
      } else {
        dispatch(SetBraintreeConfirmPaymentPending(payload));
      }
    });
  };

  const clientToken = useSelector(
    (state) => state.main.braintreeConfigurations.client_token
  );
  const onlineErrors = useSelector(
    (state) => state.main.UIInteractions.onlineErrors
  );
  const total = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.total
  );

  const isBraintree = useSelector(
    (state) => state.main.UIInteractions.isBraintree
  );

  const setBraintreeError = (err) => {
    let errBody = {
      error: "",
      action: false,
    };
    if (err) {
      errBody.error = err?.message;
      errBody.action = true;
    }
    dispatch(SetOnlineError(errBody));
  };

  const setPaymentNonce = (payload) => {
    dispatch(SetBraintreeConfirmPaymentPending(payload));
  };

  return {
    clientToken,
    onlineErrors,
    setBraintreeError,
    total,
    isBraintree,
    setPaymentNonce,
    dropinInstance,
    setBraintreeInstance,
    handleRequestPayment,
  };
};

export default useDropinHook;
