import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, IconButton } from "@mui/material";
import { useAllActionsHook } from "../../hooksAndUiInteractions/allActionsHook";
import { Tooltip } from "@mui/material";

export function CancelPayment() {
  const { setPaymentCancel, isPaymentStarted } = useAllActionsHook();
  return (
    <div>
      {" "}
      <Button
        data-testid="cancel-button-test"
        variant="outlined"
        className="cancel-button"
        disabled={isPaymentStarted}
        onClick={() => {
          setPaymentCancel();
        }}
      >
        Cancel
      </Button>
    </div>
  );
}

export function DownloadButton() {
  return (
    <div>
      {" "}
      <Button variant="outlined" className="download-reciept-button">
        {" "}
        Download Reciept
      </Button>
    </div>
  );
}

export function ContinueButton() {
  const { redirectBack } = useAllActionsHook();
  return (
    <div>
      {" "}
      <Button
        variant="contained"
        className="continue"
        onClick={() => redirectBack()}
        data-testid="continue-button-test"
      >
        {" "}
        Continue{" "}
      </Button>
    </div>
  );
}

// The button should be inside form, hence linked by form id
export function ProcessCardPaymentAction() {
  const { onlineActionsDisabled, isPaymentStarted } = useAllActionsHook();
  return (
    <div>
      <Button
        data-testid="card-payment-button-test"
        variant="outlined"
        type="submit"
        form="card-payment-form"
        className="process-payment-button"
        disabled={onlineActionsDisabled || isPaymentStarted}
      >
        Confirm
      </Button>
    </div>
  );
}

export function PreviewAndProceedOnPOS() {
  const { posActionsDisabled, setToggleModal, isPaymentStarted } =
    useAllActionsHook();
  return (
    <div>
      {" "}
      <Button
        variant="outlined"
        className="preview-pay-button"
        data-testid="preview-button-test"
        disabled={posActionsDisabled || isPaymentStarted}
        onClick={() => setToggleModal(true)}
      >
        Preview and Pay
      </Button>
    </div>
  );
}

// Calls Stripe to confirm payment on WFE.
// called when processPaymentAtBackend = false
export function ProcessPosPaymentAction() {
  const { posActionsDisabled, setProcessPosPayment, isPaymentStarted } =
    useAllActionsHook();
  return (
    <div>
      <Button
        variant="outlined"
        type="submit"
        className="confirm-payment-button"
        data-testid="confirm-pos-button-test"
        disabled={posActionsDisabled || isPaymentStarted}
        onClick={setProcessPosPayment}
      >
        Confirm
      </Button>
    </div>
  );
}

export function RetryPayment() {
  return (
    <div>
      {" "}
      <Button variant="outlined" className="retry-button">
        Retry Payment
      </Button>
    </div>
  );
}

// Calls connectReader to connect to the reader - when processPaymentAtBackend = false
// Calls discoverReaders to update the status - when processPaymentAtBackend = true
export function RefreshPosStatus() {
  const { reconnectReader } = useAllActionsHook();
  return (
    <div>
      <Tooltip
        title="Refresh Reader Status"
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#2957A3",
              color: "white",
              '& .MuiTooltip-arrow': {
                color:"#2957A3"
              },
            },
          },
        }}
      >
        <IconButton
          variant="outlined"
          className="refresh-button"
          data-testid="refresh-button-test"
          onClick={() => reconnectReader()}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export function RecheckStatusButton() {
  const { recheckStatus, disableRecheckButton } = useAllActionsHook();
  return (
    <div>
      {" "}
      <Button
        className="recheck-button"
        variant="outlined"
        data-testid="recheck-button-test"
        onClick={() => recheckStatus()}
        disabled = {disableRecheckButton}
      >
        Recheck Status
      </Button>
    </div>
  );
}

// New button for calling Backend API to process POS payment
// called when processPaymentAtBackend = true
export function ConfirmPaymentBackend() {
  const { posActionsDisabled, isPaymentStarted, posBackend } =
    useAllActionsHook();
  return (
    <div>
      <Button
        variant="outlined"
        type="submit"
        className="process-payment-button"
        data-testid="confirm-pos-backend-button-test"
        disabled={posActionsDisabled || isPaymentStarted}
        onClick={() => posBackend()}
      >
        Confirm
      </Button>
    </div>
  );
}
