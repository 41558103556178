import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Button, Tooltip, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReaderTable from "./ReaderTable";
import LocationForm from "./LocationForm";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function LocationTable() {
  const {
    selectedIntegration,
    selectedTenant,
    searchLocationVariable,
    addNewLocation,
    setSelectedLocation,
    closeLocationForm,
    selectedReader,
    deleteSelectedLocation,
    pagination,
    locationsDisplayList,
    setLocationPageNo
  } = useIntegrationHook();

  const [searchVar, setSearchVar] = useState("");
  return (
    <div className="LocationTable">
      <div className="LocationHeader">
        <Typography className="LocationHeaderText">Location Details</Typography>
        <div className="add-location-pagination">
          <Pagination
            count={Math.ceil(
              selectedTenant.locations.length / pagination.locationsPerPage
            )}
            page={pagination.locationsPage}
            onChange={(e, value) => setLocationPageNo(value)}
            color="primary"
            className="custom-pagination-location"
            size="small"
          />
        </div>
        <div className="LocationTableActions">
          <Tooltip title="Add New Location" arrow placement="right-start">
            <IconButton
              className="add-location-button"
              data-testid="add-location-test-button"
              onClick={() => {
                addNewLocation();
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Paper className="paper-searchbox">
            <IconButton className="search-button" aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              className="search-input"
              placeholder="Search Location"
              inputProps={{ "aria-label": "search Location" }}
              onChange={(e) => {
                setSearchVar(e.target.value);
              }}
            />
          </Paper>
        </div>
      </div>
      <div className="button-container">
        {/* {(saveLocationsEnable?.isLocationAdded ||
          saveLocationsEnable?.isLocationEdited) === true ? (
          <Button
            className="save-location-button"
            variant="contained"
            disabled={
              !saveLocationsEnable?.isLocationAdded
            }
            startIcon={<DownloadDoneIcon />}
            data-testid="save-location-test-button"
            onClick={() => {
              saveLocationsClick({
                selectedIntegration: selectedIntegration,
                selectedTenant: selectedTenant,
              });
            }}
          >
            Save Locations
          </Button>
        ) : null} */}
      </div>
      <div className="error-location-table">
        {locationsDisplayList === undefined ||
        locationsDisplayList.length === 0 ? (
          <p className="no-location-message">No Location Found!</p>
        ) : null}
      </div>
      <div className="Location-Card-Table">
        {/* NOTE - 
        Filtering using Redux required configuring another variable in TemplateTenant as 
        current implementation of filterLocations() updates the original list
        and to avoid variable, another API call of GetLocations is required 
        to fill up the locations array again when empty */}
        {locationsDisplayList
          .filter((location) => {
            if (searchVar === "") {
              return true;
            }
            return (
              location.display_name
                .toLowerCase()
                .includes(searchVar.toLowerCase()) ||
              location.provider_location_id
                .toLowerCase()
                .includes(searchVar.toLowerCase())
            );
          })
          .map((location, index) => (
            <div
              className={`main-accordion ${
                location.isExpanded === true ? "add-border" : ""
              }`}
              key={index}
            >
              <Button
                className="override-button"
                data-testid="locations-list-table"
                onClick={(e) => setSelectedLocation(location.Loc_id)}
              >
                <div
                  className={`Location-One-Info ${
                    location.changed_ind === true ? "unsaved-changes" : "normal"
                  }`}
                >
                  <div className="Location-name-div">
                    <div className="Location-name">{location.display_name}</div>
                  </div>
                  <div className="Location-adr-div">
                    <div className="Location-adr">{location.address.line1}</div>
                  </div>
                  <div className="Location-action-buttons">
                    {location.changed_ind === true ? (
                      <Tooltip title="Unsaved changes" arrow placement="top">
                        <ErrorOutlineIcon className="unsaved-icon" />
                      </Tooltip>
                    ) : null}
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteSelectedLocation({
                          location: location,
                          readers: location.readers,
                          selectedIntegration: selectedIntegration,
                          selectedTenant: selectedTenant,
                        });
                      }}
                    >
                      <img
                        alt="Delete Button"
                        src="/Images/icon _trash_.svg"
                        width="20px"
                        height="20px"
                      />
                    </IconButton>

                    {location.isExpanded ? (
                      <IconButton
                        className="down-reader"
                        onClick={(e) => {
                          e.stopPropagation();
                          closeLocationForm(location.Loc_id);
                        }}
                      >
                        <KeyboardArrowUpOutlined />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="down-reader"
                        onClick={(e) => {
                          e.stopPropagation();
                          closeLocationForm(location.Loc_id);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </Button>
              {location.isExpanded & location.toggleToEdit ? (
                <ReaderTable
                  idprop={location.Loc_id}
                  readers={location.readers}
                  selectedReader={selectedReader}
                  locprovid={location.provider_location_id}
                />
              ) : location.isExpanded & !location.toggleToEdit ? (
                <LocationForm
                  idprop={location.Loc_id}
                  location={location}
                  providprop={location.provider_location_id}
                />
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
}
