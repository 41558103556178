import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import { useLoginHook } from "../adminHooks/loginHook";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AlertModal from "./AlertModal";
export default function AdminLogin() {
  const {
    username,
    password,
    aws_region,
    user_pool,
    client_id,
    emailError,
    passwordError,
    emailHelperText,
    passWordHelperText,
    canLogin,
    isPasswordUpdated,
    accessToken,
    validateInp,
    cognitoSignIn,
    takeInput,
    getConfig,
    resetVariables,
    setTokenOnReload,
    configLoaded
  } = useLoginHook();



  const navigate = useNavigate();

  useEffect(() => {
    if (isPasswordUpdated) {
      resetVariables();
      alert(
        "Your password has been updated successfully. Please log in using your new password"
      );
    }
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canLogin) {
      cognitoSignIn(username, password, aws_region, client_id, user_pool);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLogin]);

  useEffect(() => {
    if (accessToken !== "") {
      navigate("/admin/dashboard");
    } 

    // NOTE - Checking config loaded as this useEffect completes before config.fulfilled
    if(configLoaded){
      const key = Object.keys(localStorage).find((key) =>
      key.includes(".accessToken")
    );
    localStorage.getItem(key) !== null
      ? setTokenOnReload(localStorage.getItem(key))
      : navigate("/admin/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, configLoaded]);



  return (
    <div className="auth-page-container">
      <AlertModal />
      <Box className="login-container">
        <Box className="login-form-container">
          <img
            src="/Images/quicketsolutionslogo.svg"
            alt=""
            style={{ marginBottom: 15 }}
          />
          <Typography variant="h6" className="heading-pass">
            Login to Quicket-Pay Admin Portal
          </Typography>
          <TextField
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            inputProps={{ "data-testid": "username-input" }}
            value={username}
            error={emailError}
            helperText={emailHelperText}
            autoComplete="username"
            autoFocus
            onChange={(event) => {
              takeInput(event.target.name, event.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={passwordError}
            helperText={passWordHelperText}
            value={password}
            inputProps={{ "data-testid": "password-input" }}
            autoComplete="current-password"
            onChange={(event) => {
              takeInput(event.target.name, event.target.value);
            }}
          />
          <Link
            className="link-url"
            style={{ justifyContent: "flex-end" }}
            to="/admin/forgot_password"
          >
            Forgot Password?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            data-testid="sign-in-button"
            onClick={(e) => {
              e.preventDefault();
              validateInp(username, password);
              //   cognitoSignIn(username, password, aws_region, client_id);
            }}
          >
            Sign In
          </Button>
          {/* <Link className="link-url" to="/signup">
          Create a new User
        </Link> */}
        </Box>
      </Box>
    </div>
  );
}
