import React from "react";
import ReactDOM from "react-dom/client";
import "./Index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouteBaseContainer } from "./RouteBaseContainer";
import LogRocket from "logrocket";
import axios from "axios";

const initLogRocket = async () => {
  window.logRocketObj = await axios.get("/config.json").then((res) => {
    const data = res.data;
    if (data?.logrocketApp && data?.logrocketSdkServer && data?.logrocketIngestServer) {
      LogRocket.init(data?.logrocketApp, {
        sdkServer: data.logrocketSdkServer,
        ingestServer: data.logrocketIngestServer,
      });
      return LogRocket;
    } else {
      return null;
    }
  });
};

initLogRocket();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouteBaseContainer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
