import React from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import usePaymentOrderDetailsHook from "../../hooksAndUiInteractions/PaymentOrderDetailsHook";

export default function PaymentOrderDetails() {
  const {
    amount,
    serviceFee,
    total,
    currency,
    currentMenu,
    showPaymentStatus,
    currentStatus,
    currencySymbol,
    displayRefId,
    description,
  } = usePaymentOrderDetailsHook();

  return (
    <div className={`Rest-page ${showPaymentStatus ? "status" : ""}`}>
      <Card className="card-wrapper">
        <div className={`card-sub-section ${description ? "description" : ""}`}>
          {description ? (
            <Typography className="card-title description-text">{description} </Typography>
          ) : null}
          <Typography className="card-title referenceId-text">
            Reference Id - {displayRefId}{" "}
          </Typography>
        </div>
        <div className="card-main-details">
          <Card className="card-body">
            <div className="card-div">
              <Typography className="card-div-content" width={120}>
                Amount
              </Typography>
              <Typography className="card-div-content">-</Typography>
              {currencySymbol ? (
                <Typography className="card-div-content cur" width={120}>
                  {currencySymbol} {amount}
                </Typography>
              ) : (
                <Typography className="card-div-content cur" width={120}>
                  {amount} {currency}
                </Typography>
              )}
            </div>
            <div className="card-div">
              <Typography width={120} className="card-div-content">
                Service Charges
              </Typography>
              <Typography className="card-div-content">-</Typography>
              {currencySymbol ? (
                <Typography className="card-div-content cur" width={120}>
                  {currencySymbol} {serviceFee}
                </Typography>
              ) : (
                <Typography className="card-div-content cur" width={120}>
                  {serviceFee} {currency}
                </Typography>
              )}
            </div>
            <div className="card-div">
              <Typography width={120} className="card-div-content">
                Payable Amount
              </Typography>
              <Typography className="card-div-content">-</Typography>
              {currencySymbol ? (
                <Typography className="card-div-content cur" width={120}>
                  {currencySymbol} {total}
                </Typography>
              ) : (
                <Typography className="card-div-content cur" width={120}>
                  {total} {currency}
                </Typography>
              )}
            </div>
          </Card>
          <Card className="sub-div-card">
            {currencySymbol ? (
              <Typography className="sub-div-content cur">
                {" "}
                {currencySymbol} {total}
              </Typography>
            ) : (
              <Typography className="sub-div-content cur">
                {" "}
                {total} {currency}
              </Typography>
            )}
          </Card>
        </div>
        <div className="container-stripe-or">
          {showPaymentStatus
            ? currentStatus?.MenuComponent
            : currentMenu?.MenuComponent}
        </div>
      </Card>
    </div>
  );
}
