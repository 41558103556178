import React from "react";
import { Box, Typography } from "@mui/material";
import { ContinueButton } from "../actionButtons/allActions";
import usePaymentStatusHook from "../../hooksAndUiInteractions/PaymentStatusHook";
import { CheckCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { PaymentStatus } from "../../../stateAndConstants/paymentStateAndConstants/constants";

export default function PaymentSuccess() {
  const {
    paymentStatus,
    total,
    currency,
    paymentId,
    receiptUrl,
    currencySymbol,
    paymentDate,
  } = usePaymentStatusHook();
  return (
    <div>
      <>
        <div
          className="payment-status-successful"
          data-testid="successful-payment-test"
        >
          <div>
            <CheckCircleOutline
              className="icon-payment"
              data-testid="success-icon-test"
            />
          </div>
          <div>
            <Typography className="status-text">
              {paymentStatus === PaymentStatus.succeeded
                ? "Successful Payment"
                : paymentStatus}
            </Typography>
          </div>
          <div>
            {currencySymbol ? (
              <Typography className="amount-text" data-testid="amount-test">
                {currencySymbol} {total}
              </Typography>
            ) : (
              <Typography className="amount-text" data-testid="amount-test">
                {total} {currency}
              </Typography>
            )}
          </div>
          {/* <div>
            <Typography
              className="transaction-id-text"
              data-testid="transaction-id-test"
            >
              Transaction ID : {paymentId}
            </Typography>
          </div> */}
          <div>
            {paymentDate ? (
              <Typography className="date-text">{paymentDate}</Typography>
            ) : null}
          </div>
        </div>
        <Box className="main-button-section">
          {receiptUrl ? (
            <Typography className="download-text">
              Please print{" "}
              <label className="clickable-text">
                <Link to={receiptUrl} target="_blank">
                  this page
                </Link>
              </label>{" "}for your records.
            </Typography>
          ) : null}
          <ContinueButton />
        </Box>
      </>
    </div>
  );
}
