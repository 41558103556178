import { Button, IconButton } from "@mui/material";
import EditableConfig from "./CustomControls/EditableConfig";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";

export default function LocationForm({ location, idprop, providprop }) {
  const {
    toggleViewLR,
    getLocationMetaData,
    locationUpdate,
    getAllReaders,
    addUpdateLocation,
    setDiscardLocation
  } = useIntegrationHook();
  let EditableConfig_1 = JPJS(getLocationMetaData);
  EditableConfig_1.ID_Level.push(idprop);
  EditableConfig_1.updating_key = "display_name";
  let EditableConfig_2 = JPJS(getLocationMetaData);
  EditableConfig_2.ID_Level.push(idprop);
  EditableConfig_2.updating_key = "address_line";
  let EditableConfig_3 = JPJS(getLocationMetaData);
  EditableConfig_3.ID_Level.push(idprop);
  EditableConfig_3.updating_key = "address_city";
  let EditableConfig_4 = JPJS(getLocationMetaData);
  EditableConfig_4.ID_Level.push(idprop);
  EditableConfig_4.updating_key = "address_state";
  let EditableConfig_5 = JPJS(getLocationMetaData);
  EditableConfig_5.ID_Level.push(idprop);
  EditableConfig_5.updating_key = "address_country";
  let EditableConfig_6 = JPJS(getLocationMetaData);
  EditableConfig_6.ID_Level.push(idprop);
  EditableConfig_6.updating_key = "address_postal";
  const getProcessPaymentConfig = (selectedOption) => {
    if (selectedOption === "any") {
      return true;
    } else if (selectedOption === "same") {
      return false;
    } else {
      return null;
    }
  };
  let processFlag = null;
  let nlocation = JPJS(location);
  return (
    <div className="LocationForm">
      <div className="divide-cols">
        <div className="lf-col1">
          <div className="form-row">
            <div className="form-fieldtype">
              <label htmlFor="firstName">Location Name</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.display_name}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_1}
                newcssforinput="inputbggrey"
              />
              {nlocation.nameError !== "" ? (
                <div className="no-payment-message">{location.nameError}</div>
              ) : null}
            </div>
            <div className="form-fieldtype">
              <label htmlFor="phone">Address</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.address.line1}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_2}
                newcssforinput="inputbggrey"
              />
              {nlocation.lineError !== "" ? (
                <div className="no-payment-message">{location.lineError}</div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label htmlFor="phone">City</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.address.city}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_3}
                newcssforinput="inputbggrey"
              />
              {nlocation.cityError !== "" ? (
                <div className="no-payment-message">{location.cityError}</div>
              ) : null}
            </div>
            <div className="form-field">
              <label htmlFor="phone">State</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.address.state}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_4}
                newcssforinput="inputbggrey"
              />
              {nlocation.stateError !== "" ? (
                <div className="no-payment-message">{location.stateError}</div>
              ) : null}
            </div>
            <div className="form-field">
              <label htmlFor="phone">Country</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.address.country}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_5}
                newcssforinput="inputbggrey"
              />
              {nlocation.countryError !== "" ? (
                <div className="no-payment-message">
                  {nlocation.countryError}
                </div>
              ) : null}
            </div>
            <div className="form-field">
              <label htmlFor="phone">Postal Code</label>
              <EditableConfig
                type={nlocation}
                value={nlocation.address.postal_code}
                classNameprop="location-name"
                dataTestIdProp="location-name-input"
                updateValue={locationUpdate}
                controlMetaData={EditableConfig_6}
                newcssforinput="inputbggrey"
              />
              {nlocation.postalError !== "" ? (
                <div className="no-payment-message">{location.postalError}</div>
              ) : null}
            </div>
            <div className="form-field">
              <label htmlFor="phone">Payment Config</label>
              <div className="payment-config">
                <select
                  className="jira-select"
                  onChange={(e) => {
                    processFlag = getProcessPaymentConfig(e.target.value);
                    nlocation.process_payment_at_backend = processFlag;
                  }}
                  defaultValue={
                    nlocation.process_payment_at_backend ? "any" : "same"
                  }
                >
                  <option value="any">Any Network</option>
                  <option value="same">Same Network</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="lf-col2">
          <IconButton
            onClick={() => {
              toggleViewLR(idprop);
              if (providprop) {
                getAllReaders(providprop);
              }
            }}
          >
            <KeyboardArrowRightOutlined />
          </IconButton>
        </div>
      </div>
      <div className="form-row">
        <div className="line-lf" />
      </div>
      <div className="form-row button-container">
        <Button
          className="save-location-button"
          variant="contained"
          startIcon={<DownloadDoneIcon />}
          data-testid="save-reader-test-button"
          onClick={() => {
            addUpdateLocation(nlocation);
          }}
        >
          Save
        </Button>
        {nlocation.record_status !== "A" ? (
          <Button
            className="discard-tenant-button"
            variant="contained"
            startIcon={<ClearIcon />}
            data-testid="discard-integration-test-button"
            onClick={() => setDiscardLocation(nlocation)}
          >
            Discard
          </Button>
        ) : null}
      </div>
    </div>
  );
}
