import { Card } from "@mui/material";
import React from "react";
import useSideNavigationHook from "../../hooksAndUiInteractions/SideNavigationHook";

export default function SideNavigation() {
  const { viewStructure, menuStructure, setPaymentMethod, isPaymentStarted } =
    useSideNavigationHook();

  return (
    <div className="Rest-page">
      <div className="Sidebar">
        <div className="Sidebar-container-data">
          {menuStructure.map((menu, index) => (
            <Card
              className="Sidebar-controls"
              data-testid="menu-card-test"
              sx={{
                // backgroundColor:
                //   viewStructure.MainViewName === menu.MenuTag
                //     ? "#AACAF3"
                //     : " #D4DDED",
                backgroundColor:
                  viewStructure.MainViewName === menu.MenuTag
                    ? "#6da8f5"
                    : "#D4DDED",
                borderColor:
                  viewStructure.MainViewName === menu.MenuTag
                    ? "#D4DDED"
                    : "#6da8f5",
                borderBottomWidth: "1.06px",
                borderBottomStyle: "solid",
                pointerEvents: isPaymentStarted ? "none" : "auto",
              }}
              onClick={() => setPaymentMethod(menu.MenuView)}
              key={index}
            >
              <menu.MenuIcon
                className="icon-sidebar"
                sx={{
                  color:
                    viewStructure.MainViewName === menu.MenuTag
                      ? "#EFF6FB"
                      : "#2957a3",
                }}
              />

              <p
                className={`sidebar-button-text ${viewStructure.MainViewName === menu.MenuTag ? 'selected':  ""}`}
                data-testid="menu-button-test"
              >
                {menu.MenuText}
              </p>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
