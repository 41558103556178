import { HelperMethods, ViewModes, viewModeNameList } from "./constants";

export let initState = {
  UIInteractions: {
    StopStateForTesting: "",
    menuStructure: [],
    viewStructure: HelperMethods.JPJS(
      ViewModes.filter(
        (vm) => vm.ViewName === viewModeNameList.ConfigPending
      )[0]
    ),
    currentComponent: "", //MenuStructure Tag
    showPosModal: false,
    showPosAlert: false,
    isPOSpossible: false,
    isCardpossible: false,
    isBraintree: false,
    applicationErrors: "",
    locationErrors: "",
    posErrors: "",
    onlineErrors: "",
    stripeErrors: "",
    posActionsDisabled: false,
    onlineActionsDisabled: false,
    isPaymentStarted: false, // this is for remaining buttons in the WFE flow
    locationChangeHandler: {
      isLocationChangeNeeded: false,
      startStage: "",
    },
  },

  config: {},

  IntegratingClientDetails: {
    amountDetails: {
      amount: 0.00,
      serviceFee: 0.00,
      total: 0.00,
      currency: "",
      currencySymbol: "",
    },
    tenantDetails: {
      referenceId: "",
      browserCallbackUrl: "",
    },
    description: "",
  },

  PaymentIntegrations: {
    stripeKeys: {
      publicKey: null,
      connectionToken: null,
    },
    cardClientSecret: "",
    posClientSecret: "",
    paymentIntent: {},
    paymentStatus: "",
    paymentDate: null,
    paymentErrorMessage: "",
    receiptUrl: null,
    statusPayload: "",
  },

  PaymentInteractionDetails: {
    authToken: "",
    orderPayloadToken: "",
    createOrderSucceeded: false,
    createOrderPayload: null,
    cardProcessingPayload: null,
    showPaymentStatus: false,
    paymentId: "",
    paymentGateway: "",
    availablePaymentGateways:[],
    paymentModes: null,
    // "payment_modes": {"in_person": ["card_present"], "online": ["card"]}
    paymentMethodGateways: null,
    //   "payment_method_gateways": {
    //     "online": {
    //         "card": ""
    //     },
    //     "in_person": {
    //         "card_present": ""
    //     }
    // },
    locations: [],
    readers: [],
    card: {},
    currentLocation: null,
    previousReader: null,
    currentReader: null,
    currentPaymentMode: "",
    currentPaymentMethod: "",
    isReaderConnected: false,
    processPaymentAtBackend: false,
    // Below variable is for the requirement to show recheck status but
    // disable it while polling
    // and enable it again on timeout.
    disableRecheckButton: false,
  },

  braintreeConfigurations: {
    client_token: "",
    paymentMethodNonce: null,
  },
};
