import React from "react";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import {
  CancelPayment,
  ContinueButton,
  RecheckStatusButton,
} from "../../PaymentModule/components/actionButtons/allActions";
import {
  LoadingAnimation,
  NoShow,
  DataErrors,
  ProcessError,
} from "../../PaymentModule/components/TransitionAnimation/TransitionAnimation";
import AddCardIcon from "@mui/icons-material/AddCard";
import TransitionView from "../../PaymentModule/components/TransitionView";
import PaymentView from "../../PaymentModule/components/PaymentView";
import PosMethodComponent from "../../PaymentModule/components/posSpecific/PosMethodComponent";
import CardMethodComponent from "../../PaymentModule/components/onlineSpecific/CardMethodComponent";
import PaymentSuccess from "../../PaymentModule/components/paymentStatus/PaymentSuccess";
import PaymentFailure from "../../PaymentModule/components/paymentStatus/PaymentFailure";
import PaymentResultView from "../../PaymentModule/components/paymentStatus/PaymentResultView";
import PaymentPending from "../../PaymentModule/components/paymentStatus/PaymentPending";
import PaymentCancel from "../../PaymentModule/components/paymentStatus/PaymentCancel";
import DropinComponent from "../../PaymentModule/components/braintreeSpecific/DropinComponent";

export const viewModeNameList = {
  ConfigPending: "ConfigPending",
  ConfigErrored: "ConfigErrored",
  ConfigComplete: "ConfigComplete",
  SetTokenPending: "SetTokenPending",
  SetTokenComplete: "SetTokenComplete",
  SetTokenErrored: "SetTokenErrored",
};

export const PaymentStatus = {
  succeeded: "succeeded",
  failed: "failed",
  in_progress: "in_progress",
  processing: "processing",
  cancelled: "cancelled",
  initialized: "initialized",
};

export const TransitionViewModes = [
  {
    mode: "config_pending_mode",
    main_message_text: "The configurations for the application is loading",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "config_errored_mode",
    main_message_text: "Application is down. Please contact administrator.",
    icon: <NoShow />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "config_complete_mode",
    main_message_text: "The configurations for the application are loaded",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "set_token_pending",
    main_message_text: "Reading the required payment details",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "set_token_errored",
    main_message_text:
      "The payment request is invalid. Please update and try again.",
    icon: <NoShow />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "set_token_complete",
    main_message_text: "Read the required payment details successfully",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "create_order_pending",
    main_message_text: "Loading the payment modes and methods",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "create_order_errored",
    main_message_text:
      "The required payment information is missing. Please update and retry.",
    icon: <DataErrors />,
    // buttons:[]
    buttons: [<ContinueButton />],
  },
  {
    mode: "create_order_rejected",
    main_message_text:
      "The required payment information is missing. Please update and retry.",
    icon: <NoShow />,
    buttons: [],
  },
  {
    mode: "create_order_complete",
    main_message_text: "Loaded the payment modes and methods successfully",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "location_check_pending",
    main_message_text:
      "Loading the devices for available locations for the payment",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "stripe_connection_check",
    main_message_text: "Checking the Stripe Connection for payment",
    icon: <NoShow />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "location_check_complete",
    main_message_text:
      "Loaded the devices in the locations for the payment successfully",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "application_load_mode",
    main_message_text: "Please wait while application is loading...",
    icon: <LoadingAnimation />,
    buttons: [<ContinueButton />],
  },
  {
    mode: "processing_mode",
    main_message_text: "We are processing the information. Please wait...",
    icon: <LoadingAnimation />,
    buttons: [],
    // buttons: [<ContinueButton/>],,
  },
  {
    mode: "payment_processing_mode",
    main_message_text:
      "Your payment is currently being processed. Please don't refresh or close this page.",
    icon: <LoadingAnimation />,
    buttons: [<RecheckStatusButton />, <CancelPayment />],
  },
  {
    mode: "data_errors",
    main_message_text:
      "The required payment information is missing. Please update and retry.",
    icon: <DataErrors />,
    buttons: [],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "payment_cancel_pending",
    main_message_text:
      "Please wait while we cancel this payment. We appreciate your patience.",
    icon: <LoadingAnimation />,
    buttons: [],
  },
  {
    mode: "payment_cancel_errored",
    main_message_text:
      "We cannot process the request currently. Please try again later.",
    icon: <NoShow />,
    buttons: [<ContinueButton />],
  },
  {
    mode: "process_errors",
    main_message_text:
      "We cannot process the information currently. Please try again.",
    icon: <ProcessError />,
    buttons: [],
  },
  {
    mode: "check_status_errors",
    main_message_text:
      "We cannot confirm the payment. Please do not refresh as it may cause payment failure.",
    icon: <ProcessError />,
    buttons: [<RecheckStatusButton />],
  },
  {
    mode: "payment_process_errors",
    main_message_text:
      "We cannot generate the payment information currently. Please try again.",
    icon: <ProcessError />,
    buttons: [],
  },
  {
    mode: "redirect_mode",
    main_message_text:
      "Please wait while we continue ahead. We appreciate your patience.",
    icon: <LoadingAnimation />,
    buttons: [],
  },
  {
    mode: "recheck_status",
    main_message_text:
      "Please wait while we recheck the status of your payment for following details.",
    icon: <LoadingAnimation />,
    buttons: [<RecheckStatusButton />, <CancelPayment />],
  },
  {
    mode: "redirect_mode_errored",
    main_message_text: "Could not redirect back. Please try again.",
    icon: <ProcessError />,
    buttons: [<ContinueButton />],
  },
  {
    mode: "backend_payment_processing_mode",
    main_message_text:
      "Please wait while we confirm your payment for following details",
    icon: null,
    buttons: [<RecheckStatusButton />, <CancelPayment />],
    // buttons: [<ContinueButton/>],
  },
  {
    mode: "payment_inprogress_mode",
    main_message_text:
      "Please wait while we confirm your payment for following details",
    icon: null,
    buttons: [<RecheckStatusButton />, <CancelPayment />],
    // buttons: [<ContinueButton/>],
  },
];

export const MenuStructure = [
  {
    MenuName: "in_person",
    MenuTag: "POS",
    MenuView: "ShowPOSView",
    MenuPaymentgateway: "stripe",
    MenuComponent: <PosMethodComponent />,
    MenuText: "Pay In Person",
    MenuIcon: PointOfSaleOutlinedIcon,
  },
  {
    MenuName: "online",
    MenuTag: "card",
    MenuView: "ShowOnlineView",
    MenuPaymentgateway: "stripe",
    MenuComponent: <CardMethodComponent />,
    MenuText: "Online Payment",
    MenuIcon: AddCardIcon,
  },
  {
    MenuName: "online",
    MenuTag: "card",
    MenuPaymentgateway: "braintree",
    MenuView: "ShowBraintreeOnlineView",
    MenuComponent: <DropinComponent />,
    MenuText: "Online Payment",
    MenuIcon: AddCardIcon,
  },
  {
    MenuName: "payment_success",
    MenuTag: "success",
    MenuView: "PaymentSuccessView",
    MenuComponent: <PaymentSuccess />,
    MenuText: "Payment Success",
  },
  {
    MenuName: "payment_failure",
    MenuTag: "failed",
    MenuView: "PaymentFailureView",
    MenuComponent: <PaymentFailure />,
    MenuText: "Payment Failure",
  },
  {
    MenuName: "payment_pending",
    MenuTag: "pending",
    MenuText: "Payment Pending",
  },
  {
    MenuName: "payment_cancel",
    MenuTag: "cancel",
    MenuView: "PaymentCancelView",
    MenuComponent: <PaymentCancel />,
    MenuText: "Payment Cancel",
  },
];

export const startStage = {
  CheckReaderStatusAndUpdate: "CheckReaderStatusAndUpdate",
  LoadLocationsReadersPending: "LoadLocationsReadersPending",
};

export const currencySymbols = {
  usd: "$",
};

export const ViewComponents = {
  TransitionView: <TransitionView />,
  PaymentView: <PaymentView />,
  PaymentResultView: <PaymentResultView />,
};

export let ViewModes = [
  {
    ViewName: viewModeNameList.ConfigPending,
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "config_pending_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: viewModeNameList.ConfigErrored,
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "config_errored_mode",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: viewModeNameList.ConfigComplete,
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "config_complete_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "QPBackendHealthPending",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "qpbackendhealth_load_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "QPBackendHealthErrored",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "qpbackendhealth_error_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "QPBackendHealthComplete",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "qpbackendhealth_complete_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: viewModeNameList.SetTokenPending,
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "set_token_pending",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: viewModeNameList.SetTokenComplete,
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "set_token_complete",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: viewModeNameList.SetTokenErrored,
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "set_token_errored",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderPending",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "create_order_pending",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderErrored",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "create_order_errored",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderRejected",
    Stage: "Loading",
    MainView: "TransitionView",
    MainViewName: "create_order_rejected",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderComplete",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "create_order_complete",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderInvalidAmount",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "create_order_errored",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "CreateOrderInvalidRequest",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "create_order_errored",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "LocationAvailabilityCheckPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "location_check_pending",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CheckStripeTerminalConnection",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "stripe_connection_check",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "LocationAvailabilityCheckComplete",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "location_check_complete",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ShowPOSView",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetReaderDisplay",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ClearReaderDisplay",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CheckReaderConnectionStatus",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ConnectReaderDisplay",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "DisconnectReader",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetReconnectReaderErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosProcessPaymentApiPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosProcessPaymentApiErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosProcessPaymentApiComplete",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosPaymentPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCollectPaymentErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetProcessPosPaymentErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ShowOnlineView",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardValidation",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardValidationErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardProcessing",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardProcessOrderPending",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardProcessOrderComplete",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetCardProcessOrderErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "ConfirmCardPaymentErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CheckStatusPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "CheckStatusErrored",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "check_status_errors",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetPaymentCancel",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "payment_cancel_pending",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentSuccess",
    Stage: "PaymentComplete",
    MainView: "PaymentResultView",
    MainViewName: "PaymentSuccessView",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentFailure",
    Stage: "PaymentComplete",
    MainView: "PaymentResultView",
    MainViewName: "PaymentFailureView",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentPending",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "payment_inprogress_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentCancelSuccess",
    Stage: "PaymentComplete",
    MainView: "PaymentResultView",
    MainViewName: "PaymentCancelView",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentCancelPosErrored",
    Stage: "PaymentComplete",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentCancelCardErrored",
    Stage: "PaymentComplete",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PaymentCancelErrored",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "payment_cancel_errored",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "GetStatusPayload",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "redirect_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "GetStatusPayloadErrored",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "redirect_mode_errored",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "RedirectBack",
    Stage: "PaymentComplete",
    MainView: "TransitionView",
    MainViewName: "redirect_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ReCheckStatusPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "recheck_status",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "SetDiscoverReaders",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosBackendPaymentPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosBackendPaymentErrored",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "POS",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "PosBackendPaymentComplete",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: true,
    SupplimentData: {},
  },
  {
    ViewName: "BackendPaymentProcessing",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "backend_payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "ShowBraintreeOnlineView",
    Stage: "PaymentProcessing",
    MainView: "PaymentView",
    MainViewName: "card",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
  {
    ViewName: "BraintreeConfirmPaymentPending",
    Stage: "PaymentProcessing",
    MainView: "TransitionView",
    MainViewName: "payment_processing_mode",
    IsViewVariableSet: false,
    SupplimentData: {},
  },
];

export const HelperMethods = {
  JPJS: (v) => JSON.parse(JSON.stringify(v)),
  formatFee: (val) => Number(val).toFixed(2),
};
