import { EditOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationTable from "./LocationTable";
import TenantKeys from "./TenantKeys";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import EditableConfig from "./CustomControls/EditableConfig";
import animation from "../../Assets/animation-notfound.svg";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function TenantOnboarding() {
  const {
    selectedTenant,
    selectedIntegration,
    isEditableTenant,
    allowEditingTenant,
    tenantUpdate,
    getTenantMetaData,
    saveCreatedTenant,
    setDiscardTenant,
    toggleTenantPaymentMethod,
  } = useIntegrationHook();
  const EditableConfig_1 = JPJS(getTenantMetaData);
  EditableConfig_1.updating_key = "display_name";

  const EditableConfig_2 = JPJS(getTenantMetaData);
  EditableConfig_2.updating_key = "tenant_code";

  const EditableConfig_3 = JPJS(getTenantMetaData);
  EditableConfig_3.updating_key = "service_charges_percentage";

  const getDefaultChecked = (mode, type, gateway) => {
    if (
      selectedTenant &&
      selectedTenant.enabled_payment_gateways?.includes(gateway) &&
      selectedTenant.enabled_payment_methods &&
      selectedTenant.enabled_payment_methods[type].length > 0 &&
      selectedTenant.enabled_payment_method_gateways[type][mode.code] ===
        gateway
    ) {
      return selectedTenant.enabled_payment_methods[type].some(
        (enabledMode) => enabledMode.code === mode.code
      );
    }
    if (
      selectedTenant &&
      selectedTenant.enabled_payment_gateways?.includes(gateway) &&
      selectedTenant.braintree_enabled_payment_methods &&
      selectedTenant.braintree_enabled_payment_methods[type] &&
      selectedTenant.enabled_payment_method_gateways[type][mode.code] ===
        gateway
    ) {
      return selectedTenant.braintree_enabled_payment_methods[type].some(
        (enabledMode) => enabledMode.code === mode.code
      );
    }
    return false;
  };

  const [expandedAccordion, setExpandedAccordion] = useState("tenant-keys");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : "");
  };

  const [isChecked, setIsChecked] = useState({
    isStripeChecked: false,
    isBraintreeChecked: false,
  });

  console.log(selectedTenant, "selectedTenant");
  // const tenantNewClassName = isEditableTenant ? "setwidth" : null;
  return (
    <>
      {selectedIntegration && selectedTenant ? (
        <div className="Tenant-card">
          <div className="Tenant-header">
            <div className="header-content">
              <div className="main">
                <Typography className="MainText">Tenant &nbsp;</Typography>
                <Typography className="SubMainText">Code &nbsp;</Typography>
                <Typography className="SubMainText">
                  Service Charges (%) &nbsp;
                </Typography>
              </div>
              <div className="main">
                <Typography className="MainText">: &nbsp; &nbsp;</Typography>
                <Typography className="SubMainText">: &nbsp; &nbsp;</Typography>
                <Typography className="SubMainText">: &nbsp; &nbsp;</Typography>
              </div>
              <div className="main">
                <EditableConfig
                  thisObjectName="display_name"
                  type={selectedTenant}
                  value={selectedTenant.display_name}
                  classNameprop="MainText"
                  dataTestIdProp="tenant-name-input"
                  updateValue={tenantUpdate}
                  controlMetaData={EditableConfig_1}
                  newcssforinput="inputbggrey"
                  disable_edit_list={selectedTenant.disable_edit_list}
                />
                <EditableConfig
                  thisObjectName="tenant_code"
                  type={selectedTenant}
                  value={selectedTenant.tenant_code}
                  classNameprop="SubMainText"
                  dataTestIdProp="tenant-code-input"
                  updateValue={tenantUpdate}
                  controlMetaData={EditableConfig_2}
                  newcssforinput="inputbggrey"
                  disable_edit_list={selectedTenant.disable_edit_list}
                />
                {selectedTenant.code_error !== null ? (
                  <div className="error-message">
                    {selectedTenant.code_error}
                  </div>
                ) : null}

                <EditableConfig
                  thisObjectName="service_charges_percentage"
                  type={selectedTenant}
                  value={selectedTenant.service_charges_percentage}
                  classNameprop="SubMainText"
                  dataTestIdProp="tenant-service-charge-input"
                  updateValue={tenantUpdate}
                  controlMetaData={EditableConfig_3}
                  newcssforinput="inputbggrey"
                  disable_edit_list={selectedTenant.disable_edit_list}
                />
                {selectedTenant.service_charges_error !== null ? (
                  <div className="error-message">
                    {selectedTenant.service_charges_error}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="actions">
              {isEditableTenant ? (
                <>
                  <Button
                    className="save-tenant-button"
                    variant="contained"
                    disabled={selectedTenant.service_charges_error !== null}
                    startIcon={<DownloadDoneIcon />}
                    data-testid="save-tenant-test-button"
                    onClick={() => {
                      saveCreatedTenant(selectedIntegration, selectedTenant);
                    }}
                  >
                    Save
                  </Button>
                  {selectedTenant.record_status !== "A" ? (
                    <Button
                      className="discard-tenant-button"
                      variant="contained"
                      startIcon={<ClearIcon />}
                      data-testid="discard-integration-test-button"
                      onClick={(e) => setDiscardTenant(selectedTenant)}
                    >
                      Discard
                    </Button>
                  ) : null}
                </>
              ) : (
                <Button
                  className="edit-tenant-button"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  data-testid="edit-tenant-test-button"
                  onClick={(e) => {
                    allowEditingTenant();
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
          <div className="payment-selector">
            <span className="heading-ps">Payment Mode </span>
            <div className="payment-container">
              <div className="stripe-config">
                <div className="header">Stripe Payment Modes</div>
                <div className="container-options">
                  {isEditableTenant ? (
                    <>
                      {selectedTenant.stripe_supported_payment_methods.online.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "online",
                                "stripe"
                              )}
                              value={mode.code}
                              disabled={isChecked.isStripeChecked}
                              onChange={(e) => {
                                setIsChecked({
                                  isStripeChecked: !e.target.checked,
                                  isBraintreeChecked: e.target.checked,
                                });
                                toggleTenantPaymentMethod({
                                  type: "online",
                                  value: mode,
                                  gateway: "stripe",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              Online - {mode.display}
                            </label>
                          </div>
                        )
                      )}

                      {selectedTenant.stripe_supported_payment_methods.in_person.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "in_person",
                                "stripe"
                              )}
                              value={mode.code}
                              onChange={(e) => {
                                toggleTenantPaymentMethod({
                                  type: "in_person",
                                  value: mode,
                                  gateway: "stripe",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              In Person - {mode.display}
                            </label>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {(selectedTenant?.enabled_payment_methods?.online ===
                        undefined ||
                        selectedTenant?.enabled_payment_methods?.online
                          ?.length === 0) &&
                      (selectedTenant?.enabled_payment_methods?.in_person ===
                        undefined ||
                        selectedTenant?.enabled_payment_methods?.in_person
                          ?.length === 0) ? (
                        <p className="no-payment-message">
                          No payment modes enabled
                        </p>
                      ) : null}

                      {selectedTenant.enabled_payment_methods?.online?.map(
                        (mode) =>
                          selectedTenant.enabled_payment_method_gateways
                            ?.online[mode.code] === "stripe" ? (
                            <div className="option-1" key={mode.code}>
                              <label className="checbox-label-new">
                                Online - {mode.display}
                              </label>
                            </div>
                          ) : null
                      )}

                      {selectedTenant.enabled_payment_methods?.in_person.map(
                        (mode) =>
                          selectedTenant.enabled_payment_method_gateways
                            ?.in_person[mode.code] === "stripe" ? (
                            <div className="option-1" key={mode.code}>
                              <label className="checbox-label-new">
                                In Person - {mode.display}
                              </label>
                            </div>
                          ) : null
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="braintree-config">
                <div className="header">Braintree Payment Modes</div>
                <div className="container-options">
                  {isEditableTenant ? (
                    <>
                      {selectedTenant.braintree_supported_payment_methods.online.map(
                        (mode) => (
                          <div className="option-1" key={mode.code}>
                            <input
                              type="checkbox"
                              className="checkbox-control"
                              data-testid="check-feilds"
                              defaultChecked={getDefaultChecked(
                                mode,
                                "online",
                                "braintree"
                              )}
                              value={mode.code}
                              disabled={isChecked.isBraintreeChecked}
                              onChange={(e) => {
                                setIsChecked({
                                  isStripeChecked: e.target.checked,
                                  isBraintreeChecked: !e.target.checked,
                                });
                                toggleTenantPaymentMethod({
                                  type: "online",
                                  value: mode,
                                  gateway: "braintree",
                                  isChecked: e.target.checked,
                                });
                                console.log(e.target.checked);
                              }}
                            />
                            <label className="checbox-label">
                              Online - {mode.display}
                            </label>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {!selectedTenant?.braintree_enabled_payment_methods
                        ?.online.length > 0 ? (
                        <p className="no-payment-message">
                          No payment method enabled
                        </p>
                      ) : (
                        <>
                          {selectedTenant.braintree_enabled_payment_methods?.online.map(
                            (mode) =>
                              selectedTenant.enabled_payment_method_gateways
                                ?.online[mode.code] === "braintree" ? (
                                <div className="option-1" key={mode.code}>
                                  <label className="checbox-label-new">
                                    Online- {mode.display}
                                  </label>
                                </div>
                              ) : null
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="to-line" /> */}
          <div>
            <br />
            <Accordion
              className="custom-accordion"
              expanded={expandedAccordion === "tenant-keys"}
              onChange={handleAccordionChange("tenant-keys")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="tenant-keys-content"
                id="tenant-keys-header"
              >
                <Typography variant="h6">Tenant Keys</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TenantKeys />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === "location-details"}
              onChange={handleAccordionChange("location-details")}
              className="custom-accordion"
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowUpIcon />}
                aria-controls="location-details-content"
                id="location-details-header"
              >
                <Typography variant="h6">Location Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LocationTable />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className="no-selected-tenant">
          <img src={animation} width={200} height={80} alt="notfound" />
          No Tenant Selected
        </div>
      )}
    </>
  );
}
