import { Box, Typography } from "@mui/material";
import React from "react";
import { ContinueButton } from "../actionButtons/allActions";
import CancelIcon from "@mui/icons-material/Cancel";
import usePaymentStatusHook from "../../hooksAndUiInteractions/PaymentStatusHook";
import { PaymentStatus } from "../../../stateAndConstants/paymentStateAndConstants/constants";

export default function PaymentCancel() {
  const { paymentStatus, total, currency, paymentId,currencySymbol } =
    usePaymentStatusHook();
  return (
    <div>
      <>
        <div className="payment-status-failure">
          <div>
            <CancelIcon className="icon-payment" data-testid="cancel-icon-test"/>
          </div>
          <div>
            <Typography className="status-text">
              {paymentStatus === PaymentStatus.cancelled
                ? "This payment is cancelled"
                : paymentStatus}
            </Typography>
          </div>
          <div>
          {currencySymbol ? (
              <Typography className="amount-text" data-testid="amount-test">
                {currencySymbol} {total}
              </Typography>
            ) : (
              <Typography className="amount-text" data-testid="amount-test">
                {total} {currency}
              </Typography>
            )}
          </div>
          {/* <div>
            <Typography className="transaction-id-text" data-testid="transaction-id-test">
              Transaction ID: {paymentId}
            </Typography>
          </div> */}
          <div>
            {/* <Typography className="date-text">
              24th March 2023, 04:56PM
            </Typography> */}
          </div>
        </div>
        <Box className="main-button-section">
          {/* {receiptUrl !== null ? (
            <Typography className="download-text">
              Please view your payment receipt{" "}
              <label className="clickable-text" href={receiptUrl}>
                here{" "}
              </label>
            </Typography>
          ) : null} */}

          <ContinueButton />
        </Box>
      </>
    </div>
  );
}
