import React from "react";
import { IconButton, Button } from "@mui/material";
import EditableConfig from "./CustomControls/EditableConfig";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import { CloseSharp } from "@mui/icons-material";

export default function ReaderCards({ reader, rdridprop, locprop, locprovid }) {
  // readerprop, locprop
  const {
    getReaderMetaData,
    readerUpdate,
    saveCreatedReader,
    deleteCreatedReader,
    closeReader,
  } = useIntegrationHook();
  let EditableConfig_1 = JPJS(getReaderMetaData);
  let EditableConfig_2 = JPJS(getReaderMetaData);
  EditableConfig_1.ID_Level.push(locprop);
  EditableConfig_1.ID_Level.push(rdridprop);
  EditableConfig_2.ID_Level.push(locprop);
  EditableConfig_2.ID_Level.push(rdridprop);
  EditableConfig_1.updating_key = "reader_label";
  EditableConfig_2.updating_key = "pairing_code";
  const getProcessPaymentConfig = (selectedOption) => {
    if (selectedOption === "any") {
      return true;
    } else if (selectedOption === "same") {
      return false;
    } else if (selectedOption === "null") {
      return null;
    } else {
      return null;
    }
  };
  let processFlag = null;
  let nreader = JPJS(reader);
  return (
   
      <div
        key={nreader.Rdr_id}
        className="reader-one"
        data-testid="reader-card-div"
      >
        {nreader.reader_id ? (
          <div className="reader-header">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteCreatedReader({
                  reader: nreader.reader_id,
                  locprov: locprovid,
                });
              }}
            >
              <img
                alt="Delete Button"
                src="/Images/icon _trash_.svg"
                width="15px"
                height="15px"
              />
            </IconButton>
          </div>
        ) : (
          <div className="reader-header">
            <IconButton
              className="close-reader"
              onClick={(e) => {
                e.stopPropagation();
                closeReader({
                  rdrid: nreader.Rdr_id,
                  locid: locprop,
                });
              }}
            >
              <CloseSharp style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </div>
        )}

        <div className="reader-content">
          <EditableConfig
            type={nreader}
            value={nreader.reader_label}
            classNameprop="reader-name"
            dataTestIdProp="reader-label-input"
            placeholder="Reader Label"
            updateValue={readerUpdate}
            controlMetaData={EditableConfig_1}
            newcssforinput="inputbgwhite-margin"
          />
          {nreader.labelerror !== "" ? (
            <div className="error-message">{nreader.labelerror}</div>
          ) : null}
          {nreader.isEditable ? (
            <>
              <EditableConfig
                type={nreader}
                value={nreader.pairing_code}
                classNameprop="reader-name"
                dataTestIdProp="pairing-code-input"
                placeholder="Reader Pairing Code"
                updateValue={readerUpdate}
                controlMetaData={EditableConfig_2}
                newcssforinput="inputbgwhite-margin"
              />
              {nreader.codeerror !== "" ? (
                <div className="error-message">{nreader.coderror}</div>
              ) : null}
              <div className="config-div">
                <select
                  className="jira-select"
                  onChange={(e) => {
                    processFlag = getProcessPaymentConfig(e.target.value);
                    nreader.process_payment_at_backend = processFlag;
                  }}
                >
                  <option value="" disabled selected>
                    Payment Process Config
                  </option>
                  <option value="any">Any Network</option>
                  <option value="same">Same Network</option>
                  <option value="null">Default</option>
                </select>
              </div>
            </>
          ) : null}
          {nreader.isEditable ? null : (
            <>
              <div className="reader-id">
                {" "}
                <span className="label"> Reader ID- </span>
                {nreader.reader_id}
              </div>
              <div className="process-status">
                <span className="label">
                  {" "}
                  Process Payment Config - &nbsp;
                  {nreader.process_payment_at_backend === false
                    ? "Same Network"
                    : nreader.process_payment_at_backend === true
                    ? "Any Network"
                    : nreader.process_payment_at_backend === null
                    ? "Default"
                    : null}
                </span>
              </div>
            </>
          )}
        </div>
        {nreader.isEditable ? (
          <>
            <div className="form-row button-container">
              <Button
                className="save-reader-button"
                variant="contained"
                startIcon={<DownloadDoneIcon />}
                data-testid="save-reader-test-button"
                onClick={(e) => {
                  saveCreatedReader({
                    reader: nreader,
                    locprov: locprovid,
                    locid: locprop,
                  });
                }}
              >
                Save
              </Button>
            </div>
          </>
        ) : null}
      </div>
    
  );
}
