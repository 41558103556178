import React from "react";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ClearIcon from "@mui/icons-material/Clear";
import { useIntegrationHook } from "../adminHooks/integrationHook";
import EditableConfig from "./CustomControls/EditableConfig";
import { JPJS } from "../../stateAndConstants/adminStateAndConstants/adminConstants";

export default function ApiForm({ subscription, index }) {
  const {
    selectedTenant,
    isEditableTenant,
    getApiMetaData,
    apiUpdate,
    closeapiform
  } = useIntegrationHook();

  let EditableConfig_5 = JPJS(getApiMetaData);
  EditableConfig_5.ID_Level.push(subscription.Api_id);
  EditableConfig_5.updating_key = "apicallbacks";
  return (
    <>
      <div className="one-entry-apiadmin" key={subscription.Api_id}>
        {subscription.subscription_status === "ACTIVE" ? (
          <div className="status success">
            <Tooltip title="Active" arrow placement="left-start">
              <DoneIcon className="new-icon-success" />
            </Tooltip>
          </div>
        ) : subscription.subscription_status === "INACTIVE" ? (
          <div className="status inactive">
            <Tooltip title="Inactive" arrow placement="left-start">
              <ClearIcon className="new-icon-success" />
            </Tooltip>
          </div>
        ) : (
          <div className="status unsubsribed">
            <Tooltip title="Unsubscribed" arrow placement="left-start">
              <PriorityHighIcon className="new-icon-success" />
            </Tooltip>
          </div>
        )}
        <div className="api-data">
          <EditableConfig
            type={selectedTenant}
            value={subscription.callback_url}
            classNameprop="admin-key-value"
            dataTestIdProp="api-callback-key-input"
            updateValue={apiUpdate}
            newcssforinput="inputbggrey"
            // updateValue={authUpdate}
            controlMetaData={EditableConfig_5}
          />
        </div>
        {isEditableTenant ? (
          <IconButton
            onClick={() => {
              closeapiform(subscription);
            }}
          >
            <img
              alt="Delete Button"
              src="/Images/icon _trash_.svg"
              width="15px"
              height="15px"
            />
          </IconButton>
        ) : null}
      </div>

      <div className="error-message">{subscription.api_error}</div>
    </>
  );
}
