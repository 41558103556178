import React from "react";
import { Provider } from "react-redux";
import { mainStore } from "./stateAndConstants/store";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material";
import RouteList from "./RouteList";
import { BrowserRouter } from "react-router-dom";
export const RouteBaseContainer = ({ TestCallBacks }) => {
  return (
    <React.StrictMode>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <Provider store={mainStore}>
          <BrowserRouter>
            <RouteList TestCallBacks={TestCallBacks} />
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};
