import { useSelector } from "react-redux";

const usePaymentStatusHook = () => {
  const paymentStatus = useSelector(
    (state) => state.main.PaymentIntegrations.paymentStatus
  );
  const paymentErrorMessage = useSelector(
    (state) => state.main.PaymentIntegrations.paymentErrorMessage
  );
  const total = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.total
  );
  const currency = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.currency
  );
  const currencySymbol = useSelector(
    (state) => state.main.IntegratingClientDetails.amountDetails.currencySymbol
  );
  const paymentId = useSelector(
    (state) => state.main.PaymentInteractionDetails.paymentId
  );
  const receiptUrl = useSelector(
    (state) => state.main.PaymentIntegrations.receiptUrl
  );
  const paymentDate = useSelector(
    (state) => state.main.PaymentIntegrations.paymentDate
    );
  return {
    paymentStatus,
    paymentErrorMessage,
    total,
    currency,
    currencySymbol,
    paymentId,
    receiptUrl,
    paymentDate
  };
};

export default usePaymentStatusHook;
